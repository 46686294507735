//@import "../../../global";
@import "../../../global.scss";

.LiveManagerStreamSettingsWrapper {

    .subFieldDesc {
        display: inline;

        .switchButtonLabel,
        .switchButton {
            float: left;
            color: #ffffff;
            text-transform: capitalize;
        }

        .switchButton {
            cursor: pointer;
            margin-left: 5px;
            margin-right: 10px;
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            -webkit-transition: background-image 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-image 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            width: 28px;
            height: 30px;
        }

        .switchButton[attr=disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .switchOption {
        float: right !important;
        width: 70px;
    }

    .switchButton.checked {
        background: url('../../../assets/images/toggle_on.svg') no-repeat;
    }

    .switchButton.unchecked {
        background: url('../../../assets/images/toggle_off.svg') no-repeat;
    }

    .generateButton {
        background-color: $btn-default-color-active !important;
        color: #fff !important;
        margin-bottom: 10px;
        position: absolute;
        z-index: 100;
        margin-left: 23px;
        display: none;

        &:hover {
            background-color: $btn-default-color-hover !important;
            color: #fff !important;
            box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
        }

        &:disabled {
            background-color: #B2C1EA !important;
        }
    }

    .centerButton {
        float: none !important;
    }

    .fullWidthButton {
        min-width: 150px;
    }

    .qrCodeWrapper {
        //background: #323641 0% 0% no-repeat padding-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        .generateSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .codeButtonsSection {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 50px;
        }
    }

    .generateSection:hover {
        #generateButton {
            display: inline-block;
        }
    }

    .codeField {
        background: #373C4A 0% 0% no-repeat padding-box;
        height: 55px;
        margin: 5px;
        // border-bottom: 1px solid #616676;

        div {
            &:not(:disabled):before {
                border-bottom: none !important;
            }

            &:not(:disabled):after {
                border-bottom: none !important;
            }
        }
    }

    #code1,
    #code2,
    #code3,
    #code4,
    #code5,
    #code6 {
        background: #373C4A 0% 0% no-repeat padding-box;
        border: 1px solid #616676;
        border-radius: 10px;
        width: 50px;
        height: 55px;
        font: normal normal bold 20px/111px $body-font;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
    }

    .expiryLabel {
        margin-top: 10px;
        margin-bottom: 10px;
        font: italic normal 300 12px $body-font;
    }

    .OTPLabel {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        letter-spacing: 0px;
        font: normal normal normal 12px $body-font;
    }
}