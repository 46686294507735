.dragging-value-button {
	cursor: ew-resize !important;
	background-color: transparent !important;
	flex-basis: 50%;

	input:hover,
	& .value-label {
		color: #126cfc;
	}

	input {
		border: none;
		padding: 0;
		margin: 0;
		width: 100%;
		cursor: inherit;
	}
}
