@import "../../global";

#leftPanelPlayerContainer.hideControls {
  .gobrain-controlbar {
    visibility: hidden !important;
  }
}
.loadingStreamOverlay {
  ._loading_overlay_overlay {
    position: fixed // adding fix for alpha live loading; 
  }
}
.LiveManagerLeftPanelWrapper {
  //margin-left: 100px;
  height: inherit;
  display: flex;
  margin-left: 6px;
  float: left;
  .disabledCheckBox {
    opacity: 0.5;
    pointer-events: none;
  }

  #leftPanelActionContainer {
    background-color: transparent;
    float: left;
    display: flex;
    .leftPanelActionButtons {
      margin: auto;
    }

    .LivePlayerStartButton,
    .LivePlayerStopButton {
      min-width: 110px !important;
      height: 34px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 0.4em;
      }
    }
    .LivePlayerStopButton {
      background-color: #FD9098 !important;
      &:hover {
        background-color: #FD7C86 !important;
      }
      img {
        width: 15px !important;
        height: 15px !important;
      }
    } 
    @media screen and (max-width: 1400px) {
      .saveAsOD {
        span {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      /*.leftPanelActionButtons {
        text-align: center;
        button {
          margin-top: 10px;
          img,
          svg {
            display: none;
          }
        }
      }*/
    }
  }

  .LiveStatusLabel {
    color: white;
  }

  #live-live {
    margin: 0 auto;
  }

  .infoSection {
    padding-top: 20px;
    label {
      color: #ffffff;
      display: block;
    }
  }
}
