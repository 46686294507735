#smallLivePlayer {
    cursor: move;
    position: absolute;
   // right: 0.2%;
    width: 256px;
    height: 144px;
   // bottom: 1%;
    z-index: 10000;
    div {
        pointer-events: none !important;
    }
    .gobrain-progress-wrapper {
        display: none;
    }
    .smallPlayerLiveText {
        font-size: 12px;
        position:absolute;
        color: #ffffff;
        font-weight: 600;
        letter-spacing: 1px;
        bottom: 145px;
        left: 0px;
        z-index: 49000;
        width: 100%;
        background-color: #000000;
        padding: 6px;
        img {
            float: right;
            height: 15px;
            width: 15px;
        }
    }
    .LivePlayerWrapper {
        //right: 10px;
        position: absolute;
        bottom: 0px;
    }        
    
    #actualLive-live {
        /*position: absolute;
        bottom:0px;
        margin: 0 auto;*/
    }
}


