.add-event-button {
	margin: 20px 0 10px 0 !important;
}

.widget-event-wrapper {
	margin: 0 0 5px 0;
}

.widget-event-list-item-title {
	border: 1px solid #e8e8e8;
	cursor: pointer;
}

.widget-event-title-text {
	margin-left: 10px;
}

.widget-event-list-item-content {
	border-bottom: 1px solid #e8e8e8;
	border-left: 1px solid #e8e8e8;
	border-right: 1px solid #e8e8e8;
	padding: 15px;
}
