@import "../../../../global.scss";

.dialogTitleNewMediaContentWrapper > h3 {
    text-align: left;
    float: left;
}
// .dialogBoxInternalBlock { align-items: center }
.file-input-wrapper {
    width: 200px;
    height: 40px;
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: center;
}
.file-input-wrapper > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}
.file-input-wrapper > .btn-file-input {
    cursor: pointer;
    display: inline-block;
    width: 160px;
    height: 35px;
    background-color: $btn-primary-bg-color !important;
    color: $header-bg-color;
    border-radius: 40px !important;
    letter-spacing: 1px !important;
    text-transform: capitalize !important;
    font-size: 12px !important;  
    border: none;  

    &:focus { outline: none }

    > svg {
        width: 25px;
        height: 15px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        fill: $header-bg-color;
    }
}
.file-input-wrapper:hover > .btn-file-input { background-color: $btn-primary-bg-color-hover }
.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 200px;
    width: 500px;
    border: 1px solid $default-label-text-color;
    padding: 5px 2px;
    border-radius: 5px;

    &::-webkit-scrollbar { width: 7px }
          
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $gray-links-color; 
        border-radius: 5px;
    }
       
    &::-webkit-scrollbar-thumb {
        background: $customer-primary; 
        border-radius: 10px;
    }
      
    &::-webkit-scrollbar-thumb:hover { 
        background: $btn-primary-bg-color; 
        cursor: pointer; 
    }
}
.imgPreview img { width: 100% }
.previewTextWrapper {
    width: 100%;
    display: flex;
    padding-left: 14px;
    margin-top: 20px;
    color: $default-label-color;
    margin-top: 25px;
    > h3 {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
    }
}
.bottomActionsPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    button {
        background-color: #fff !important;
        color: #125FAA !important;
        border: 1px solid #125FAA;
        box-shadow: none;
        width: 125px;
        padding: 10px;
        border-radius: 25px;
        cursor: pointer; 

        &:focus { outline: none }
    }
}

#thumbnailManagerDialogGeneral {
    height: 850px !important;
    .defaultActionBtn {     
        font-size: 11px !important;
        padding-top: 7px !important;
    }

    .selectedItemsContentWrapper {
        min-height: auto;
        border-bottom: none;
        border-left: none;
        border-right: none;
    }
    
    .actualThubGallery {
        display: block;
        min-height: 300px;
        padding: 25px 25px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        height: 300px;
        overflow-y: auto;
        padding-top: 25px;

        .eachThumbImgWrapper {
            width: 155px;
            height: 75px;
            float: left;
            margin-right: 5px;
            margin-bottom: 10px;
            border: 1px solid #e8e8e8;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            background-color: #FFFFFF !important;

            img { 
                // height: 100%;
                // width: 100%;
                max-width: 100%;
                max-height: 100%;
                display: flex;
                margin: 0 auto;
                vertical-align: middle;
                cursor: pointer !important;
            }

            svg {
                width: 15px;
                height: 15px;
                position: relative;
                left: 135px;
                top: -70px;
                fill: #fff;
                background-color: #000;
                padding: 3px;
                cursor: pointer;
                border-radius: 3px;
            }
        }
    }
    .modalBoxHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .modalBoxHeader + div {
        padding-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
.selectedThumbnailByDefaultImg {
    border: 2px solid #2ebedc !important;
    padding: 2px;
    cursor: default !important;
}