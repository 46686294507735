@import "../../../global";


#MediaDetailDialog {

    .dialogContent {
        padding: 0px;
        padding-top: 12px;
    }
    .MediaDetailDialogWrapper{    
        width: 100% !important;
        height: 100% !important;
        .addClosedNavWidth {
            padding-left: 0px;
            padding-top: 12px;
        }        
    }

    label, th {
        color: black;
    }
    
     .closeButton {     
         height: 18px;
         width: 18px;
         border: 0;
         background: none;
         cursor: pointer;     
        float: right;
        margin-top: 0.5em;
        margin-left: 0.8em;
     }
}   

