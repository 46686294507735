#sttHeader{
    //height: 48px !important;
    width: 100%;
    //position: fixed;
    margin: 0px;
    //bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#sttHeaderLeftSide{
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.sttTranslationPickerHeader{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    background: white;
    //width: 239px;


    select{
    display: block;
    width: 139px;
    height: 25px;
    text-align: left;
    font: 12px Open Sans ;
    border-radius: 2px;
    }

    button{
       margin-left: 10px;
        display: block;
        width: 100px;
        height: 25px;
        background: #3E64CB 0% 0% no-repeat padding-box ;
        border:0.5px solid #3E64CB;
        
        font: 12px Open Sans ;
        color: white;
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
    }

}

.modeBtn{
    width: 26px;
    height: 26px;
    background-color: #E6E6E6;
    border-radius: 2px;
    border: 0;
    padding: 3 10 3 10;
    background: #e6e6e6 0% 0% no-repeat padding-box;
    
    margin: 5px;
   
}
.modeBtn:hover{
    box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
}

.currentMode{
    fill: white;
    background-color: #3e64cb;
}

.editModeBtn {
    transform: scale(0.75);
    filter: invert(38%) sepia(5%) saturate(18%) hue-rotate(334deg) brightness(92%) contrast(88%);
}

.editModeBtnCurrent {
    transform: scale(0.75);
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

.translateModeBtn {
    transform: scale(0.75);
    filter: invert(38%) sepia(5%) saturate(18%) hue-rotate(334deg) brightness(92%) contrast(88%);
}

.translateModeBtnCurrent {
    transform: scale(0.75);
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(334deg) brightness(104%) contrast(101%);
}

#subEditMode{
    
    background-size: 22px 22px;
    background-position: center;
    
}
#editMode{
    
    background-size: 18px 18px;
    background-position: center;
    
}

.sttHeaderTitle{
    margin: 0px;
    padding: 12px;
    z-index:1;
    text-align: left;
    font: 15px Open Sans;
    font-weight: 600;
    color: #000000;
    opacity: 1;
}

#switchViewLayer{
    //right: 304px;
    //display: none;
    position: relative;
    top: -1px;
    /* transform: translate(-50%, 0); */
    z-index: 2;
    padding: 11px;
    //width: 500px;

}

.whiteBtn{
    
    
    color: #383838;
    border: 2px solid #a2a2a2;
    background-color: white;
    text-align: left;
    font: 12px Open Sans;
    font-weight: bold;
    
    letter-spacing: 0;
    padding: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left: 15px;   
}

.previewActive{
    color: black !important;
}

#saveSrtBtnHeader{
    background: #126CFC 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    text-align: center;
    width: 100px;        
    border: 2px solid #126CFC;
    font: 12px Open Sans;
    color: white;
    letter-spacing: 0;
    padding: 5px;
    cursor: pointer;
}

#saveSrtBtnHeader:hover{
    background: #0053D8 0% 0% no-repeat padding-box !important;
    border: 2px solid #0053D8 !important;
}

#saveSrtBtnHeaderDeactive{
    background: #126CFC 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 0.4;
    text-align: center;
    width: 100px;        
    border: 2px solid #126CFC;
    font: 12px Open Sans;
    color: white;
    letter-spacing: 0;
    padding: 5px;
    
}



.optionsBtn{
    color: #383838;
    border: 2px solid #a2a2a2;
    background-color: white;
    text-align: left;
    font: 12px Open Sans;
    font-weight: bold;
    letter-spacing: 0;
    padding: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding-left: 14px;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left: 15px;   
}

.downArrow{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    margin-right: 3px;
    transform: translateY(1px);
    //filter: invert(64%) sepia(89%) saturate(604%) hue-rotate(152deg) brightness(91%) contrast(87%);
}

.sttPreviewBtnContainer{
    display: flex;
    flex-direction: row;

    span{
        margin-right: 4px;
    }
    
}

#sttSliderSwitch {
    transform: translateY(-1px);

.SliderSwitch {
    max-width: 600px;
    margin-left:auto;
    margin-right: auto;
    //margin-top: 100px;
    text-align: center;
  }
  
 input{
    visibility: hidden;
    display: inline-block;
    width: 1px;
    height: 1px;
  }
  
  
  
  
  .SliderSwitch__container{
    background-color: #e8e8e8;
    height: 6px;
    display: inline-block;
    width: 20px;
    border-radius: 4px;
    position: relative;
    vertical-align: middle;
    box-shadow: inset 0px 0px 3px 1px rgba(0,0,0,0);
    margin-left: 4px;
    margin-right: 10px;
    
    transition: background-color 300ms ease-in-out;
  }
  
  .SliderSwitch__toggle {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 12px;
    background-color: #d1d1d1;
    
    position: absolute;
    top: -2px;
    left: -2px;
    
    cursor: pointer;
    
    transition: left 300ms ease-in-out;
  }
  
  .SliderSwitch__toggle:after {
   
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 0;
    width: 100%;
    text-align: center;
    
    transition: color 300ms ease-in-out;
    
  }
  
  input:checked + .SliderSwitch__container{
    background-color: #7fb538;
  }
  
  input:checked + .SliderSwitch__container .SliderSwitch__toggle {
    background-color: #8dc544;
    left: calc( 100% - 8px );
  }
  
  input:checked + .SliderSwitch__container .SliderSwitch__toggle:after {
  
    color: #2ECC40;
  }

}

