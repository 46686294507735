.editor-dialog {
    &__title {
        display: flex;
        border-bottom: 1px solid #DBE9FF;
        align-items: center;
        padding: 10px;
        min-width: 550px;
        div {
            margin: 0 10px;
            flex: 1;
            font-size: 15px;
            font-weight: 600;
        }
        button {
            justify-self: flex-end;
            padding: 0px;
        }
    }
    &__action--center {
        justify-content: center !important;
    }
    &__action--left {
        justify-content: flex-start !important;
    }

    &__action--bothSide {
        justify-content: space-between !important;
    }
}

.create-model-field {
    display: flex !important;
    margin: 0 !important;
    > * {
        width: 100% !important;
        text-align: left;
    }
    .MuiFormControlLabel-label {
        margin-bottom: 8px !important;
    }
}

.cancel-button {
    color: #126CFC !important;
}