.scenario-chart-side-bar {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	width: 25%;
	border-right: 1px solid #e8e8e8;
	min-width: 350px;
	&__content {
		background: #f7f8fa;
		flex: 1;
		display: flex;
		flex-direction: column;
		height: calc(100% - 100px);
	}
}
