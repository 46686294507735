@import "../../global";

.loginScreen {
    background: #082456 0% 0% no-repeat padding-box;
    height: 100%;

    .iframelogin {
        border: none;
        width: 100%;
        height: auto;
        min-height: 500px;
    }

    .loginScreen__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px 20px 24px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
    .loginScreen__header--slot {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .loginScreen__bg {
        background-image: url('../../assets/images/login_assets/Blue_boxes.svg');
        background-position: bottom 24px right 24px;
        background-repeat: no-repeat;
        background-size: 15%;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;
    }

    button {
        border: none;
        border-radius: 4px;
        display: flex;
    }

    .loginScreen__header-button {
        padding: 0px 24px;
        height: 48px;
        font-size: 14px;
        font-family: 'Poppins';
        align-items: center;
        cursor: pointer;
        &:hover {
            box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
        }
        &:not(.loginScreen__header-button--white) {
            margin-right: 10px;
            background: #126cfc;
            color: white;
            &:hover {
                background-color: $btn-default-color-hover;
            }
        }
        &.loginScreen__header-button--white {
            background: #fff;
            color: #0c306c;
            &:hover {
                background-color: #f2f5f7;
            }
        }
        .loginScreen__header-button--icon {
            margin-right: 5px;
        }
    }
    .loginScreen__header-button--sm {
        display: none;
        cursor: pointer;
        &:hover {
            box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
        }
    }

    .loginScreen__slot {
        flex: 1 0 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.loginScreen__slot--right {
            justify-content: flex-start;
            margin-left: 5%;
        }
        &.loginScreen__slot--left {
            justify-content: flex-end;
            margin-right: 5%;
        }
    }

    .loginScreen__welcomeText {
        color: #126cfc;
        font: normal normal normal 65px/80px Poppins;
        max-width: 450px;
        text-align: center;
        span {
            color: white;
        }
    }

    .loginContainer {
        width: 400px;
        border-radius: 10px;
        // height: 460px;

        .loginContainer__banner {
            position: relative;
            height: 80px;

            .loginContainer__banner--img {
                width: 100%;
                height: 100%;
            }

            .loginContainer__banner--text {
                position: absolute;
                bottom: 15px;
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                display: flex;
                margin-left: 60px;
                &:hover {
                    font-size: 19px;
                    cursor: pointer;
                }

                img {
                    margin-right: 10px;
                }
            }
        }

        .loginContainer__form {
            background-color: #fff;
            padding: 40px 60px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .form-group {
                display: flex;
                justify-content: center;
                flex-direction: column;
                &:not(:last-of-type):not(.login_btn):not(.login-with-azure) {
                    margin-bottom: 40px;
                }

                a {
                    cursor: pointer;
                    color: #126cfc;
                    text-decoration: none;
                    &:hover {
                        font-weight: 700;
                    }
                }

                label {
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                input {
                    width: 100%;
                    height: 32px;
                    border: none;
                    border-bottom: 1px solid #DFE0E2;
                    box-shadow: none;
                    font-size: 14px;
                    color: #000;
                    &::placeholder {
                        color: #C3C3C3;
                    }
                    &:focus {
                        border: none;
                        outline: none;
                        border-bottom: 1px solid #326AF3;
                    }
                    &:-webkit-autofill,
                    &:-webkit-autofill:focus {
                        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                    }
                }

                button, a {
                    font-size: 14px;
                    font-family: 'Poppins';
                    border: none;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                }

                button {
                    height: 48px;
                    min-width: 150px;
                    &:not(.login-with-azure-btn):not(.show-password) {
                        background: #126cfc;
                        color: white;
                        padding: 0px 24px;
                        &:hover {
                            background-color: #0053d8;
                        }
                    }
                    &.login-with-azure-btn {
                        background: #FFFFFF;
                        box-shadow: 3px 3px 10px #00000019;
                        border-radius: 4px;
                        text-align: center;
                        position: relative;
                        img {
                            position: absolute;
                            left: 24px;
                            width: 21px;
                        }
                        &:hover {
                            background-color: rgba(9, 30, 66, 0.04);
                        }
                    }
                }
            }

            .password-reset {
                margin-top: 40px;
            }

            .login_spaceText {
                color: #C3C3C3;
                font-size: 14px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .dashboard_copyRight {
        position: absolute;
        bottom: 20px;
        font-size: 14px;
        color: #85AAE6;
    }

    .reset-password-info {
        font-size: 14px;
        padding: 30px 20px;
        text-align: center;
    }
}

@media screen and (max-width: 770px) {
    .loginScreen {
        .loginScreen__bg {
            flex-direction: column-reverse;
            background: none;
            padding: 0%;
            .loginScreen__slot {
                flex: none;
                &--right {
                    justify-content: center;
                    margin: 50px 0 10px 0 !important;
                }
                &--left {
                    margin: 0;
                    justify-content: center;
                    width: 100%;
                    flex: 1 !important;
                }
            }
            .loginScreen__welcomeText {
                font-size: 24px;
                max-width: 100%;
                height: 60px;
            }
        }

        .loginContainer {
            width: 100%;
            height: 100% !important;
            .loginContainer__banner .loginContainer__banner--text {
                padding-left: 40px;
            }
            .loginContainer__form {
                padding: 20px 10px;
            }
        }

        button.loginScreen__header-button {
            display: none !important;
        }
        .loginScreen__header-button--sm {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            height: 48px;
            width: 48px;
            background: transparent;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        .dashboard_copyRight {
            margin: 0 24px 0 24px !important;
        }
    }
}
