@import "../../../../../global";

.sharePreviewSection {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h4 {  margin-bottom: 15px }
}
.previewAndShareInternalBlock {
    display: flex;
    flex-direction: row;
    align-items: center;

    .genericDropDown {
        width: 102.5%;
        height: 37px;
        margin-top: 10px;
        border-radius: 5px;
        border: solid 1px $media-content-border-color;
        background-color: $tooltip-text-color;
        cursor: pointer;
        padding-left: 15px;
        color: $default-label-color;

        &:focus { outline: none }
    }
    label {
        cursor: pointer;
    }
}
#themeSelectionBox {
    margin-bottom: 10px;

    svg {
        position: relative;
        left: -20px;
        top: 15px;
        fill: $primary-filtering-icons-color;
    }
}
.halfWidth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;

    label { font-weight: normal !important }
}
.previewButtonsInside {
    background-color: $tooltip-text-color;
    border: 1px solid $checkboxes-border-color;
    border-radius: 5px;
    color: $checkboxes-highlighted-color;
    width: 100%;
    margin-top: 10px;
    font-size: 11px;
    height: 25px;
    cursor: pointer;
    text-align: center;

    &:focus { outline: none }
}
.expandablePreviewSection {

    .collapsibleStatus {
        textarea {
            margin-bottom: 0px;
            height: 100px;
            resize: none;
            width: 100%;
            border-radius: 5px;
            border: 1px solid $checkboxes-border-color;
            margin-top: 10px;
            font-size: 11px;
            color: $textarea-font-color;

            + button {
                position: relative;
                top: -43px;
                border: none;
                width: 99%;
                left: 1px;

                svg {
                    position: relative;
                    top: 2px;
                    border: none;
                    width: 9px;
                    fill: $customer-primary;
                    width: 9px;
                    height: 9px;
                    margin-left: 5px;
                }
            }
        }
    }

    .expandableStatus {
        svg {
            position: relative;
            top: 2px;
            fill: $customer-primary;
            width: 9px;
            height: 9px;
            margin-left: 5px;
        }
    }

}
#embedTextarea { height: 125px }
.linksSharingBlock {
    margin-top: 7px;
    margin-bottom: 30px;
    a {
        font-size: 11px;
        cursor: pointer;
    }
    img {
        width: 18px;
        margin-right: 10px;
        margin-top: -2px;
    }
}
#iFrameTextarea { height: 105px }
.expandableHeight { height: 130px }
.expandableHeightEmbed { height: 160px }
.expandableHeightiFrame {
    height: 120px;
    margin-bottom: 20px;
}
#linkSharingContainer { margin-top: 25px }
#iFrameLinkContainer { margin-top: 25px }
