@import '../../global';

body[screen-type='tablet'],
body[screen-type='small'],
body[screen-type='mobile'] {
	.breadcrumb--desktop {
		display: none !important;
	}
	.filter-section {
		padding: 12px 24px 12px 12px;
	}
}
body[screen-type='large'],
body[screen-type='medium'] {
	.breadcrumb--mobile,
	#breadCrumbMobileView {
		display: none !important;
	}
}

.breadcrumb--bordered {
	border-bottom: 1px solid #e6e9ec;
}

.breadCrumbHeader {
	font-size: 12px;
	font-weight: normal;
}

#breadCrumbPaper {
	box-shadow: inherit;
	padding: 0px;
	display: inline-block;
	width: 100%;
	nav {
		padding: 8px 8px 8px 0px;
	}
}

#breadCrumbGroup ol,
.hidden-breadcrumb ol {
	max-width: 100%;
	max-height: 30px;
	overflow: auto;
}

#breadCrumbGroup li,
.hidden-breadcrumb li {
	max-width: 250px;
	@include textEllipsis;
	font-size: 12px;
	a {
		color: $faded-breadcrumb-link-color !important;
		cursor: pointer;
		font-size: 12px;
		letter-spacing: 0.01px;
		&:hover {
			color: $faded-breadcrumb-link-color-hover !important;
		}
	}
	&:not(:first-child) {
		padding-left: 2px;
	}

	&.MuiBreadcrumbs-separator {
		margin: 0px;
	}
}

.breadcrumb-separator {
	margin: 0 12px;
	font-size: 8px;
	font-weight: 600;
	margin-bottom: -2px;
}

#breadCrumbGroup li:last-child a,
.hidden-breadcrumb li:last-child a {
	color: #3b3b3b !important;
}

li[aria-hidden='true'] {
	font-size: 18px;
	margin-bottom: 1px;
}

.breadCrumbMenuBar {
	display: flex;
	align-items: center;
	margin: 0 24px;
	min-height: 42px;
	.folderSeparator {
		margin: 0 12px;
		font-size: 8px;
		margin-bottom: -2px;
	}
}
.breadcrumbMenuList {
	li {
		font-weight: 600;
	}
	li:focus {
		background-color: transparent;
	}
}
.breadcrumbMenuIcon {
	width: 15px !important;
	height: 15px !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	> svg {
		width: 100%;
		height: 100%;
	}
	&:hover {
		box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		border-radius: 4px;
	}
}
