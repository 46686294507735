.language-pool {
	&-wrapper {
		display: block;
		margin-bottom: 12px;
		font: 12px Open Sans;
		input {
			width: 220px;
			height: 25px;
			text-align: left;
			border: 1px solid;
			border-radius: 5px;
			font: 12px Open Sans;
			padding: 0px 5px;
			&:disabled {
				border: 1px solid #e8e8e8;
			}
		}
	}

	&-listbox {
		width: 220px;
		margin: 2px 0 0;
		padding: 0;
		position: absolute;
		list-style: none;
		background-color: #fff;
		overflow: auto;
		max-height: 250px;
		border-radius: 4px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		z-index: 1;

		& li {
			padding: 5px 12px;
			display: flex;

			& span {
				flex-grow: 1;
			}

			& svg {
				color: transparent;
			}
		}

		& li[aria-selected='true'] {
			background-color: #fafafa;
			font-weight: 600;

			& svg {
				color: #1890ff;
			}
		}

		& li[data-focus='true'] {
			background-color: #e6f7ff;
			cursor: pointer;

			& svg {
				color: #000;
			}
		}
	}

	&-tag {
		display: flex;
		align-items: center;
		height: 20px;
		margin: 2px;
		line-height: 22;
		font-size: 11px;
		color: #000;
		background-color: #fafafa;
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		box-sizing: content-box;
		padding: 0 4px 0 10px;
		outline: 0;
		overflow: hidden;

		&-wrapper {
			width: 220px;
			display: flex;
			justify-content: flex-start;
			margin-bottom: 5px;
			flex-wrap: wrap;
		}

		&:focus {
			border-color: #40a9ff;
			background-color: #e6f7ff;
		}

		& span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		& svg {
			font-size: 12px;
			cursor: pointer;
			padding: 4px;
		}
	}

	&-input-wrapper {
		width: 300px;
		border: 1px solid #d9d9d9;
		background-color: #fff;
		border-radius: 4px;
		padding: 1px;
		display: flex;
		flex-wrap: wrap;

		&:hover {
			border-color: #40a9ff;
		}

		&.focused {
			border-color: #40a9ff;
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}

		& input {
			font-size: 14px;
			height: 30px;
			box-sizing: border-box;
			padding: 4px 6px;
			width: 0;
			min-width: 30px;
			flex-grow: 1;
			border: 0;
			margin: 0;
			outline: 0;
		}
	}

	&-label {
		padding: 0 0 4px;
		line-height: 1.5;
		display: block;
	}
}
