@import '../../../../../../global';

.eachSelectedItemWrapper {
	.fileNameUploaded {
		color: #000 !important;
	}
}

.sortingDropDownWrapper {
	.sortingDropDown {
		color: #000 !important;
	}
}

#SubtitleDialog {
	label {
		color: black;
	}

	table.infoTable {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		tr {
			margin: 10px;
			border: 1px solid $media-content-border-color;
		}
		td {
			color: black;
			padding: 8px;
			width: 150px;
		}

		td:nth-child(odd) {
			font-weight: 600;
		}
	}

	img.imgIcons {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}
	a {
		img,
		span {
			cursor: pointer;
			float: left;
			margin-right: 5px;
			font-weight: normal;
		}
	}

	textarea {
		resize: none;
	}

	.selectedSubtitleWrapper {
		display: flex;
		flex-direction: column;
		min-height: 40px;
		max-height: 150px;
		overflow-y: auto;
		border: 1px solid $media-content-border-color;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 5px;

		.eachSubtitleWrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 98%;
			line-height: 25px;
			border-bottom: 1px solid #d3d3d3;
			padding-top: 7px;
			padding-bottom: 7px;

			&:last-child {
				border-bottom: none;
			}

			.fileNameUploaded {
				width: 70%;
				color: #000 !important;
			}
			.fileSizeUploaded {
				width: 30%;
			}

			> button {
				border: none;
				background: transparent;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				> svg {
					width: 100%;
				}
			}

			.tooltip {
				width: 45%;
				margin-left: 10px;
			}
		}
	}
}
