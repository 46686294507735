.q-color {
	fill: #9ca1ac;
	color: #9ca1ac;
	* {
		fill: inherit;
		color: inherit;
	}
	&:not(.q-color--inactive):hover * {
		fill: inherit;
		color: inherit;
	}

	&.q-color--info {
		fill: #126cfc;
		color: #126cfc;
		&:hover {
			fill: #0053d8;
			color: #0053d8;
		}
	}

	&.q-color--error {
		fill: #fd5e6b;
		color: #fd5e6b;
		&:hover {
			fill: #f44352;
			color: #f44352;
		}
	}

	&.q-color--inactive {
		opacity: 40%;
	}
}
