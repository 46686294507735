.MuiCollapse-entered > .MuiCollapse-wrapper {
	position: relative;
	.catalogTree--indicator {
		position: absolute;
		content: ' ';
		background-color: #126cfc;
		top: 0;
		bottom: 0;
		width: 1px;
	}
}
.q-catalogTree {
	padding-bottom: 0px !important;
	button {
		background-color: transparent !important;
	}
}

.catalog-dropdown-options {
	width: 60px;
	&.no-children {
		margin-right: 34px;
	}

	& button.MuiButton-outlinedSizeSmall {
		padding: 3px 5px !important;
	}

	& span.MuiButton-label {
		justify-content: space-between !important;
	}
}
