.library-sidebar {
	width: 18%;
	min-width: 200px;
	display: flex;
	flex-direction: column;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	padding: 12px 0;

	button {
		width: 32px;
		height: 32px;
	}

	&__sub-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 10px;
		color: #a0a0a0;
		height: 30px;
		padding: 20px 4px 12px 12px;
		text-transform: uppercase;
		&--collapsed {
			padding-bottom: 20px;
		}
	}

	&__trees {
		overflow: auto;
		flex: 1;
		padding-left: 12px;
	}
	&__header {
		padding-right: 12px;
		padding-left: 12px;
	}

	.searchFilter--item {
		.searchFilter--icon {
			visibility: hidden;
			background-color: transparent !important;
		}
		&:hover .searchFilter--icon {
			visibility: visible;
		}
	}
}
@media (max-width: 800px) {
	.library-sidebar {
		width: 25%;
	}
}
