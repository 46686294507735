@import "../../global.scss";

#TagsSection {
    .tagsListSection {
        max-height: 100px;
        overflow: auto;
        display: block;
        padding-left: 0px;
        margin-top: 2px;
        margin-bottom: 5px;
        width: 100%;
    } 
    .tag {
        color: $header-bg-color;
        font-weight: bold;
        background: $media-item-header-bg-color;
        float: left;
        padding: 5px 10px;
        border-radius: 10em;
        margin-right: 5px;
        margin-top: 5px;
        font-size: 12px;
        padding: 5px 25px;
        border-radius: 20px;
        list-style: none;
        
        button {
            background: transparent;
            border: 0;
            cursor: pointer;
        }
    
        svg {
            width: 10px;
            height: 10px;
            cursor: pointer;
            top: 1px;
            position: relative;
            margin-right: 5px;
    
            .prefix__cls-1 { fill: $header-bg-color }
            #prefix__Layer_2 { fill: $header-bg-color }
        }
    }
    /*.tag-input {
        margin-bottom: 0px;
        textarea {
            padding-right: 150px;
        }
    }*/

    #tagInput {
        width: 100%;
        padding: 12px;
        margin: 0;
        margin-top: 10px;
        border: 1px solid lightgrey;
        border-radius: 4px;
    }

    .tagInputInfo {
        margin-left: -90px;
        border-radius: 2px;
        border: 1px solid grey;
        letter-spacing: 0.5px;
        padding: 5px;
        display: inline-block;
        font-size: 10px;
    }

    .suggestionList{
        padding: 0px;
        cursor: pointer;
        max-height: 100px;
        min-height: 0px;
        width: 100%;
        overflow: auto;
        border: 1px solid #BFBFBF;
        border-top: 0px;    
        margin: 0px;  
        &::-webkit-scrollbar { 
			width: 5px;
			cursor: pointer; 
		}
          
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $body-bg-color-after-login; 
            border-radius: 8px;
        }
           
        &::-webkit-scrollbar-thumb {
            background: $default-label-text-color; 
            border-radius: 5px;
        }
          
        &::-webkit-scrollbar-thumb:hover { background: $body-table-font-color } 
        li {
            padding-left: 10px;
            height: 20px;
            font-size: 12px;
            background-color: #F7F7F7;
            margin-bottom: 5px;
            list-style-type: none;
        }
        li:hover{
            background-color: #9ED9E5;
        }
        li.highlight{
            background-color: #9ED9E5;
        }
    }
}
