.widget {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.button-widget {
	justify-content: center;
	align-items: center;
}

.image-widget {
	text-align: center;
}

svg#svgWidgetOverlay {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;

	& > g > g {
		transform-box: fill-box;
		transform-origin: center;
	}
}
