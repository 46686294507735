.slider {
	margin: 20px 0 0;
	box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
	border-radius: 10px !important;
	border: 1px solid #e5e9ec !important;
	position: relative;
	&__action {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	&__item {
		display: flex;
		&--left,
		&--right {
			display: flex;
			min-height: 275px;
			width: 50%;
			flex: 1 0 50%;
			padding: 40px;
		}
		&--left {
			flex-direction: column;
			justify-content: space-between;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			button {
				align-self: flex-start;
			}
		}
		&--right {
			justify-content: center;
			align-items: center;
			padding: 24px 40px;
			background-color: #3daa8b;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			color: #000;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		&-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 12px;
		}
		&-text {
			font-size: 12px;
			margin-bottom: 12px;
		}
	}
}
