@import '../../../../global.scss';

#addToPlaylistDialog {
	div[class*='MuiPaper-rounded-'] {
		min-width: 600px;
	}

	a {
		color: $customer-primary !important;
		font-size: 12px;
		letter-spacing: 1px;
		cursor: pointer;
		text-decoration: none;
	}
	.allPlaylistsContainer {
		width: 100%;
		min-height: 275px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-y: auto;
		padding: 15px;
		border: 1px solid $content-border-color;
		border-radius: 4px;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 5px;
			cursor: pointer;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px $body-bg-color-after-login;
			border-radius: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background: $default-label-text-color;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: $body-table-font-color;
		}

		.singleListViewContainer {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 10px;
			cursor: pointer;

			> img {
				width: 50px;
			}
			> h6 {
				font-size: 12px;
				letter-spacing: 1px;
				margin-left: 10px;
				cursor: pointer;
			}
		}
		.selectedSingleListViewContainer {
			background-color: $thin-left-panel-bg-color;
			border-radius: 5px;
			color: $default-label-color;
		}
	}
	.labelWrapperPlaylist {
		margin-top: 0;
		width: 100%;
		margin-bottom: 10px;
		font-size: 12px;
	}
}

#createNewPlaylistsDialogBox {
	.dialogContentWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: auto;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		justify-content: space-evenly;

		> input[type='text'] {
			height: 30px;
			border-radius: 5px;
			border: solid 1px $gray-links-color;
			min-width: 464px;
			padding-left: 10px;
			padding-right: 10px;
			font-size: 12px;
			letter-spacing: 1px;
		}
	}
	.createNewPlaylistsDialog__backBtn {
		display: flex;
		flex: 1;
		align-items: center;
		svg {
			width: 12px;
			height: 20px;
			margin-right: 10px;
		}
	}
}
