.tree-item {
	height: 36px;
	border-bottom: 1px solid #e8e8e8;
	display: flex;
	align-items: center;
	padding: 0 5px;

	&.layer-wrapper {
		padding-left: 5px;
		background-color: #f7f8fa;
	}

	&.item-wrapper {
		padding-left: 30px;
		background-color: #ffffff;
		cursor: pointer;
	}

	& .item-content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& .item-title {
			display: flex;
			align-items: center;
			flex: 8;

			& .title-span {
				overflow: hidden;
				white-space: nowrap;
				display: inline;
				width: 200px;
				text-align: left;
				text-overflow: ellipsis;
			}

			& .item-drag {
				cursor: pointer;
				margin-right: 5px;
				color: #c0c1c3;
			}

			& .italic-text {
				font-style: italic;
				color: #656c72;
			}
		}

		&.active {
			color: #326af3;
		}

		& .item-action {
			display: none;
			flex: 2;
		}
	}

	&:hover {
		&.item-wrapper {
			background-color: #f7f8fa;
		}

		& .item-content {
			& .item-action {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
