@import "../../../global";

.InteractBottomPanelContainer {
  display: flex;
  text-align: center;
  label {
    display: flex;
    align-items: center;
  }
  button:disabled {
    opacity: 0.4;
  }
  .trimVideoText {
    margin-right: 10px;
  }
}

.interactEditorBottomPanelWrapper[attr="groupLength1"] {
  .vis-item.video {
    height: 70px !important;
  }
}
.interactEditorBottomPanelWrapper[attr="groupLength2"] {
  .vis-item.video {
    height: 119px !important;
  }
}
.interactEditorBottomPanelWrapper[attr="groupLength3"] {
  .vis-item.video {
    height: 168px !important;
  }
}

.interactEditorBottomPanelWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  button {
    margin-top: 2px;
    height: 34px;
  }

  /*  .interactElementsTitleContainer {
        position: relative;
        background-color: #000000;
        width: 100%;
        height: 36px;
        @include alignTextImageCenter;

        .interactElementsTitle {
            float: left;
            font-weight: 600;
            letter-spacing: 0.5px;
            padding: 5px;
            padding-left: 46px;
            color: #ffffff;
        }
    } */

  .interactElementsTitleContainer {
    background-color: #2a2e38;
    color: #ffffff;
    height: 38px;
    line-height: 38px;
    text-align: center;
    .interactElementsTitle {
      padding-left: 6px;
      display: block;
      float: left;
    }
    .openInNewWindowIcon {
      float: right;
      svg {
        height: 25px;
      }
    }
  }

  .interactQueueContainer {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    .interactQueueItem[liveQueue="true"] {
      opacity: 0.4;
      pointer-events: none;
    }
    .interactQueueItem {
      background-color: #323641;
      width: 200px;
      height: 100px;
      margin: 10px;
      text-align: center;
      cursor: pointer;
      position: relative;

      .interactQueueItemBox {
        width: 200px;
        height: 70px;
        background-color: #373c4a;
        box-shadow: 3px 3px 10px #00000029;
        border-radius: 5px;
        position: relative;

        .interactElementImage {
          max-width: 190px;
          max-height: 60px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      label {
        color: white;
      }
      .deleteIcon {
        cursor: pointer;
        width: 25px;
        height: 25px;
        position: absolute;
        right: 2px;
        bottom: 36px;
        padding: 1px;
        //border: 1px solid red;
        border-radius: 2px;
        fill: white;
        z-index: 2000000;
        &:hover {
          border: 1px solid red;
        }
      }
    }
  }

  .sliderRoot {
    width: 10vw;
    display: inline-block;
    cursor: pointer;
    .slider {
      padding: 15px 0px;
    }

    .trackBefore {
      background-color: #fff;
    }

    .trackAfter {
      background-color: #fff;
    }

    .thumb {
      background-color: #fff;
    }
  }

  .zoom-button:nth-child(n-1) {
    right: 55px;
  }
  .zoom-button:last-child {
    right: 20px;
  }

  .zoom-button {
    position: absolute;
    vertical-align: top;
    color: white;
    background: $navigation-panel-bg-color !important;
    opacity: 1;
    min-width: 30px;
    img {
      width: 18px;
      height: 18px;
      background-color: #fff;
    }
  }

  .zoom-button:disabled {
    color: $disabled-btn-color !important;
    opacity: 0.5;
  }

  //timeline custom css

  .interactTimeLine {
    //margin-right: 1px;
    padding-top: 1em;
    //background-color: #ffffff;
  }
  .vis-timeline {
    &.vis-bottom {
      // border-radius: 10px 0px 10px 10px; //setting right as 0 because vertical pointer was changing shape
      border: none;
      min-height: 75px !important;
      max-height: 1000px !important;
      width: 100% !important;
    }
  }

  .vis-background {
    overflow: initial; //added to highlight vertical pointer
  }

  .vis-itemset {
    max-height: 168px !important; //added so that trim tool does not cause height to increase
    .vis-background {
      background-color: #efefef;
    }

    .vis-foreground {
      background-color: $movable-grid-color;
      .vis-group {
        height: 49px !important;
        // border-bottom: 1px solid #FFFFFF;
        background-color: $live-interact-header;
      }
      .vis-group.mediaGroup {
        height: 70px !important;
        pointer-events: none;
      }
    }
  }

  .vis-time-axis {
    height: 46px !important;
    //border-bottom: 12px solid $text-inputs-border-color;
    //padding-bottom: 12px;
    .vis-grid.vis-vertical {
      border-left: none;
    }
    .vis-text {
      /* white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;*/
      color: #ffffff;
      top: 5px;
      z-index: 3;
    }
  }

  .vis-panel.vis-top,
  .vis-panel.vis-left,
  .vis-panel.vis-center,
  .vis-labelset .vis-label {
    border: 0px;
  }

  .vis-panel.vis-left {
    .vis-labelset {
      .vis-label {
        background-color: transparent !important;
        height: 49px !important;
      }
      .vis-inner {
        @include alignTextImageCenter;
        display: inline-flex;
        text-align: center;
        height: inherit;
        img {
          height: 20px !important;
          width: 25px !important;
          align-content: center;
          padding: 3px;
        }
      }
    }
  }

  .vis-panel.vis-vertical,
  .vis-panel.vis-center {
    left: 34px !important;
  }

  .vis-panel.vis-top {
    font-size: 1.2rem;
  }

  .vis-item.mediaItem {
    // background-color: #C7EAF8;
    background-color: #c6cfea;
    border: 0px;
    opacity: 1;
    cursor: auto;
    z-index: 1;
  }
  .vis-item.chapter {
    background-color: #ef84d2;
  }
  .vis-item.video.vis-selected {
    border-radius: 10px;
    opacity: 0.4;
    cursor: move;
  }

  .vis-item .vis-item-overflow {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border: 0px;
  }

  .vis-item.video {
    pointer-events: none;
    border-radius: 0px;
    z-index: 2;
    position: fixed;
    top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 168px !important;
    cursor: auto;
    border-width: 0px !important;
    top: 0px !important;
    .vis-delete:after {
      content: "" !important;
    }
    .vis-drag-left {
      left: -25px !important;
      width: 50px !important;
      z-index: 10;
    }
    .vis-drag-right {
      right: -25px !important;
      width: 50px !important;
      z-index: 10;
    }
  }

  .mediaItem {
    .vis-item-content {
      background-color: transparent !important;
    }
  }
  .vis-item {
    //background-color: #C4C8C9;
    // background-color: #C7EAF8;
    background-color: #126cfc;
    cursor: pointer;
    opacity: 0.4;
    color: white;
    height: 100%;
    border-radius: 0px !important;
    border-color: transparent;
    label.visItemLabel {
      background-color: #000000;
      padding-left: 5px;
      padding-right: 5px;
      @include textEllipsis;
      max-width: 90%;
    }

    .vis-item-content {
      padding: 0px;
      white-space: normal;
      text-align: center;
      white-space: normal;
      text-align: left;
      background-color: $navigation-panel-bg-color;
      font-size: 13px;
      @include textEllipsis;
      width: auto !important;
      max-width: 95%;

      img {
        height: 70px;
      }
      div {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #1b1d2a;
      }
      .mediaTimelineItemContent {
        min-width: 150px;
        max-width: 200px;
        label.visItemLabel {
          position: absolute;
          top: 0px;
          left: 0px;
        }
        img {
          width: 50%;
          object-fit: cover;
          height: 70px;
        }
        img.dummy {
          width: auto;
        }
      }
      .mediaTimelineItemContent.interactItem {
        img {
          width: auto;
        }
      }
    }

    &.vis-selected {
      border-color: #ffffff;
      border-width: 1px;
      opacity: 1;
    }
    &.vis-selected:not(.video):after {
      //don't club before and after css
      position: absolute;
      content: "";
      display: inline-block;
      vertical-align: middle;
      color: #000000;
      background: #000000 content-box;
      top: 18px;
      left: -4px;
      border: 2px solid #000000;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
    &.vis-selected:not(.video):before {
      position: absolute;
      content: "";
      display: inline-block;
      vertical-align: middle;
      color: #000000;
      background: #000000 content-box;
      top: 18px;
      right: -4px;
      border: 2px solid #000000;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
    &.mediaItem {
      background-color: #0053D8;
      opacity: 0.4;
    }
    &.vis-selected.mediaItem {
      border-color: $navigation-panel-bg-color;
      background-color: #0053D8;
      opacity: 0.1;
    }

    .vis-delete:after {
      content: url("./../../../assets/images/trash_icon.svg");
      color: #f53240;
    }

    .vis-delete:hover {
      background: transparent;
    }
  }

  .vis-custom-time {
    background-color: #fff;
    width: 2px;
    cursor: move;
    z-index: 5;

    div {
      &:after {
        //fix for Edge browser
        content: url("./../../../assets/images/timeline_bar_head_vertical.svg");
        width: 19px;
        left: 6px;
        top: -1px;
        position: absolute;
      }
    }
  }
  #pointerTimeDisplay {
    color: white;
    position: absolute;
    top: 26px;
    left: -25px;
    z-index: 3;
    background-color: black;
    cursor: move;
  }
}
