.icon-library {
	& .MuiPaper-root {
		height: 85vh;
	}
	.container {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 1rem 0;
		overflow: hidden;

		.title-section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #dbe9ff;
			padding: 0 1.5rem 5px 1.5rem;

			& .close-button {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-bottom: 0;
				min-width: 20px;
				background-color: #eaf0fe;

				& svg {
					width: 10px;
					height: 10px;
				}
			}
		}

		.search-section {
			display: flex;
			justify-content: flex-end;
			padding: 0 1.5rem 10px 1.5rem;

			.search-box {
				width: 22rem;
				border-bottom: 1px solid #999a9b;
			}
		}

		.icons-section {
			flex-grow: 1;
			overflow: auto;
			padding: 0 1.5rem;
			border-top: 1px solid #E5E9EC;

			.icons-container {
				height: 100%;

				.icons-grid {
					background-color: #f7f8fa;
					padding: 2%;
					height: 100%;
					overflow: auto;

					& .icon {
						display: inline-block;
						width: 100px;
						height: 110px;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
						margin: 0 4px;
						font-size: 12px;
						border: 1px solid #ced4d8;
						border-radius: 10px;
						margin: 5px;
						padding: 5% 1%;
						transition: all 0.1s ease-in;
						cursor: pointer;

						&:hover {
							border: 1px solid #326af3;

							& svg {
								color: #326af3;
							}

							& p {
								color: #326af3;
							}
						}

						& svg {
							width: 2rem;
							height: 2rem;
						}

						& p {
							margin: 0;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: #999a9b;
							margin-top: 15%;
						}
					}
				}
			}
		}
	}
}
