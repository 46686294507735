.advancedSearchOptions {
	border-top: 1px solid #eaeaf2;
}

.advancedSearchSection {
	display: grid;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1.5fr 2fr 1fr;
	border-bottom: 1px solid #eaeaf2;
	.advanced-search-row-1,
	.advanced-search-row-2 {
		display: grid;
		justify-content: center;
		grid-template-rows: auto;
		grid-template-columns: repeat(4, 1fr);
		border-bottom: 1px solid #eaeaf2;
	}
	.fromToDateWrappersInternal {
		display: grid;
		justify-content: center;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		.fromToDateWrappersInternalRowLabel {
			top: 0px;
		}

		.MuiFilledInput-adornedStart {
			padding-left: 0px !important;
		}
		.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
			margin-top: 0px !important;
		}
		.MuiFilledInput-input {
			padding: 6px 0 6px !important;
		}
		.MuiFormControl-marginNormal {
			margin-top: 8px !important;
			margin-bottom: 5px !important;
		}
		.MuiFilledInput-root {
			border: 1px solid #cfd5e5;
			border-radius: 4px;
			background-color: #f7f8fa;
		}
		.MuiFilledInput-underline:before {
			border-bottom: none;
		}
		.MuiInputAdornment-positionStart {
			margin-right: 0px;
		}
		.DatePickerInternalWrapper {
			display: flex;
			align-items: center;
			.datePickerClear {
				margin-left: -25px;
				z-index: 999;
				margin-top: 8px;
				cursor: pointer;
			}
		}
	}
	.search-options-block_1,
	.search-options-block_2,
	.search-options-block_3,
	.search-options-block_4,
	.search-options-block_5 {
		width: 100%;
		height: 100%;
		padding: 12px 24px;
		&:not(:last-child) {
			border-right: 1px solid #eaeaf2;
		}
		.main_label {
			font-weight: 600;
			font-size: 9px;
			text-transform: uppercase;
		}
	}
	.search-options-block_5 {
		.tagInput {
			width: 217px;
			height: 30px;
			background: #eaedf4 0% 0% no-repeat padding-box;
			border: 0.5px solid #cfd5e5;
			border-radius: 4px;
			&:focus-visible {
				border: 1px solid #126cfc !important;
			}
		}
	}
	.search-options-block_1 {
		.searchFiltersDropDownWrapper {
			align-items: center;
			display: flex;
			> .searchFiltersDropdownInternalWrapper {
				width: 100%;
				position: relative;
			}
			.input-icon {
				height: 10px;
				position: absolute;
				margin: 10px;
				width: 10px;
				position: absolute;
			}
			.searchFiltersMenu {
				width: 100%;
				height: 30px;
				border: 1px solid #cfd5e5;
				border-radius: 4px;
				background-color: #f7f8fa;
				font-size: 11px;
				margin-top: 8px;
				padding-left: 32px;
				text-align: left;
				display: flex;
				align-items: center;
				flex-basis: 50%;
				cursor: pointer;
				label {
					width: 85%;
					margin-right: 5px;
					cursor: inherit;
				}
				svg {
					width: 10px !important;
					height: 10px !important;
					right: 10px;
					position: absolute;
					cursor: inherit;
				}
			}
		}
	}
}

.search-options-block_3 {
	& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
		flex-basis: auto !important;
	}
}

.advancedSearchActionSection {
	background-color: #ffffff;
	.advancedSearchActions {
		min-height: 40px;
		width: 100%;
		padding: 24px 12px 12px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		.update-filter {
			background-color: #ffffff !important;
			color: #126cfc !important;
			margin-right: 5px;
			font-size: 11px !important;
		}
		.save-btn {
			background: white !important;
			color: #000 !important;
			font-weight: 600;
			border: 1px solid #eaeaf2 !important;
			svg {
				fill: #000 !important;
			}
		}
		.search-btn {
			background: #126cfc1a !important;
			color: #126cfc !important;
			font-weight: 600;
			margin-left: 24px;
			padding: 5px 24px;
		}
		.save-label {
			margin-right: 5px;
			font-size: 11px !important;
			.expanded_white {
				margin-top: 3px !important;
			}
			svg {
				width: 8px !important;
				height: 8px !important;
				margin-top: 3px;
			}
		}
	}
	.advancedSearchCollapse {
		background-color: #f4f5f7;
		width: 100%;
		//display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 5px 0;
		cursor: pointer;
		.button_collapse {
			width: 100%;
			height: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			//cursor: pointer;
			img {
				//height: 5px;
			}
		}
		.expand_search_label {
			width: 100%;
			display: block;
			text-align: center;
			color: #999ea7;
			font-size: 11px;
			cursor: pointer;
		}
	}
}

.advancedButtonActive {
	.advancedSearchCollapse {
		height: 15px;
		display: flex;
	}
}
