@import "../../global";


.LiveManagerBottomPanelWrapper {
    background: transparent !important;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    position: relative;

    .timelineOverlay {
        position: absolute !important;
        height: 96%;
        width: 100%;
        > div {
            position: relative;
        }
    }

    .liveStreamTimelineText {
        color: #fff;
        margin: 0 auto;
        text-align: center;
    }
    
    .hideDisplay {
        .vis-timeline.vis-bottom  {
            visibility: hidden !important;
            min-height: 0px !important;
            max-height: 0px !important;
        }        
    }

    .showDisplay {
        .vis-timeline.vis-bottom  {
            visibility: visible !important;
        }        
    }

    .liveStreamBottomText {
        color: #ffffff;
        display: table;
        width: 100%;
        height: inherit;
        text-align: center;
        label {
            display: table-cell;
            vertical-align: middle;
        }
    }
    //border-radius: 0.2em;
    #vis-timeline-container{
        width: 99.5%; 
    }
    

    .sliderRoot {
        width: 98%;
        margin-left: 1%;
        display: inline-block;
        cursor: pointer;
        .slider {
            padding: 15px 0px;
        }

        .trackBefore {
            background-color: #FFF
        }

        .trackAfter {
            background-color: #FFF
        }

        .thumb {
            background-color: #FFF
        }
    }

    //timeline custom css
    .vis-timeline {
        //overflow-y:auto;
        &.vis-bottom {
           // border-radius: 10px 0px 10px 10px; //setting right as 0 because vertical pointer was changing shape
            border: 0px !important;
            min-height: 75px !important;
            max-height: 1000px !important;
            width: 100% !important;
        }
    }

    .vis-background {
        overflow: initial; //added to highlight vertical pointer
    }

    .vis-itemset {
        .vis-background {
            background-color: #EFEFEF;
        }

        .vis-foreground {
            background-color: $live-interact-element-hover !important;
            .vis-group {
                height: 49px !important;
               // border-bottom: 1px solid $live-interact-element-hover;
                margin-top: 2px;
                border: 0px;
                background-color: $live-interact-header;
            }
            .vis-group.mediaGroup {
                height: 70px !important;
            }
        }
    }

    .vis-time-axis {
        height: 46px !important;
        .vis-grid.vis-vertical {
            border-left: none;
        }
        .vis-text {
            /* white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;*/
             color: #ffffff;
             top: 5px;
             z-index: 3;
         }
    } 

    .vis-panel.vis-left {
        border: 0px !important;
        .vis-labelset {
            .vis-label {
                @include alignTextImageCenter;
                height: 49px !important;
                margin-top: 2px;
                background-color: transparent !important;
                border: 0px !important;
            }
            .vis-inner {
                img {
                    height: 20px !important;
                    width: 20px !important;
                    align-content: center;
                    padding: 3px;
                }
            }
        }
    }
    .vis-panel.vis-center,
    .vis-panel.vis-bottom,
    .vis-panel.vis-top {
        border: 0px !important;
    }

    .vis-panel.vis-top {
        font-size: 1.2rem;
    }

    .vis-item {
        color: white;
        height: 100%;
        border-radius: 0px !important;
        border-width: 0px;
        .vis-item-content {
            white-space: normal;
            text-align: center;
            white-space: normal;
            text-align: left;
            background-color: $navigation-panel-bg-color;
            font-size: 13px;
            @include textEllipsis;
            width: auto !important;
            max-width: 95%;
            transform:none !important;
        }

        &.vis-selected {
            border-color: #ffffff;
            border-width: 1px;
            border-radius: 1px !important;
        }

        &.vis-selected:after { //don't club before and after css
            position: absolute;
            content: "";
            display:inline-block;            
            vertical-align:middle;
            color: #000000;
            background:#000000 content-box;
            top: 18px;
            left: -4px;
            border: 2px solid #000000;
            border-radius: 50%;
            width: 8px;
            height: 8px;
        }
        &.vis-selected:before {
            position: absolute;
            content: "";
            display:inline-block;            
            vertical-align:middle;
            color: #000000;
            background:#000000 content-box;
            top: 18px;
            right: -4px;
            border: 2px solid #000000;
            border-radius: 50%;
            width: 8px;
            height: 8px;
        }

        &.snapshot-initial {
            background-color: #7365E2;
        }

        &.snapshot,
        &.interactItem {
            border: 0px;
            background-color: #7365E2;
            border-color: #ffffff;
            border-radius: 0px;
            border-width: 2px;
            opacity: 0.4;
        }
        &.vis-selected.snapshot,
        &.vis-selected.interactItem {
            border: 1px solid #ffffff;           
            background-color: #7365E2;
            opacity: 1;
        }

        &.media,
        &.live {
            //background-color: #1E2239;
            background-color: #126CFC;
            opacity: 0.4;
        }
        &.vis-selected.live,
        &.vis-selected.media {
            background-color: #126CFC;
            opacity: 1;
        }

        &.live {
            pointer-events: none;
           /* border: 0px !important;
            border-radius: 0px;
            &:before {
                border: 0px !important;
                border-radius: 0px !important;
                background: transparent !important;
            }
            &:after {
                border: 0px !important;
                background: transparent !important;
                border-radius: 0px !important;
            }*/
        }

        &.interactItem,
        &.media {
            &:before {
                border: 0px !important;
                border-radius: 0px !important;
                background: transparent !important;
            }
            &:after {
                border: 0px !important;
                background: transparent !important;
                border-radius: 0px !important;
            }
        }

        .vis-delete:after {
            content: url('./../../assets/images/trash_icon.svg');
            color: #F53240;
        }

        .vis-delete:hover {
            background: transparent;
        }
    }

    .vis-custom-time {
        background-color: #fff;
        width: 2px;
        cursor: move;
        z-index: 5;

        div {
            &:after {
                content: url('./../../assets/images/timeline_bar_head_vertical.svg');               
                width: 19px;
                left: 6px;
                top: -1px;
                position: absolute;
            }
        }
    }
    #pointerTimeDisplay {
        color: white;
        position:absolute;
        top: 26px;
        left: -25px;            
        z-index: 3;
        background-color: black;
        cursor: move;       
    }
    #pointerTimeDisplay.live {
        background-color: green;
    }
}