.confirmationPopup {
    text-align: center;
    .dialogTitleContentWrapper {
        label {
            font-weight: 600;
            font-size: 16px;
            max-width: 80%;
        }
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modalBoxHeader {
        text-align: center;
    }
    .confirmationTitle {
        padding: 20px 60px;
        white-space: normal;
    }
    label {
        font-size: 14px;
        letter-spacing: normal;
        font-weight: normal;
        color: #000 !important;
        max-width: 100%;
        @include textEllipsis;
    }
    .dialogContentWrapper {
        text-align: center;
        @include addGreyScrollBar;
        margin-top: 0px;
        .itemsList {
            label {
                font-weight: bold;
                display: block;
            }
        }
    }
}
