@import "../../../../../global";
#linksSection {
    display: flex;
    justify-content: center;
    .previewAndShareBlock {
        display: flex;
        flex-direction: row;
        //align-items: center;


        &:nth-child(1) { margin-bottom: 10px; margin-top: 10px; }
        &:nth-child(4) { margin-bottom: 10px }
        label {
            cursor: pointer;
        }
    }
    .linksBlock {
        //margin-top: 7px;
        //margin-bottom: 30px;
       
        img {
            height: 20px;
            padding: 2px 5px 2px 15px;
            width: auto;
        }
        span {
            font-weight: 600;
            font-size: 12px;
            color: #383838;
        }
      .copyImg{
          transform: translateX(11px) translateY(4px);
      }
    }
    .borderWrapperCopy {
        display: flex;
        align-items: center;
        border: 1px solid $btn-default-color-active;
        border-radius: 2px;
        height: 35px;
        width: 115px;
        margin: 5px 5px 5px 0px;
        //border-radius: 5px;
        //border: none;
        background-color: $btn-default-color-active;
        //padding-bottom: 5px;
        cursor: pointer;

        a { 
            
            margin-left: 8px;
            margin-right: 10px;
            margin-bottom: -20px;
            font-size: 11px;
            cursor: pointer; 
        }
        span {
            color: #FFFFFF;
        }
    }
    .borderWrapperCopy:hover{
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.08);
        background-color: $btn-default-color-hover;
    }

    .borderWrapperCopy:active{
        background-color: $btn-default-color-hover;
        //border: solid 1px #075DAC;
        border-color: $btn-default-color-hover;
        a {
            color: white;
        }

        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(170deg) brightness(102%) contrast(104%);
        }
        span {
            color: #FFFFFF;
        }
    }

    .borderWrapperPreview {
        display: flex;
        align-items: center;
        border: 1px solid #383838;
        margin: 5px;
        width: 115px;
        height: 35px;
        border-radius: 2px;
        //border: none;
        background-color: #FFFFFF;
        //padding-bottom: 2px;
        cursor: pointer; 
         
        a { 
            /*margin-left: 8px;
            margin-right: 10px;
            font-size: 11px;
            cursor: pointer;*/
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            margin-right: 0px;
        }
        img{
            border-radius: 2px;
            //transform: translateY(1px);
            height: 15px;
            width: 45px;
            //padding: 4px 0px 4px 10px;
        }
        span {
            color: #383838;
            font-size: 12px;
            font-weight: 600;
        }

    }
    .borderWrapperPreview:hover{
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.08);
    }

    .borderWrapperPreview:active{
        background-color: $btn-default-color-hover;
        //border: none;
        a {
            color: white;
        }

        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(170deg) brightness(102%) contrast(104%);
        }
    }

    .borderWrapperDownload {
        height: 35px;
        margin: 5px;
        border: 1px solid #383838;
        display: flex;
        align-items: center;
        border-radius: 2px;
        img {
            height: 13px;
            padding: 0px 8px;
        }
        &:hover {
            box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.08);
        }
    }
}