.poll-container {
	&__header {
		padding: 12px;
		display: flex;
		align-items: center;
		border-top: 1px solid #1a1c26;
		&--collapsible {
			cursor: pointer;
			&:hover {
				font-weight: 600;
			}
		}
		&.poll-container__header--collapsible {
			padding: 6px 12px;
		}
	}

	&__polls {
		position: relative;
		padding: 0px 12px;
		&:last-child {
			border-bottom: 1px solid #1a1c26;
		}
	}

	&__message {
		color: #ffffff99;
		text-align: center;
		padding: 0px 12px 12px 12px;
	}
}
