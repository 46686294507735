@import '../../global';
#root {
	overflow-y: hidden !important;
}
#publishMediaScreen {
	position: relative;
	display: flex;
	width: 100%;
	height: calc(100vh - 45px);
	#librarySidebar {
		width: 18%;
		float: left;
		height: inherit;
	}
	#libraryView {
		height: 100%;
		float: right;
		width: 82%;
		position: relative;
	}
	.paginationContentWrapper {
		bottom: 0;
		border-top: 1px solid #eaeaf2;
		width: 100%;
		background-color: #ffffff;
		position: absolute;
		z-index: 1001;
		max-height: 100%;
		height: auto;
	}

	@media (max-width: 800px) {
		#librarySidebar {
			display: block;
			width: 25%;
		}
		#libraryView {
			float: right;
			width: 75%;
		}
		.noMediaMessageSection {
			height: calc(100% - 250px);
			margin-bottom: 10px;
		}
		.paginationContentWrapper {
			padding: 10px;
		}
	}

	@media only screen and (max-width: 800px) and (orientation: landscape) {
		.mediaContentWrapper {
			&[medialength='0'] {
				height: calc(100% - 40px);
			}
		}
		.paginationContentWrapper {
			position: relative;
		}
	}

	@media only screen and (max-height: 400px) and (orientation: landscape) {
		.mediaContentWrapper {
			&[medialength='0'] {
				height: auto;
			}
		}
	}

	@media only screen and (max-width: 640px) {
		#librarySidebar {
			width: 0%;
			display: none;
		}
		#libraryView {
			float: right;
			width: 100%;
		}
		.paginationContentWrapper {
			border-top: 1px solid #eaeaf2;
			width: 100%;
			height: 120px;
		}
	}

	@media only screen and (max-width: 640px) and (max-height: 740px) {
		#librarySidebar {
			width: 0%;
			display: none;
		}
		#libraryView {
			float: right;
			width: 100%;
		}
	}

	@media only screen and (max-width: 640px) and (max-height: 650px) {
		#librarySidebar {
			width: 0%;
			display: none;
		}
		#libraryView {
			float: right;
			width: 100%;
		}

		.medialListView {
			tbody#listBodyContainer {
				max-height: 58vh !important;
			}
		}
	}

	.mediaInternalGridContainer {
		display: grid;
		justify-content: center !important;
		grid-template-rows: auto;
		grid-gap: 1.5rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media (max-width: 480px) {
			grid-template-columns: 1fr;
		}
		@media (min-width: 481px) and (max-width: 700px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (min-width: 701px) and (max-width: 1024px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media (min-width: 1025px) and (max-width: 1400px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (min-width: 1401px) and (max-width: 2000px) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
		@media (min-width: 2000px) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}

	.isAdvancedSearchOpen {
		@media (max-width: 480px) {
			max-height: 22vh !important;
		}
		@media (min-width: 481px) and (max-width: 767px) {
			max-height: 25vh !important;
		}
		@media (min-width: 481px) and (max-width: 767px) {
			max-height: 27vh !important;
		}
		@media (min-width: 1025px) and (max-width: 1280px) {
			max-height: 30vh !important;
		}
		@media (min-width: 1281px) and (max-width: 1660px) {
			max-height: 34vh !important;
		}
		@media (min-width: 1661px) and (min-height: 701px) and (max-height: 950px) {
			max-height: 50vh !important;
		}
		@media (min-width: 1661px) and (min-height: 951px) and (max-height: 1500px) {
			max-height: 55vh !important;
		}
	}
}

.checkbox_white_fill {
	.st0 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #ffffff;
	}
	.st1 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #126cfc;
	}
}

#singleMediaDropDownList {
	width: 100%;
	li {
		background-color: transparent;
	}
	li:hover {
		background-color: $menuItems-bg-color-hover;
	}
	li:active {
		background-color: $link-color;
	}
}

.notReady {
	opacity: 0.5;
	pointer-events: none;
}

#selectAllNoneMenuSystem {
	margin-left: -75px;
	margin-top: 10px;
	width: 150px;
}

#actionsMenuSystem {
	margin-left: -50px;
	margin-top: 10px;
	img {
		width: 30px;
		height: 16px;
		float: left;
	}
}

.fullWidthControl {
	width: 100% !important;
	> select {
		width: 100% !important;
	}
	> input[type='text'] {
		margin-top: 8px;
		height: 35px;
		border-radius: 5px;
		border: 1px solid $media-content-border-color;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}
}
.halfWidthControl {
	width: 45% !important;
	> select {
		width: 100% !important;
		margin-top: 10px;
	}
	&:after {
		top: 65% !important;
	}
	> label {
		color: $default-label-color;
		letter-spacing: 1px;
		font-size: $default-font-size;
		font-weight: bold;
	}
	> input[type='text'] {
		margin-top: 8px;
		height: 35px;
		border-radius: 5px;
		border: 1px solid $media-content-border-color;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}
}
.hide {
	display: none !important;
}
h1.noMedia {
	position: absolute;
	top: 50%;
	left: 45%;
}
#mediaFilterOptionsList {
	svg {
		margin-left: 0px !important;
		margin-right: 10px;
		fill-rule: evenodd;
		fill: #000;
	}
	svg.checkbox_fill {
		fill: #126cfc;
		fill-rule: evenodd;
	}
	.MuiListItem-button:hover .tooltiptext {
		display: block;
	}
	#mediaFilterTooltip {
		top: 47px;
		left: 42px;
	}
	#showPlaylistsTooltip {
		top: 94px;
		left: 42px;
	}
}

#listingMenuSystem {
	.menuItemsBtn {
		width: auto;
		flex: none;
		> *:first-child {
			margin-right: 10px;
		}
	}
}

#mediaFilterOptions {
	width: 100%;
	border-bottom: 1px solid #eaeaf2;
	padding-left: 10px;
	display: inline-flex;
	padding: 10px;
	#sortByTypeSortingDropdown {
		#sortingMenuButton {
			.sortingMenuButtonContent {
				border-bottom: 0.1px solid #000;
				display: inline-flex;
				align-items: center;
			}
			svg {
				height: 15px !important;
			}
		}
	}
	svg {
		fill-rule: evenodd;
		fill: #000;
		flex: 1 0 auto;
	}
	svg.checkbox_fill {
		fill: #126cfc;
		fill-rule: evenodd;
	}
	select {
		appearance: none;
		/* for Firefox */
		-moz-appearance: none;
		/* for Chrome */
		-webkit-appearance: none;
	}
	/* For IE10 */
	select::-ms-expand {
		display: none;
	}
	svg.expanded {
		fill: #000;
		padding-bottom: 9px;
		margin-top: 8px;
	}
}
.mediaContentWrapper {
	-webkit-flex-direction: row;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
	padding-bottom: 0;
	background-color: $header-bg-color;
	margin-left: 0;
	margin-top: 0;
	height: calc(100% - 60px);

	.scenario-empty-view {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.wrapper {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			width: 100%;
			margin: 10%;

			@media only screen and (max-width: 2100px) {
				& {
					font-size: 40px;
				}
			}

			@media only screen and (max-width: 1200px) {
				& {
					font-size: 40px;
				}
			}
			@media only screen and (max-width: 1024px) {
				& {
					margin: 10px;
				}
			}
			@media only screen and (max-width: 768px) {
				& {
					margin: 10px;
				}
			}

			.video-wrapper {
				width: 80%;
				max-width: 430px;
			}

			.content-wrapper {
				margin-left: 50px;
				width: 100%;
				max-width: 400px;

				& h2 {
					font-family: 'Lora', serif !important;
					font-size: 45px;
					font-weight: bold;
					margin-bottom: 10px;

					@media only screen and (max-width: 2100px) {
						& {
							font-size: 45px;
						}
					}

					@media only screen and (max-width: 1200px) {
						& {
							font-size: 37px;
						}
					}
					@media only screen and (max-width: 1024px) {
						& {
							font-size: 30px;
						}
					}
					@media only screen and (max-width: 768px) {
						& {
							font-size: 30px;
						}
					}
				}

				& .subtitle {
					margin-bottom: 30px;
				}

				& .button {
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}

	&[medialength='0'] {
		height: calc(100% - 120px);
	}

	.monthDisplayCalendar {
		padding: 0px 10px;
		height: 40px;
		display: flex;
		align-items: center;
	}
	.filtersSection {
		display: inline-block;
		height: 48px;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.sortingDropDownWrapper,
		.selectAllWrapper,
		.catalogSwitchChange,
		.listingIconsSection,
		.catalogSwitchWrapper,
		.filterMediasOption,
		.HeaderActionsDeleteBtnWrapper,
		.actionsSection {
			margin-right: 20px;
			float: left;
			cursor: pointer;
			svg:first-child {
				width: 15px !important;
				height: 15px !important;
			}
		}

		.tooltiptext {
			position: absolute;
			width: auto;
			max-width: none;
			font-size: 10px;
			color: white !important;
			background-color: #000000;
			opacity: 0.8;
			border: none;
			text-align: center;
			padding: 5px 10px;
			transform: translateY(28px) translateX(-20%);
			display: none;
			z-index: 1;
		}
		.filterMediasOption:hover,
		.listingIconsSection:hover,
		.selectAllWrapper:hover,
		.catalogSwitchChange:hover {
			.tooltiptext {
				display: block;
			}
		}
		#searchInputBox:hover + .tooltiptext:not(.clear_search_text) {
			transform: translateY(0px);
			display: block;
		}
		.clear_search_img:hover + .clear_search_text {
			transform: translateY(0px) translateX(150px);
			display: block;
		}

		.listingIconsSection {
			display: flex;
			border-radius: 5px;
			flex-direction: row;
			justify-content: space-around;
			height: 37px;
			overflow: hidden;
			padding: 10px;
			width: 55px;
			&:hover {
				box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				border-radius: 5px;
				height: 37px;
			}

			button:first-child {
				margin-right: 10px;
			}
			button:last-child {
				margin-left: 0px;
			}

			.tooltiptext {
				transform: translateY(28px) translateX(10px);
			}
		}

		.formControl {
			min-width: 200px;
		}

		.selectAllWrapper,
		.catalogSwitchChange,
		.listingIconsSection,
		.filterMediasOption,
		.HeaderActionsDeleteBtnWrapper {
			width: 60px !important;
			border-radius: 5px;
			margin-right: 10px;
			display: flex;
			flex-direction: row;
			height: 37px;
			align-items: center;
			padding-left: 8px;
			padding-right: 8px;

			> button {
				width: 100%;
				height: 100%;
				border: none;
				background: transparent;

				> svg {
					fill: $primary-filtering-icons-color;
					background: transparent;
					border: none;
					justify-content: center;
					display: flex;
					flex-direction: column;
					width: 12px;
					height: 12px;
				}

				&:focus {
					outline: none;
				}
			}
			svg:last-child {
				margin-left: 10px;
			}
		}

		.selectAllWrapper {
			width: 55px !important;

			button {
				cursor: pointer;
			}

			&:hover {
				box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				border-radius: 5px;
				height: 37px;
				padding-right: 5px !important;
			}
		}

		.searchInputWrapper-new {
			float: left;
			cursor: pointer;
			padding-left: 8px !important;
			margin-right: 10px;
			display: flex;
			flex-direction: row;
			height: 37px;
			align-items: center;
			.expandedSearchInput {
				font-size: 11px !important;
				width: 175px;
				border-radius: 4px;
				outline: 0;
				background-color: #fff;
				height: 30px;
				padding: 0 15px 0 30px;
				border: 1px solid #1d71f8;
				&:hover {
					box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					border-radius: 4px;
				}
				&:focus {
					background-color: #fff;
					border: 1px solid #1d71f8;
				}
				input::-webkit-search-cancel-button {
					display: block;
					cursor: pointer;
				}
			}
			.collapsedSearchInput {
				width: 36px;
				border-radius: 4px;
				outline: 0;
				background-color: #f4f5f7;
				height: 30px;
				padding: 0 18px;
				border: none;
				&:hover {
					box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					border-radius: 4px;
				}
				&:focus {
					background-color: #fff;
					border: 1px solid #1d71f8;
				}
			}

			.search-icon {
				position: relative;
				right: -25px;
				width: 14px;
			}

			.clear-icon-show {
				position: relative;
				left: -26px;
				width: 25px;
				cursor: pointer;
			}

			.clear-icon-hide {
				visibility: hidden;
			}
			.search-icon:hover {
				.expandedSearchInput {
					font-size: 11px !important;
					box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					border-radius: 4px;
				}
			}
		}

		.advancedSearchButtonWrapper {
			display: none !important;
			float: left;
			cursor: pointer;
			padding-left: 8px !important;
			border-radius: 5px;
			margin-right: 10px;
			display: flex;
			flex-direction: row;
			height: 37px;
			align-items: center;
			.advancedSearchButton {
				input {
					height: 30px;
					padding: 0 10px 0 15px;
					border: none;
					border-radius: 4px;
					cursor: pointer;
					font-size: 11px;
					&:hover {
						box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
						-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
						-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
					}
				}
				.advancedButtonActive {
					background-color: #92bbfd;
					padding: 0 35px 0 15px !important;
				}
				.input-icon {
					height: 30px;
					position: absolute;
					margin: 0px 0px 0px -25px;
					width: 25px;
					padding: 5px 0;
				}
			}
		}

		.catalogSwitchChange.highlightCatalogContainer {
			border: 1px solid $customer-primary;
			background: $customer-primary;
		}

		.catalogSwitchChange {
			cursor: pointer;
			> svg {
				.cls-1,
				.cls-2 {
					fill: $header-bg-color;
				}
				background: transparent;
				border: none;
				justify-content: center;
				display: flex;
				flex-direction: column;
				width: 20px;
				height: 20px;
				fill: #6c7473;
			}
		}

		.actionsSection {
			display: flex;
			border-radius: 5px;
			width: 110px;
			flex-direction: row;
			justify-content: space-around;
			padding-top: 0;
			padding-bottom: 0;
			height: 37px;
			align-items: center;
			overflow: hidden;

			&:hover {
				box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
				border-radius: 5px;
			}

			.actionsLabel {
				display: block;
				float: inherit;
			}
			.actionsImage {
				display: none;
			}

			> button {
				margin-left: 0;
				align-items: center;
				border: none;
				background: transparent;
				color: $media-item-header-bg-color;
				font-size: 12px;
				letter-spacing: 1px;
				margin-left: 0;
				position: relative;
				left: -10px;
				height: 12px;
				width: 12px;
				cursor: pointer;

				> svg {
					fill: $primary-filtering-icons-color;
					width: 100%;
					height: 100%;
				}

				&:focus {
					outline: none;
				}
			}
		}

		.extraActions {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			padding-top: 0;
			padding-bottom: 0;
			height: 36px;
			align-items: center;
			overflow: hidden;
			float: left;
			border-radius: 4px;
			margin-right: 5px;
			button {
				background-color: #f4f5f7 !important;
				min-width: 50px !important;
				pointer-events: auto !important;
				&:hover {
					background-color: #e7eaf0 !important;
					+ .tooltiptext {
						display: block;
					}
				}
			}
			.addToPlaylistTooltip {
				max-width: none;
				transform: translateY(28px) translateX(0px);
			}
			.addToCatalogTooltip {
				max-width: none;
				transform: translateY(28px) translateX(-30px);
			}
			.deleteSelectionTooltip {
				transform: translateX(40px) translateY(28px);
			}

			.defaultActionBtn {
				svg {
					fill: $btn-default-color-active !important;
				}
			}
			.deleteUserBtn {
				svg {
					fill: $default-alert-btn-bg-color !important;
				}
			}
			.iconButton {
				display: flex;
				&:hover {
					cursor: pointer;
				}
			}
			.actionsLabel {
				display: block;
				float: inherit;
			}
			.actionsImage {
				display: none;
			}

			svg {
				width: 15px;
				height: 15px;
				margin-right: 5px;
			}
			label {
				color: #6c7473;
			}
			button {
				padding: 0px 10px !important;
			}
		}

		.newBtnStyle {
			font-size: 12px !important;
		}

		.squireBtn {
			border-radius: 0px;
			padding-top: 6px !important;
		}

		.uploadsSection {
			text-align: right;
			flex: 1;
			button {
				font-weight: bold;
			}
			.plusSign {
				font-size: 25px;
				line-height: 8px;
				margin-right: 10px;
			}
			svg {
				width: 15px !important;
				height: 15px !important;
				margin-right: 5px;
			}
		}
	}

	.advancedSearchSection {
		display: grid;
		justify-content: center;
		grid-template-rows: auto;
		grid-template-columns: repeat(4, 1fr);
		border-bottom: 1px solid #eaeaf2;
		.advanced-search-row-1,
		.advanced-search-row-2 {
			display: grid;
			justify-content: center;
			grid-template-rows: auto;
			grid-template-columns: repeat(4, 1fr);
			border-bottom: 1px solid #eaeaf2;
		}
		.fromToDateWrappersInternal {
			align-items: center;
			display: flex;
			.fromToDateWrappersInternalRowLabel {
				top: 0px;
			}

			.MuiFilledInput-adornedStart {
				padding-left: 0px !important;
			}
			.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
				margin-top: 0px !important;
			}
			.MuiFilledInput-input {
				padding: 6px 0 6px !important;
			}
			.MuiFormControl-marginNormal {
				margin-top: 8px !important;
				margin-bottom: 5px !important;
			}
			.MuiFilledInput-root {
				border: 1px solid #cfd5e5;
				border-radius: 4px;
				background-color: #f7f8fa;
			}
			.MuiFilledInput-underline:before {
				border-bottom: none;
			}
			.MuiInputAdornment-positionStart {
				margin-right: 0px;
			}
			.DatePickerInternalWrapper {
				display: inline-flex;
				align-items: center;
				.datePickerClear {
					margin-left: -25px;
					z-index: 999;
					margin-top: 8px;
					cursor: pointer;
				}
			}
		}
		.search-options-block_1,
		.search-options-block_2,
		.search-options-block_3,
		.search-options-block_4,
		.search-options-block_5 {
			width: 100%;
			height: 100%;
			padding: 12px 24px;
			&:not(:last-child) {
				border-right: 1px solid #eaeaf2;
			}
			.main_label {
				font-weight: 600;
				font-size: 9px;
				text-transform: uppercase;
			}
		}
		.search-options-block_5 {
			.tagInput {
				width: 217px;
				height: 30px;
				background: #eaedf4 0% 0% no-repeat padding-box;
				border: 0.5px solid #cfd5e5;
				border-radius: 4px;
				&:focus-visible {
					border: 1px solid #126cfc !important;
				}
			}
		}
		.search-options-block_1 {
			.searchFiltersDropDownWrapper {
				align-items: center;
				display: flex;
				> .searchFiltersDropdownInternalWrapper {
					width: 100%;
					position: relative;
				}
				.input-icon {
					height: 10px;
					position: absolute;
					margin: 10px;
					width: 10px;
					position: absolute;
				}
				.searchFiltersMenu {
					width: 100%;
					height: 30px;
					border: 1px solid #cfd5e5;
					border-radius: 4px;
					background-color: #f7f8fa;
					font-size: 11px;
					margin-top: 8px;
					padding-left: 32px;
					text-align: left;
					display: flex;
					align-items: center;
					flex-basis: 50%;
					cursor: pointer;
					label {
						width: 85%;
						margin-right: 5px;
						cursor: inherit;
					}
					svg {
						width: 10px !important;
						height: 10px !important;
						right: 10px;
						position: absolute;
						cursor: inherit;
					}
				}
			}
		}
	}

	.search-options-block_3 {
		& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
			flex-basis: auto !important;
		}
	}
	.advancedSearchActionSection {
		background-color: #ffffff;
		.advancedSearchActions {
			min-height: 40px;
			width: 100%;
			padding: 24px 12px 12px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			.update-filter {
				background-color: #ffffff !important;
				color: #126cfc !important;
				margin-right: 5px;
				font-size: 11px !important;
			}
			.save-btn {
				background: white !important;
				color: #000 !important;
				font-weight: 600;
				border: 1px solid #eaeaf2 !important;
				svg {
					fill: #000 !important;
				}
			}
			.search-btn {
				background: #126cfc1a !important;
				color: #126cfc !important;
				font-weight: 600;
				margin-left: 24px;
				padding: 5px 24px;
			}
			.save-label {
				margin-right: 5px;
				font-size: 11px !important;
				.expanded_white {
					margin-top: 3px !important;
				}
				svg {
					width: 8px !important;
					height: 8px !important;
					margin-top: 3px;
				}
			}
		}
		.advancedSearchCollapse {
			background-color: #f4f5f7;
			width: 100%;
			//display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 5px 0;
			cursor: pointer;
			.button_collapse {
				width: 100%;
				height: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				//cursor: pointer;
				img {
					//height: 5px;
				}
			}
			.expand_search_label {
				width: 100%;
				display: block;
				text-align: center;
				color: #999ea7;
				font-size: 11px;
				cursor: pointer;
			}
		}
	}

	.advancedButtonActive {
		.advancedSearchCollapse {
			height: 15px;
			display: flex;
		}
	}

	.viewUnixTimeStamp {
		display: none;
	}

	.monthDisplayCalendar {
		min-width: 80px;
		img {
			width: 15px;
			height: 15px;
		}
		img,
		label {
			margin-left: 0px;
			margin-right: 5px;
			font-weight: 600;
			float: left;
		}
	}

	.noMediaMessageSection {
		display: block;
		width: 100%;
		.noMediaMessage {
			display: block;
			text-align: center;
			transform: translateY(50%);
			width: 100%;
			img,
			label {
				display: block;
				font-weight: bold;
			}
			img {
				margin-left: auto;
				margin-right: auto;
				width: 81px;
				height: 89px;
			}
			label {
				width: 100%;
				color: #818181;
				font-size: 11px;
				margin-top: 15px;
				font-weight: normal;
			}
		}
		.defaultActionBtn {
			margin-top: 24px;
			svg {
				margin-right: 5px;
			}
		}
	}

	.mediaInternalGridContainer {
		overflow-y: auto;
		display: block;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		min-height: 21vh;
		max-height: 100%;
		width: 100%;
		padding: 10px;
		position: relative;
		padding-bottom: 180px;
		@include addGreyScrollBar;
		&[attr='nomedias'] {
			max-height: 100% !important;
		}
		.singleMediaBlock:hover,
		.singleMediaBlock:active {
			.container {
				display: block !important;
			}
		}

		.singleMediaBlock:before {
			content: '';
			display: block;
			height: 0;
			width: 0;
			padding-bottom: calc(9 / 16 * 100%);
		}
		.singleMediaBlock[attr='catalog'][isthumbnail='no'] {
			.imgParentDiv {
				background-size: 50%;
				background-position: center;
			}
		}
		.singleMediaBlock {
			display: flex;
			width: 100%;
			height: 100%;
			//width: 212px;
			//height: 120px;
			margin-bottom: 45px;
			position: relative;
			&:hover {
				//cursor: pointer;
				.mediaCount {
					color: #fff !important;
				}
			}

			.container[attr='selectAll'] {
				display: inherit;
				.mediaContentRelativeContentTop.visible {
					display: none;
				}
			}
			.container[topContainer='showMoreButton'] {
				display: block;
			}

			.container {
				display: none;
			}
			> img {
				max-width: 100%; // change width and height to max width and height to keep aspect ratio
				max-height: 100%;
				display: flex;
				margin: 0 auto;
				vertical-align: middle;
			}
			> .imgParentDiv {
				width: 100%;
				height: calc(100% - 45px);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					object-fit: revert;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					min-height: 100%;
					min-width: 100%;
					transform: translate(-50%, -50%);
					&.catalog_thumbnail {
						object-fit: none;
					}
				}
			}

			.mediaDetailsSection {
				width: 100%;
				position: absolute;
				bottom: -2px;
				.mediaDetailType {
					display: flex;
					align-items: center;
					margin-top: 5px;
					color: #747474;
				}
				.typeIcon {
					display: flex;
					flex: wrap;
					height: 15px;
					svg,
					img {
						margin-right: 5px;
						width: 12px !important;
						height: 15px;
						fill: #747474 !important;
					}
				}
				p {
					white-space: normal;
					display: inline-block;
					font-size: $default-font-size;
				}
				p.gridTypeText {
					padding-right: 10px;
					font-size: 11px;
					text-transform: capitalize;
					color: #747474;
				}
				p.title {
					margin-top: 5px;
					text-align: left;
					left: 0px;
					font-weight: bold;
					width: 100%;
					word-spacing: inherit;
					word-break: break-all;
					letter-spacing: 0.2px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
					//-webkit-line-clamp: 2;
					-webkit-line-clamp: 1; //can be chnaged to 2
					line-height: 1.6rem;
					max-height: 38px;
				}
				p.timeStamp {
					text-align: left;
					right: 0px;
					font-weight: lighter;
					font-size: 11px;
					color: #747474;
				}
			}
			.mediaContentRelativeContentBottom {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0px;
				.duration {
					text-align: right;
					right: 15px;
					color: $header-bg-color;
					position: absolute;
					bottom: 60px;
					background-color: $default-label-color;
					padding: 2px 5px;
					font-size: 12px;
					border-radius: 4px;
				}

				.mediaCount {
					text-align: right;
					right: 15px;
					color: #000;
					position: absolute;
					top: 50%;
					padding: 2px 10px;
					font-size: 12px;
				}
				a {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0;
				}
			}
		}
	}

	.tableParent {
		max-height: calc(100% - 100px);
	}

	.medialListView {
		border-collapse: collapse;
		.desktopMoreButton {
			.moreButton {
				position: relative;
				width: 50%;
				text-align: left;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
		.monthDisplayCalendar {
			position: absolute;
			margin-top: -5px;
		}
		tbody {
			display: block;
			overflow-y: auto;
			@include addGreyScrollBar;
		}
		tbody#listBodyContainer {
			min-height: 200px;
			max-height: calc(100vh - 100px);
			position: relative;
			padding-bottom: 180px;
			td {
				cursor: pointer;
			}
			td.listviewcheckbox {
				text-align: center;
				align-items: center;
				.round {
					position: relative;
					top: 0px;
					left: 0px;
					svg {
						position: relative;
					}
				}
			}
		}
		tbody.listHeader {
			width: 99.5%;
			font-weight: 600;
			font-size: 14px;
			padding-left: 10px;
			border-bottom: 1px solid#EAEAF2 !important;
		}
		tbody tr {
			width: 100%;
		}
		tbody#listBodyContainer tr:hover {
			background-color: #eaeaf2;
		}
		thead tr,
		tbody tr {
			display: table;
			table-layout: fixed;
			text-align: left;
		}
		tr[attr='catalog'] .imgParentDiv {
			background-size: 70%;
			background-position: center;
		}
		.duration {
			position: absolute;
			bottom: 20px;
			font-size: 10px;
			color: #fff;
			right: 5px;
			background-color: $default-label-color;
			padding-right: 5px;
			padding-left: 5px;
			border-radius: 4px;
		}

		tr.listHeader {
			height: 40px;
			border-top: 0px !important;
			padding-left: 10px;
			letter-spacing: 0.5px;
			td:first-child {
				padding-left: 10px;
				img {
					width: 10px;
					height: 10px;
				}
			}
		}
		tr {
			text-align: left;
			height: 75px;
			position: relative;
			padding-left: 10px;
			.round {
				position: relative;
				top: -10px;
				svg {
					fill: #000;
				}
				svg.checkbox_fill {
					fill: #126cf1;
				}
			}
			&:not(:first-child) {
				border-top: 1px solid#EAEAF2 !important;
			}

			td:nth-child(1) {
				width: 50px;
			}
			td:nth-child(2) {
				width: 75px;
				position: relative;
			}
			td:nth-child(4) {
				width: 30px;
			}
			td:nth-child(5) {
				width: 30px;
			}
			td:nth-child(6) {
				width: 60px;
			}
			td:nth-child(7) {
				width: 21%;
			}
			td:nth-child(8) {
				width: 150px;
			}
			td:nth-child(9) {
				width: 150px;
			}
			td.titleLabel {
				label {
					padding-left: 10px;
					max-width: 90%;
					@include textEllipsis;
					width: inherit;
					cursor: pointer;
					float: left;
				}
				img {
					float: left;
					margin-top: 5px;
				}
			}
			td.mobileViewMenu {
				display: none;
				width: 75px;
				height: 100%;
				position: relative;
				text-align: center;
				float: right;
			}
			.mobileViewListingMenuContainer {
				position: absolute;
				top: 40%;
				right: 20%;
			}
			td.viewsColumn {
				display: none; //not showing view column for now
			}
			.titleLabel:hover {
				.listViewSettingContainer {
					display: inherit;
				}
			}
			td {
				@include textEllipsis;
				label {
					@include textEllipsis;
				}
				.mediaCount {
					font-size: 10px;
					position: absolute;
					bottom: 18px;
					right: 15px;
					color: #000;
				}
				.trashIconContainer {
					margin-left: 10px;
					> button {
						border: none;
						background: none;
						width: 15px;
						height: 15px;
					}
				}
				//.listViewSettingContainer { display: none }
				.listViewSettingContainer[topContainer='showMoreButton'] {
					display: block;
				}
				.listViewSettingContainer {
					button.moreButton {
						float: left;
					}
				}
				button.moreButton {
					border: 0;
					background-color: transparent;
					//float: left;
					cursor: pointer;
					img {
						width: 20px;
						border: 0;
					}
				}

				.imgParentDiv {
					width: 100%;
					height: 100%;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					img {
						position: absolute;
						left: 0;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						&.catalog_thumbnail {
							height: 50%;
						}
					}
				}

				> img {
					width: 70px;
					height: 50px;
				}

				img.headerArrow {
					height: 15px;
				}

				img.playicon {
					width: 15px;
					height: 15px;
				}

				> label {
					@include textEllipsis;
					color: $body-table-font-color;
					font-size: $default-font-size + 2;
				}
			}
			.shortcutIcons {
				width: 30px;
				.tooltiptext {
					position: absolute;
					margin-left: -34px;
					max-width: 120px;
					display: block;
					height: 22px;
					font-size: 10px;
					color: white;
					background-color: #000000;
					opacity: 0.8;
					padding: 3px 10px;
					@include textEllipsis;
					opacity: 0;
					z-index: 1;
				}
				svg {
					width: 20px !important;
					height: 16px !important;
					fill: #3b3b3b;
					cursor: pointer;
				}
			}
		}
	}
}

.menuItemsBtn {
	width: 16px;
	height: 16px;
	background: transparent;
	border: none;
	flex: 1;
	cursor: pointer;

	> svg {
		fill: #000;
		align-items: center;
		align-content: center;
		width: 100%;
		height: 100%;
		float: left;
	}
	.uploadMenuIcon,
	.catalogMenuIcon,
	.liveEventMenuIcon {
		width: 15px;
		height: 15px;
		float: left;
	}
	label {
		@include textEllipsis;
		margin-left: 15px;
		max-width: 100px;
		float: left;
	}
}

.deleteItemBtn {
	width: 130px;
	height: 35px;
	border-radius: 40px !important;
	box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
	background-color: $default-alert-btn-bg-color !important;
	color: $header-bg-color !important;
	font-size: $default-font-size !important;
	letter-spacing: 1px !important;
}

.goBackBtnExtended {
	width: auto !important;
}

.dialogActionsWrapper {
	padding-bottom: 0;
	width: 100%;
	.dialogBoxInternalBlock {
		align-items: center !important;
		margin-bottom: 32px;
	}
}

.dialogBoxInternalBlock {
	width: 100%;
	//height: 75px;
	align-items: center;

	> div {
		max-height: 200px;
		overflow-y: auto;
		width: 100%;

		label {
			width: 100%;
			display: block;
			font-size: 12px;
			font-weight: bold;
			color: #000;
			letter-spacing: 1px;
		}
	}
}

.dialogTitleContentWrapper {
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
	padding-top: 0;
	text-align: center;
	margin: 0 24px;
	margin-top: 24px;
}

.dialogContentWrapper {
	padding: 12px 24px;
	margin: 0;
}

.modalBoxHeader {
	font-size: 15px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
}

.visible {
	visibility: visible !important;
}

.round {
	position: absolute;
	top: 5px;
	left: 6px;
	z-index: 1000;
	.tooltiptext {
		position: relative;
		width: 80px;
		max-width: 120px;
		display: block;
		font-size: 10px;
		color: white;
		background-color: #000000;
		opacity: 0.8;
		border: none;
		text-align: center;
		padding: 5px;
		transform: translateY(-3px) translateX(-5px);
		opacity: 0;
	}
}

.hideCheckbox {
	display: none;
}

.round:hover {
	.tooltiptext {
		opacity: 100;
	}
}

.gridViewAddMediaButtons .shortcutIcons {
	position: absolute;
	top: 3px;
	z-index: 1000;
	left: 36px;
	.tooltiptext {
		left: -34px;
	}
	svg {
		width: 20px !important;
		height: 15px !important;
		fill: white;
		cursor: pointer;
	}
}

.gridViewAddMediaButtons,
.medialListView {
	.shortcutIcons > *:not(.tooltiptext):hover {
		+ .tooltiptext {
			display: block;
			opacity: 100 !important;
		}
	}
}

.gridViewAddMediaButtons div:nth-of-type(2) {
	left: 68px;
}

.gridViewAddMediaButtons {
	div:nth-of-type(3) {
		left: 100px;
	}
	div:nth-of-type(4) {
		left: 132px;
	}
}

.round input[type='checkbox'] {
	visibility: hidden;
}

.round input[type='checkbox']:checked + label {
	background-color: $checkboxes-highlighted-color;
	border-color: $checkboxes-highlighted-color;
}

.round input[type='checkbox']:checked + label:after {
	opacity: 1;
}

.containerCheckbox {
	position: relative;
	top: -150px;
	left: 5px;
}

.checkboxInvisible {
	top: -148px;
	z-index: 20000;
	position: relative;
	visibility: hidden;
}

.checkboxVisible {
	z-index: 1000;
	display: block !important;
}

.mediaInternalListContianer {
	.playicon {
		width: 30px !important;
		height: 30px !important;
	}

	.listViewSettingContainer {
		position: relative;
		width: 55%;

		> button {
			border: none;
			background: none;
			width: 15px;
			height: 15px;
			margin-right: 15px;
			cursor: pointer;
			position: absolute;
		}
	}

	.trashIconContainer {
		margin-right: 2%;
		> button {
			border: none;
			background: none;
			width: 15px;
			height: 15px;
			margin-top: 10px;

			> img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.sortingDropDownWrapper {
	min-width: 40px;
	height: 37px;
	overflow: hidden;
	border: none;
	display: flex;
	padding: 5px 0;
	margin-right: 0;
	align-items: center;
	border-radius: 5px;
	border: 1px solid $table-borders-color;
	cursor: pointer;

	ul {
		margin-block-start: 0.5em;
	}

	.sortingDropDownInternalWrapper {
		width: 100%;
		display: block;
	}

	.sortingDropDown {
		width: 100%;
		height: 20px;
		border-radius: 5px;
		border: none;
		background-color: $header-bg-color;
		cursor: pointer;
		padding-left: 15px;
		color: $primary-filtering-icons-color;
		margin-left: 2px;
		&:focus {
			outline: none;
		}
	}

	button {
		cursor: pointer;
		//margin-top:5px;
		padding: 10px;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: 5px;
		margin-right: 5px;
		position: relative;
		z-index: 1000;
		max-width: 200px;
		height: 37px;
		background: transparent;
		border: none;
		text-align: left;
		@include textEllipsis;
		@include alignTextImageCenter;
		color: #6f7775;
		label,
		svg {
			float: left;
			cursor: pointer;
		}
		label {
			max-width: 100px;
			color: #000000;
			font-weight: bold;
			@include textEllipsis;
		}
		svg {
			margin-left: 8px;
			width: 100%;
			height: 100%;
			width: 12px;
			height: 12px;
			fill: $primary-filtering-icons-color;
			margin-top: 2px;
		}
	}
}

.catalogSwitchWrapper:hover,
.sortingDropDownWrapper:hover {
	.tooltiptext {
		opacity: 100;
	}
}
#sortByTypeSortingDropdown {
	border: none;
	&:hover {
		box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		border-radius: 5px;
		// height: 34px;
	}
}
.filterMediasOption,
.catalogSwitchWrapper {
	border: none;
	&:hover {
		box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		border-radius: 5px;
		// height: 34px;
	}
}
.catalogSwitchWrapper {
	margin-left: 5px;
}

#showByTypeSortingDropdown {
	border: none;
	&:hover {
		box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		border-radius: 5px;
		// height: 34px;
	}
}
.dialogTitleNewMediaContentWrapper {
	padding-bottom: 0;
	padding-top: 10px;

	> h3 {
		text-align: left;
		float: left;
	}

	> a {
		color: $customer-primary !important;
		font-size: 12px;
		text-decoration: none;
		cursor: pointer;

		> svg {
			width: 15px;
			margin-right: 10px;
			cursor: pointer;
			fill: $customer-primary;
		}
	}
}

.container.dialogContentWrapper {
	border: 1px solid $media-content-border-color;
	border-radius: 4px;
	background: #f2f5f7;
}

.mediaUploadContentArea {
	min-height: 95px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 0;

	> button {
		border: 1px solid $btn-primary-bg-color;
		background: transparent;
		width: 125px;
		color: $btn-primary-bg-color;
		font-size: 12px;
		border-radius: 20px;
		padding: 4px 15px;
		letter-spacing: 1px;
		cursor: pointer;
		margin-bottom: 24px;

		&:focus {
			outline: none;
		}
	}

	&:focus {
		outline: none;
	}

	&__message {
		font-size: 12px;
		color: #909ca5;
	}
}

.dialogContentWrapper {
	> label {
		color: $default-label-color;
		letter-spacing: 1px;
		font-size: $default-font-size;
		font-weight: bold !important;
	}
}

.modalBoxBottomContainer {
	align-items: center;
	justify-content: space-between;
	display: flex;
	margin-top: 5px;
}

.dialogBtnBottomContainer {
	align-items: center !important;
	padding-top: 20px;

	> button {
		width: auto;
		min-width: 150px;
		max-width: 200px;
		display: block;
		span {
			max-width: 180px;
			@include textEllipsis;
		}
	}
}

.selectedItemsContentWrapper {
	display: flex;
	flex-direction: column;
	min-height: 40px;
	max-height: 150px;
	overflow-y: auto;
	border-top: 1px solid $media-content-border-color;
	padding-left: 10px;
	padding-right: 10px;

	.eachSelectedItemWrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		line-height: 25px;
		border-bottom: 1px solid #ededed;
		height: 40px;
		align-items: center;

		&-label {
			display: flex;
			align-items: center;
			flex: 1;
		}

		&:last-child {
			border-bottom: none;
		}

		.fileNameUploaded {
			margin-left: 10px;
			width: 57%;
			text-align: left;
			flex: 1;
		}
		.fileSizeUploaded {
			width: 30%;
			color: #888888;
		}

		> button {
			width: 16px;
			height: 16px;
			border: none;
			background: transparent;

			> svg {
				width: 100%;
			}
		}
	}
}

/* Customized material dialog box height */
.MuiDialog-paperScrollPaper-190 {
	flex: 0 1 auto;
	max-height: 100% !important;
}
/* Customized material dialog box height */

.selectedItemsContentWrapper::-webkit-scrollbar {
	width: 5px;
}
.selectedItemsContentWrapper::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px $gray-links-color;
	border-radius: 5px;
}
.selectedItemsContentWrapper::-webkit-scrollbar-thumb {
	background: $default-label-text-color;
	border-radius: 5px;
}
.selectedItemsContentWrapper::-webkit-scrollbar-thumb:hover {
	background: $default-label-text-color;
}

.mediaContentBorderBottomOff {
	border-bottom: none;
}

.hightlightedLink {
	color: $btn-primary-bg-color !important;
	cursor: pointer;
	font-size: 12px;
	margin-left: 2px;
	&:hover {
		text-decoration: underline !important;
	}
}
.newUploadBtn {
	box-shadow: none !important;
	background-color: $header-bg-color !important;
	color: $btn-primary-bg-color !important;
	border: 1px solid $btn-primary-bg-color !important;
}

.mediaContentRelativeContentTop {
	display: table;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	height: calc(100% - 45px);
	text-align: center;
	font-size: 12px;
	background: rgba(8, 0, 0, 0.5);
	visibility: hidden;
	top: -1px;
	left: 0px;
	position: absolute;
	padding-top: 0px;
	color: #fff !important;
	> label {
		display: table-cell;
		color: #fff;
		vertical-align: middle;
	}

	> button {
		margin-right: 0;
		font-size: 25px;
		font-weight: bold;
		text-align: right;
		padding-right: 15px;
		background: transparent;
		border: none;
		cursor: pointer;
		z-index: 1000;
		width: 35px;
		position: absolute;
		height: 20px;
		top: 4px;
		right: 0px;
		> label {
			display: table-cell;
			color: #fff;
		}

		> svg {
			fill: $header-bg-color;
			vertical-align: middle;
			display: table-cell;
			width: 20px;
			height: inherit;
		}

		&:focus {
			outline: none;
		}
	}
}
.dropbtn:hover,
.dropbtn:focus {
	background-color: $customer-primary;
}
.dropdown {
	position: relative;
	display: inline-block;
}
.dropdown-content {
	display: none;
	position: absolute;
	background-color: $body-bg-color-after-login;
	min-width: 110px;
	overflow: auto;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 20000;
	left: -90px;
	top: 0px;
	right: 0px;
	height: auto;
	border-radius: 5px;

	> button {
		background: transparent;
		border: 0;
		border-bottom: 1px solid lightgrey;
		padding: 10px;
		font-size: 12px;
		cursor: pointer;
		width: 100%;

		&:focus {
			outline: none;
		}
	}
	> button:hover {
		background-color: white;
	}

	> button:last-child {
		border: 0;
	}
}
.dropdown-content a {
	color: $default-label-color;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}
.dropdown a:hover {
	background-color: $customer-primary;
}
.show {
	display: block;
}
#encodingProfilesDropdownWrapper {
	svg:not(.MuiChip-deleteIcon) {
		position: relative;
		left: 425px;
		top: -35px;
		fill: $default-label-text-color;
	}
}
#liveEventCreationBtn {
	width: 30px;
	height: 30px;
	margin-left: -5px;
	margin-top: -5px;

	+ label {
		margin-left: 5px;
		margin-top: 2px;
	}
}
#menuSeparatorDiv {
	border-left: 2px solid $thin-faded-border-color;
	margin-right: 0px;
	width: 1px;
	height: 50%;
	margin-left: 15px;
}
//#mediaFilterOptionsForMobile { display: none }

.boxed {
	position: relative;
	svg {
		margin-left: 0px !important;
		fill-rule: evenodd;
	}
	svg.checkbox_fill {
		fill: #126cfc;
		fill-rule: evenodd;
	}
}

.round {
	cursor: pointer;
	svg {
		fill: #fff;
		fill-rule: evenodd;
		position: absolute;
		top: 1px;
	}
	svg.checkbox_fill {
		fill: #126cfc;
		fill-rule: evenodd;
	}
	.tooltiptext {
		position: absolute;
		top: 25px;
	}
}
.boxed label {
	background-color: $header-bg-color;
	border: 2px solid $default-label-color;
	border-radius: 5px;
	cursor: pointer;
	height: 20px;
	left: 0;
	position: absolute;
	top: 0;
	width: 20px;
}
.boxed label:after {
	border: 2px solid $header-bg-color;
	border-top: none;
	border-right: none;
	content: '';
	height: 6px;
	left: 2px;
	opacity: 0;
	position: absolute;
	top: 4px;
	opacity: 0;
	position: absolute;
	transform: rotate(-45deg);
	width: 12px;
}
.boxed input[type='checkbox'] {
	visibility: hidden;
}
.boxed input[type='checkbox']:checked + label {
	background-color: $checkboxes-highlighted-color;
	border-color: $input-placeholder-text;
}
.boxed input[type='checkbox']:checked + label:after {
	opacity: 1;
}
#uploadsMenuSystem {
	z-index: 5000000000;
	li {
		cursor: pointer;
	}
	svg,
	img {
		width: 15px;
		height: 15px;
		margin-right: 10px;
		fill: #000000;
	}
}
.sortingDropDownWrapperMobile {
	border: none;
	min-width: 65px;
	width: 100px !important;
	margin-right: 0 !important;

	button {
		max-width: none !important;

		svg {
			&:first-child {
				width: 20px;
				height: 20px;
			}
			&:nth-child(2) {
				width: 12px;
				height: 12px;
				margin-top: 5px;
			}
		}
	}
}
#actionsMenuIconMobile {
	width: 15px;
	height: 15px;
}
.dialogContentWrapper {
	.dropzone {
		&:focus {
			outline: none;
		}
	}
}

.singleMediaBlockSkeletonWrapper {
	flex: 1 0 18%;
	display: flex;
	flex-direction: column;
}
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media only screen and (max-width: 1024px) {
	.mediaContentWrapper {
		#mediaFilterOptions {
			.mobileToolBlock {
				display: flex;
			}
		}
		.filtersSection {
			.actionsSection {
				svg {
					fill: #000000;
				}
				&:first-child {
					margin-right: 20px;
				}
			}
			.catalogSwitchChange.highlightCatalogContainer {
				border: none;
				background: none;
				svg {
					border: none;
					fill: #000000;
				}
			}

			.catalogSwitchChange {
				width: auto !important;
				cursor: pointer;
				justify-content: center;
				margin-left: 0px !important;
				margin-right: 0px;
				> svg {
					width: 20px;
					height: 20px;
					padding: 0px;
					fill: #6c7473;
				}
			}
			.extraActions {
				label {
					display: none;
				}
			}
			.sortingDropDownWrapper {
				.sortingDropDownInternalWrapper {
					display: flex;
					#sortingMenuButton2 {
						width: 30px;
						border-bottom: 0px;
						svg {
							width: 20px;
							height: 20px;
							fill: #0f1019;
							margin-left: 0px;
							margin-top: 0px;
						}
					}
				}
				button {
					padding: 0px;
				}
			}
			.uploadsSection {
				button {
					padding-bottom: 6px !important;
				}
			}
		}
	}

	.advanced-search-row-1,
	.advanced-search-row-2 {
		grid-template-columns: repeat(2, 1fr) !important;
		> *:last-child {
			border-bottom: none !important;
		}
	}
}

#caetelogTypeMenuSystem {
	li:focus {
		background-color: #f2f5f7 !important;
	}
}

#uploadsMenuSystem {
	li:focus {
		background-color: #126cfc !important;
		color: #fff;
		svg {
			color: #fff;
		}
	}

	&:hover {
		cursor: pointer;
	}
}

.mediaAddBtnSection {
	a {
		padding: 5px;
		&:hover {
			box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
			-webkit-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
			-moz-box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
			border-radius: 5px;
			//height: 37px;
			color: #0053d8;
			svg {
				.cls-1 {
					fill: #0053d8;
				}
			}
		}
		svg {
			width: 20px;
			margin-right: 10px;
			position: relative;
		}
	}
}

@media only screen and (max-width: 425px) {
	.sortingDropDownWrapper {
		min-width: 50px !important;
		margin-right: 18px;
		button {
			margin-left: 0px;
			margin-right: 0px;
			svg {
				&:first-child {
					margin-top: 0px;
					margin-left: 0px;
				}
			}
		}
	}

	.advanced-search-row-1,
	.advanced-search-row-2 {
		grid-template-columns: repeat(1, 1fr) !important;
		> *:last-child {
			border-bottom: none !important;
		}
	}
}

.search-filter-btn {
	border: none;
	border-radius: 4px;
	display: flex;
	cursor: pointer;
	position: relative;
	img {
		height: 100%;
		width: 100%;
	}
	&:hover {
		box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
		border-radius: 4px;
		.tooltiptext {
			display: block;
		}
	}
}

#searchInputContainer {
	display: flex;
	align-items: center;
	margin-right: 10px;
}
.dialogContentWrapper {
	&_title {
		display: flex;
		align-items: center;
		margin-bottom: 5px;

		svg:not(:last-child) {
			margin-right: 5px;
		}

		&--clickable,
		&--clickable * {
			cursor: pointer;
		}
	}
}
.playicon {
	width: 20px;
	height: 20px;
	margin-left: 10px;
}

.user-email-search-box {
	//overight mui textfield styling
	height: 35px;
	border-radius: 5px;
	border: 1px solid #e5e9ec !important;
	width: 100%;
	margin-top: 5px;
	padding-left: 10px !important;
	padding-right: 10px !important;
	align-items: center;
	justify-content: center;
}
