#interactTopMiddlePlanel {
  width: 100%;
  height: calc(100% - 40px);
  .LivePlayerWrapper {
    width: 100%;
    height: 100%;
  }
  #interactContainer,
  #interactPlayerContainer {
    width: 100%;
    height: 100%;
    position: relative;
    #interactPlayer-od {
    //  width: 100% !important;
    //  height: 100% !important;
    }
  }
}

.interactOverlayDropWrapper {
  align-items: flex-start;
}
