#screen-recorder-dialog {
	z-index: 6001 !important; // higher than adobe express modal

	& .MuiDialog-paperScrollPaper {
		max-height: 100% !important;
	}

	.dialog-title {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px 11px 0 24px;
		border-bottom: 1px solid #e5e9ec;
	}
	.preview-display-container {
		padding: 16px 24px 8px 24px !important;
		overflow: hidden !important;

		& .video-elements {
			position: relative;

			& #preview {
				background-color: #000000;
				border-radius: 5px;

				&.flipped {
					transform: rotateY(180deg);
					-webkit-transform: rotateY(180deg); /* Safari and Chrome */
					-moz-transform: rotateY(180deg); /* Firefox */
				}
			}

			& .user-webcam-container {
				position: absolute;
				left: 20px;
				bottom: 20px;
				overflow: hidden;
				border-radius: 15px;

				& #user-webcam {
					position: absolute;
					background-color: #000000;
					border-radius: 4px;
					left: -30%;

					transform: rotateY(180deg);
					-webkit-transform: rotateY(180deg); /* Safari and Chrome */
					-moz-transform: rotateY(180deg); /* Firefox */
				}
			}
		}
	}

	.config-options-container {
		background-color: #f7f8fa;
		border-top: 1px solid #e5e9ec;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px 24px 8px 24px !important;
		overflow: hidden !important;

		& .wrapper {
			width: 334px; // from design
		}

		& .record-modes-toggle {
			background-color: #e5e9ec;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 7px 7px 7px 7px;

			& .record-mode-btn-group {
				background-color: #ffffff;
			}
		}

		& .sources-selector {
			padding-top: 15px;

			& .source-selector {
				padding-top: 5px;

				& .select-options {
					background-color: #ffffff;
					z-index: 6002; // higer than recoder modal

					&
						.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
						padding-top: 0px;
						padding-bottom: 0px;
					}

					& .selected-option {
						display: flex;
						justify-content: start;
						align-items: center;
						gap: 10px;
						padding-top: 10px;
						padding-bottom: 10px;
						text-transform: capitalize;
					}
				}

				& .button-switch-screen {
					justify-content: start;
					background-color: #ffffff;
					padding: 6px 20px;

					& .switch-icon {
						width: 16px;
					}
				}
			}
		}
	}

	.actions-cta {
		background-color: #f7f8fa;
		padding: 15px 24px 15px 24px !important;
		justify-content: center !important;

		& .recording-cta-container {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e5e9ec;
			padding: 10px;
			border-radius: 4px;
		}
	}
}

.screen-recorder-floating-container {
	position: absolute;
	bottom: 40px;
	right: 30px;
	height: auto !important;
	background-color: #e5e9ec;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	z-index: 6001; // higher than adobe express modal

	& .drag-handler {
		min-width: 25px;
	}
}
