@import "../../../../global";
#detailsSection {  
    ::placeholder {
        color: grey;
        font-family: $body-font;
        opacity: 1; /* Firefox */
      }
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    hr {
        color: $dropdowns-background-color;
        opacity: 0.5;
    }

    input[type='text'],
    input[type='number'] { padding: 5px } 

    .editNameSection {
        margin-top: 10px;  
    }
    textarea {
        padding: 10px;
        display: block;
        width: 100%;
        resize: none;
        border-radius: 5px;
        margin-top: 10px;
        border-color: lightgray;
    }
    .customPropertyComponent {
        textarea {
            border: 1px solid lightgrey;
        }
        input[type='number'] {
            margin-top: 10px;
            padding: 10px;
            border: 1px solid lightgrey;
            border-radius: 4px;
        }
    }

    .customPropertyComponent[attr=customerror] {
        textarea,
        input[type='number'],
        .datetime,
        .date  {
            border: 1px solid $default-alert-btn-bg-color;
        }
    }
    .customPropertyComponent.sortingDropDownWrapper[attr=customerror]{
        border: 1px solid $default-alert-btn-bg-color;
    }


    .tagsSection {
        margin-top:15px !important;
        margin-bottom:20px;
        .tagsInput { display:block }
    }

    .mediaPreviewImageCotnainer {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        > img {
            max-width: 552px;
            height: 100%;
        }
    }    

    .customPropertyComponent { margin-bottom: 15px }

    .bottomActionButtons.warningActions {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
    .bottomActionButtons {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 10px;
        button {
            cursor: pointer;
        }
    
        button:nth-child(2) {
            margin-left: 2%;
           
        }
    } 

    .customPropertyTitle + .sortingDropDownWrapper  {
        margin-top:10px;
        margin-left: 0px;
    }

    .descriptionSection,
    .tagsSection {
        margin-top: 10px;
    }
   
}
