@import "../../../global";

#InteractPreviewDialog {
    div[role="dialog"] {
        background-color: transparent;
    }
    > div {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
    .interactPreviewContent{
        padding: 0px;
        .interactPreviewPlayer-od{
            margin: 0 auto;
        }
    }
    .dialogTitleContentWrapper {
        padding-top:12px;
    }
    //.headercloseBtn {
     //   right: 2em;
     //   svg {
     //       fill: #ffffff !important;
      //  }
    //}
    .dialogActionsWrapper {
        text-align: center;
    }
    div[role=document] div {
        background-color: transparent !important;
        box-shadow:none !important;
    }
}