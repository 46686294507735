@import './../../../global.scss';

.react-flow__node {
	width: 250px;
	height: 180px;
	border-radius: 4px;
	border: 3px solid transparent;
	&.selectable:hover {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
	.scene__label {
		display: none;
		opacity: 0.2;
		font-size: 12px;
		text-align: center;
		padding: 10px;
	}
	&:not(.selectable) {
		cursor: default;
	}
	&.selected {
		border: 3px solid #126cfc;

		.scene__body:not(.scene__mini) + .scene__label {
			display: block;
		}
	}
	.scene__content {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		position: relative;
		&.no-thumbnail {
			background-size: auto;
			background-color: #e5e9ec;
		}
		&--processing {
			background-color: #000000;
			color: #ffffff;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.scene__body {
		position: relative;
		width: 100%;
		height: 100%;
		border: 1px solid #0c306c;
		border-radius: 4px;
		&.scene__home .scene-btn__home {
			opacity: 1;
			background-color: #126cfc;
			&:hover {
				background-color: #0053d8;
			}
		}
		&:not(.scene__mini) .scene__content {
			height: calc(100% - 40px);
			top: 40px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}
}
.scenario-chart-graph__body--summary {
	.scene__content {
		height: 100% !important;
		top: 0px !important;
	}
}
.react-flow__handle {
	background: $btn-default-color-active !important;
	border-width: 0px !important;
}
.react-flow__handle-left {
	height: 16px !important;
	left: -11px !important;
	width: 11px !important;
	border-radius: 30% 0% 0% 30% !important;
}
.react-flow__handle-right {
	height: 16px !important;
	right: -11px !important;
	border-radius: 0% 30% 30% 0% !important;
	width: 11px !important;
	&::after {
		position: absolute;
		content: '';
		width: 4px;
		height: 4px;
		background: #fff;
		border-radius: 50%;
		top: 6px;
		right: 4px;
	}
}

.react-flow__edge {
	cursor: grab;
	&.selected {
		.react-flow__edge-path {
			cursor: grab;
			stroke-width: 2px;
		}
	}
}

.react-flow__edge-path {
	stroke-width: 1px;
	cursor: grab;
	&:hover {
		cursor: grab;
		stroke-width: 2px;
	}
}

.react-flow__controls-button img {
	max-height: 12px;
	max-width: 12px;
}

.scene__actions {
	display: flex;
	justify-content: space-between;
	background-color: #0c306c;
	padding: 5px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	.scene-btn {
		align-items: center;
		background-color: #133b7e;
		border-radius: 4px;
		border: none;
		color: #fff;
		cursor: pointer;
		display: flex;
		height: 32px;
		justify-content: center;
		width: 32px;
		img {
			width: 17px;
			height: 17px;
		}
		&:not(:last-child) {
			margin-right: 5px;
		}
		&.scene-btn__home {
			opacity: 0.54;
		}
		&:hover {
			opacity: 1;
			background-color: #11469e;
		}

		&.disabled {
			opacity: 0.54;
		}
	}
	&--right {
		display: inline-flex;
		justify-content: flex-end;
	}
}

.scene__mini {
	.scene__actions {
		padding: 0;
		background-color: transparent;
		transform: scale(1.5);
		top: -45px;
		left: 6px;
		right: unset;
	}
	.scene__analytics {
		transform: scale(1.5);
		bottom: -40px;
		right: 15px;
	}
}

.scene__analytics {
	position: absolute;
	bottom: 10px;
	right: 5px;
	background-color: #0c306c;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	font-size: 12px;
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
	}
	img {
		margin-right: 3px;
		width: 12px;
		height: 12px;
	}
}
