.schedule-live-event {
	&__field-fullwidth.MuiFormControlLabel-root {
		display: flex;
		justify-content: space-between;
		width: 100%;
		.MuiFormControlLabel-label {
			font-weight: 600;
		}
	}
	&__field.MuiFormControlLabel-root {
		.MuiInputAdornment-positionStart {
			position: absolute;
			right: 0px;
		}
		input[type='time'] {
			position: relative;
			&::-webkit-calendar-picker-indicator {
				opacity: 0;
				position: absolute;
				right: 0px;
				width: 18px;
				height: 18px;
				z-index: 999;
			}
		}
		margin: 12px 0;
		&:first-child {
			margin-top: 0;
		}
		input.MuiOutlinedInput-input,
		.MuiSelect-outlined.MuiSelect-outlined,
		div.MuiInputBase-multiline {
			padding: 12px;
			border: none;
		}
		// .MuiInputBase-root {
		// 	border: 1px solid #f2f5f7;
		// }
		textarea {
			padding: 0;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #e5e9ec;
		}

		.MuiInputBase-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
				.MuiTablePagination-input
			),
		.MuiFormControl-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
				.MuiTablePagination-input
			),
		.MuiSlider-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
				.MuiTablePagination-input
			),
		.scene-widgets__sort-input-control:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
				.MuiTablePagination-input
			) {
			flex-basis: 75% !important;
		}
	}
	&__field-label.MuiFormLabel-root {
		font-weight: 600;
		color: #000000;
	}

	&__divider {
		border-top: 1px solid #e5e9ec;
	}

	&__flex-container {
		flex-wrap: nowrap !important;
		margin-top: 12px;
		.MuiInput-underline:before,
		.MuiInput-underline:hover:not(.Mui-disabled):before,
		.MuiInput-underline:after {
			border: none;
		}
		.MuiFormControl-marginNormal {
			margin-top: 0;
		}
		.schedule-live-event__field.MuiFormControlLabel-root {
			margin: 0;
		}
		> *:not(:last-child) {
			margin-right: 12px;
			> div,
			label {
				color: #000;
				width: 100%;
				margin-bottom: 5px;
				font-size: 1rem;
				font-family: Poppins;
				font-weight: 400;
				line-height: 1.5;
				+ .MuiInput-formControl {
					margin-top: 25px;
					border: 1px solid #e5e9ec;
					border-radius: 4px;
					padding: 12px;
					&:hover {
						border-color: #000000;
					}
					input {
						padding: 0px;
					}
				}
			}
		}
		label {
			font-size: 12px !important;
			transform: translate(0, 1.5px) scale(1);
			color: #000000;
			align-items: flex-start;
			width: 100%;
			display: flex;
			span:not(.MuiIconButton-label) {
				margin-bottom: 5px;
			}
			.MuiInputBase-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
					.MuiTablePagination-input
				) {
				flex-basis: 100% !important;
				width: 100%;
				border: 1px solid #e5e9ec;
				border-radius: 4px;
				padding: 12px;
				&:hover {
					border-color: #000000;
				}
				input,
				.MuiSelect-root {
					padding: 0px;
				}
			}
		}
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: #f2f5f7;
	}
	&__tabs.MuiTabs-root {
		min-height: 0;
		position: relative;
		margin-top: 12px;
		&::before {
			position: absolute;
			content: ' ';
			width: 100%;
			height: 2px;
			background-color: #e5e9ec;
			bottom: 0;
		}
	}
	&__tab.MuiTab-root {
		min-width: 0;
		min-height: 0;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		padding: 10px;
		font-size: 12px;
		line-height: normal;
		border-bottom: 2px solid transparent;
		opacity: 1;
		&.schedule-live-event__tab--selected {
			border-color: #126cfc;
			background-color: #f7f8fa;
		}
	}
	&__dropzone {
		padding: 12px 0;
	}
	&__dropzone-content.mediaUploadContentArea {
		background-color: #126cfc1a;
		border: 1px dashed #126cfc;
		border-radius: 4px;
		svg {
			width: 34px;
			height: 34px;
		}
		> button {
			width: 34px;
			height: 34px;
			padding: 0;
			border: none;
		}
		.mediaUploadContentArea__message {
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 24px;
				height: 24px;
				margin-right: 5px;
			}
		}
	}
}
