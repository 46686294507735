.advancedSettings {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
}

.advancedSettings__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 24px;
    height: 46px;
    background-color: white;
    border-bottom: 1px solid #DFE7EB;;
}

.advancedSettings__content {
    flex: 1;
}

.advancedSettings__section {
    padding: 24px;
    &--title {
        font-weight: 600;
        margin-bottom: 12px;
    }
}