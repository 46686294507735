.scenario-chart-graph {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 86vh;
}
.scenario-chart-graph__actions {
	position: absolute;
	top: 0;
	right: 0;
	text-align: right;
	padding: 10px 24px;
	z-index: 1;

	&.summaryScenes {
		z-index: 5;
	}
	svg {
		fill: white;
		margin-right: 10px;
	}
	&.scenario-chart-graph__empty {
		bottom: 0;
		top: -100px;
		left: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.scenario-chart__message {
			margin-bottom: 12px;
			font-size: 12px;
		}
	}
}
.scenario-chart-graph__body {
	width: 100%;
	flex: 1;
	height: 100%;
	&--summary .react-flow {
		position: static !important;
		.scene__actions,
		.scene__label,
		.scene__analytics {
			display: none !important;
		}
		&__pane {
			cursor: grab;
		}
	}
}
.react-flow__controls-button {
	width: 32px !important;
	height: 32px !important;
}

.react-flow__attribution {
	display: none;
}
