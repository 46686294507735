.paginationContentWrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	& .MuiTablePagination-root {
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .MuiPagination-root {
		display: inline-block;
	}

	& .MuiToolbar-root {
		justify-content: center;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 480px) {
		& {
			display: flex;
		}

		& .MuiPagination-root {
			display: none;
		}

		& .paginationAction {
			.actionButton {
				padding: 0px 5px;
			}
		}

		& .MuiToolbar-gutters {
			padding-left: 0px;
		}

		& .MuiToolbar-regular {
			min-height: 0;
		}

		& .MuiTablePagination-selectRoot {
			margin-left: 0px;
			margin-right: 15px;
		}
	}
}
