@import "../../../global";
.LiveManagerInteractQueueWrapper {
    padding-left: 10px;
    padding-right: 10px;

    .refreshBtn {
        float: right;
        background-color: $live-interact-header !important;
        margin-right: 20px;
        margin-top: 10px;
        padding: 2px;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        svg {
            fill: #fff !important;
            width: 15px !important;
            height: 15px !important;
        }
        &:hover {
            background-color: $cancel-button-hover !important;;
        }
    }
    .interactQueueOption {
        display: inline-block;
        width: 100%;
        padding: 0px;
        li {
          clear: float;
          display: block;
          width: calc(50% - 10px);
          padding: 18px;
          text-align: left;
          list-style-type: none;
          background-color: $live-interact-header;
          cursor: pointer;
          float: left;
          border-radius: 4px;
          margin-left: 10px;
          height: 75px;
          @include textEllipsis;
          color: #fff;
          svg {
            cursor: inherit;
            fill: #ffffff !important;
            width: 16px !important;
            height: 15px !important;
            margin-right: 10px;
            margin-left: 5px;
            display: block;
            .a {
                fill:#fff;
                fill-rule:evenodd;
            }
          }
          svg,
          label {
            //to place avg and label inline
            cursor: inherit;
            vertical-align: middle;
            color: #ffffff;
          }
          &:hover {
            background-color: $cancel-button-hover !important;;
          }
        }
    }
    .queueHeading {
        display: inline-block;
        color: #ffffff;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .queueList {
        padding-left: 0px;
        margin: auto;
        width: 100%;
        .queueItem {
            cursor: pointer;
            list-style-type: none;
            background-color: $live-interact-element-list;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-right: 20px;
            margin-top: 5px;
            svg {
                cursor: pointer;
                fill: #dedede !important;
            }

            .interactElementQueueImageWrapper{
                width: 60px;
                height: 45px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                margin-right: 10px;
             }
            .queueItemTitle {
                //padding-left: 10%;
                cursor: pointer;
                font-size: 14px;
                color: #ffffff;
                width: 65%;
                max-width: 65%;
                @include textEllipsis;
            }

            .interactMore {
                cursor: pointer;
                width: 35px;
                height: 30px;
                background: url('../../../assets/images/interact_more.svg') no-repeat;
                margin-right: 20px;
                background-position: center;
                &:hover {
                   filter: brightness(80%);
                }
                margin-right: 20px;
            }
            .interactMore.disabled {
                opacity: 0.4;
                &:hover {
                    filter: none;
                }
            }

            .interactPlay,
            .interactStop {
                cursor: pointer;
                position: relative;

                width: 32px;
                height: 22px;
                margin-right: 20px;
                background: url('../../../assets/images/play_live_queue.svg') no-repeat;
                &:not(.interactPlay--disabled):hover {
                    background: url('../../../assets/images/play_live_queue_hover.svg') no-repeat;
                }
                &.interactPlay--disabled {
                    opacity: 0.3;
                    cursor: default;
                }
            }
            .interactStop {
                background: url('../../../assets/images/stop_live_queue.svg') no-repeat;
                opacity: 1;
                &:hover {
                    background: url('../../../assets/images/stop_live_queue_hover.svg') no-repeat;
                    opacity: 1;
                }
            }
            &:hover {
                background-color: $live-interact-element-hover;
            }
        }
        .queueItem.liveQueueItem {
            background-color: $btn-default-color-active !important;
            &:hover {
                background-color: $btn-default-color-hover !important;
            }
        }
    }
}

.LiveManagerInteractQueueWrapper[status=disabled] {
    .liveQueue,
    .interactQueue {
        opacity: 0.8;
        pointer-events: none;
    }
}