.inspect-media__detail-container {
	flex: 1;
	overflow-y: auto;

	.MuiBox-root {
		margin-left: 12px;
		margin-right: 12px;
		&:not(:last-of-type) {
			margin-bottom: 12px;
		}
		&:first-child {
			margin-top: 12px;
		}
	}

	.MuiOutlinedInput-input {
		padding: 12px !important;
	}
	.MuiInputBase-multiline {
		padding: 0 !important;
	}

	.inspect-media__input--full {
		margin: 12px 0;
		font-size: 12px;
		input {
			font-size: inherit;
		}
		&:first-child {
			margin-top: 0;
		}
	}

	.inspect-media__input--full .MuiFormControlLabel-label {
		padding: 0px 0 8px;
		font-weight: 600;
		width: 100%;
	}

	.player-preview__information-section {
		padding: 12px 14px;
		margin: 0 !important;
		border: none;
	}
}
