.edge-button {
    width: 40px;
    height: 40px;
    background: #eee;
    border: 1px solid #eee;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;

    &:hover {
      background: #d9d9dd;
    }
  }

  .connection-menu {

    .MuiListItem-button:hover {
      background-color: transparent;
    }

    .connection-item{
      pointer-events: none;
    }
  }