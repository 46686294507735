#playerCtrl{
    height: 48px !important;
    width: 100%;
    /* position: fixed; */
    
    position: absolute;
    bottom: 0;
    left:0;

    z-index:1000000;
    
    background-color: #1b1d2a;
    display: flex;
    align-items: center;
    


    .videoDuration{
        color: white;
        font-size: 14px;
        margin-right: 10px;
    }
}

#playBtn{
    width: 32px;
    height: 26px;
    border-radius: 2px;
    //padding: 20px;
    margin: 10px;
    background-color: #1b1d2a;
    border: 0;
    font-size: 29px;
    font-weight: bold;
    text-align: center;
    color: white;
    outline: none;
    background-image: url('../../../../assets/images/play.png');
    background-size: 20px;
    opacity: 1;
    border: 1px solid white;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

#toStartBtn{
    width: 28px;
    height: 28px;
    border-radius: 15px;
    margin-left: 10px;
    background-color: #1b1d2a;
    border: 0;
    font-size: 29px;
    font-weight: bold;
    text-align: center;
    color: white;
    outline: none;
    background-image: url('../../../../assets/images/previous.png');
    opacity: 1;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.playBtnPause{
    background-image: url('../../../../assets/images/pause.png') !important;
}

#progressBar{
    left: 100px;
    margin-right: 20px;
    display: block;
    height: 10px;
    width: calc(100vw - 780px);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
}

#progress-current{
    display: block;
    background-color: #148096;
    height: 6px;
    margin-top: 2px;
    z-index: 1000000000;
}
