.media-information-container {
	padding: 0px 14px 12px;

	&__section {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		> * {
			display: block;
			width: 100%;
		}

		&-title {
			font-weight: 600;
			margin-bottom: 8px;
		}
	}

	&__copy-btn {
		border-radius: 4px;
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #e5eaed;
		margin-left: 10px;
		width: 24px;
		height: 24px;
		img {
			border-radius: 4px;
		}
	}

	&--flat-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		padding: 12px 12px 0 12px;
		border-top: 1px solid rgba(229, 233, 236, 1);
		.media-information-container__section {
			margin-bottom: 12px;
		}
	}
}
