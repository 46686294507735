@import "../../global.scss";
#AssetLibrary {
    .assetWrapper {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, 220px);
        grid-gap: 1rem;
        justify-content: center !important;
    }
    .loadingMessage {
        padding-top: 20px;
        margin: 0 auto;
        display: table;
    }
    
    label {
        color: #ffffff;
    }
   
    .assetItemContainer{
        float: left;
        margin: 10px;
        width: 180px;
        @include textEllipsis;
        color: #ffffff;
    }
    .assetItem {
        position: relative;
        background-color: $live-interact-element-list;
        width: 180px;
        height: 70px;
        display: flex;
        vertical-align: middle;       
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        &:hover {
            //background-color: $live-interact-element-hover;
            .presetElements {
                opacity: 0.4;
            }
            //color: grey; 
            .add,
            .remove {
                opacity: 1;
                display: flex !important;
                cursor: pointer;
            }
        }
        .add {
            position: absolute;
            bottom: 3px;
            right: 3px;
            display: none;
            align-items:center;
            color: #ffffff;
            background-color: $customer-primary;
            padding: 6px;  
            border-radius: 3px;              
            img {
                margin-right: 2px;
            }
        }

        .remove {
            position: absolute;
            left: 0px;
            bottom: 0px;
            display: none;
            align-items: center;
            padding: 3px;
            img {
                width: 30px !important;
                height: 30px !important;
            }
        }
        .presetElements {  
            height: 35px;
            //background-color: red;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            label {
                max-width: 90%;
                @include textEllipsis;
                letter-spacing: 0.5px;
            }            
        }
    }
}