.scenario-chart-tab-bar {
    font-size: 12px;
    display: flex;
    padding: 0;
    margin-bottom: 0;
    margin: 0;
    &__item {
        list-style: none;
        border-bottom: 4px solid transparent;
        padding: 12px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        svg {
            margin-right: 8px;
        }
        &:hover {
            background-color: #f2f5f7;
        }
        &--selected {
            border-bottom: 4px solid #1D71F8;
            font-weight: 600;
        }
    }
}