.recording-cta-btn-group {
	width: 200px !important;
	background-color: #ffffff;

	& button {
		border: 1px solid #e5e9ec !important;
		color: #000000;
	}

	& .pause-button {
		& .resume-icon {
			color: #fd5e6b;
		}
	}

	& .duration {
		pointer-events: none;
		cursor: default;
		min-width: 65px !important;
	}

	& .ons-creen-icon-container {
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.stop-button {
	margin-left: 10px !important;
	background-color: #fd5e6b !important;
}
