.chat-container {
	&__message {
		border-radius: 0px 10px 10px 10px;
		background-color: #454853;
		padding: 12px;
		margin: 5px 5px 0px 5px;
		display: inline-block;
		&-container {
			display: flex;
			padding-right: 30px;
		}

		&-sender {
			font-weight: 600;
			margin-right: 5px;
		}

		&-time {
			font-size: 8px;
		}

		&-title {
			display: flex;
			align-items: center;
			div:first-child {
				flex: 1;
				margin-right: 5px;
				svg {
					margin-right: 5px;
				}
			}
		}

		&-more-btn {
			float: right;
		}

		&-content {
			margin-top: 5px;
		}

		&-quote {
			border-left: 2px solid #0053d8;
			padding: 2px 8px;
			margin-top: 5px;
		}

		&--admin {
			.chat-container__message {
				background-color: #126cfc;
			}
		}

		&--pinned {
			padding-right: 0px;
			height: 70px;
			.chat-container__message {
				background-color: #323641;
				width: 100%;
				border-radius: 0px;
				margin: 0;
				box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
			}
			.chat-container__message-content {
				color: #ffffff99;
				max-width: 300px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&--collapsed {
			.chat-container__message-content span {
				max-width: 300px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&__header {
		border-bottom: 1px solid #1a1c26 !important;
		min-height: 45px;
		padding: 0px 12px 0px 0px !important;
		justify-content: center;
		.MuiFormControlLabel-label {
			flex: 1 1 !important;
		}
	}
}
.MuiFormControl-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
		.MuiTablePagination-input
	).chat-container__header {
	flex-basis: auto !important;
}
