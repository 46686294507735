@import "../../../../global";
#interactElementProperties {
  padding-bottom: 20px;
  @include addGreyScrollBar;
  height: calc(100% - 34px);
  overflow-x: hidden;
  overflow-y: auto;

  input[type="text"],
  input[type="number"] {
    color: #000000;
    border-radius: 5px;
    &:not(.TimeInput_input) {
      border: 1px solid transparent;
    }
  }

  #hideVolumeCounterInput {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }

  input[type="text"] {
    color: $default-label-text-color;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
  input[type="text"].fullWidth {
    width: 100%;
  }

  input[type="text"].sixtyWidth {
    width: 60% !important;
  }

  .halfWidth {
    width: 49% !important;
  }
  .trimButtonsSet {
    display: flex;
    min-width: calc(100% - 20px) !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    align-items: center;
    svg {
      width: 15px !important;
      height: 15px !important;
      margin-right: 10px !important;
    }
  }
  .trimButtonsSet.trimCancelBtn {
    text-transform: capitalize !important;
    background-color: transparent !important;
    color: #fff !important;
    &:hover {
      font-weight: bold;
      background-color: #fff !important;
      color: #000 !important;
    }
  }

  select.currencySelectInput {
    background: transparent
      url("../../../../assets/images/down_arrow_white.svg") no-repeat;
    background-position: right 5px bottom 8px;
    background-size: 1em;
  }

  .priceInputField {
    width: 60%;
  }

  rect {
    // fill: inherit !important;
  }

  > .objTypeListName {
    display: block;
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px;
    padding-bottom: 0px;
  }
  > .objTypeListName ~ .objTypeListName {
    display: none;
  }

  .sortingDropDownWrapper {
    //border: 0;
    height: 24px;
    .sortingDropDown {
      border-radius: 2px;
      height: 22px;
    }
  }

  .interactPropertiesSection {
    position: relative;
    //border-bottom: 1px solid $text-inputs-border-color;
    padding-top: 5px;
    padding-bottom: 5px;
    .deleteButton {
      position: absolute;
      width: 12px;
      height: 10px;
      right: 30%;
      top: 5px;
      display: block;
    }
    .buttonProperties {
      margin-bottom: 20px;
    }
    .buttonProperties:last-child {
      margin-bottom: 0px;
    }
  }
  .booleanSelection {
    //border-bottom: 1px solid $text-inputs-border-color;
    padding-top: 0px;
    padding-bottom: 0px;
    > span {
      padding-left: 0px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .interactSelectionSection {
    border-top: 1px solid $text-inputs-border-color;
    padding-top: 10px;
    height: 40px;
    margin-top: 5px;
    label {
      color: #ffffff;
      float: left;
      width: 40%;
      margin-top: 3px;
    }
    .sortingDropDownWrapper {
      float: left;
      width: 60% !important;
      height: 24px;
      border-radius: 2px;
    }
  }

  .interactTime[attr="true"] {
    .TimeInput {
      border: 1px solid red !important;
    }
  }

  .interactTime {
    width: inherit;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    label {
      display: inline-block;
      max-width: 50%;
    }
  }

  .propertySectionTitle {
    color: white;
    font-weight: 600;
    margin-bottom: 10px !important;
  }

  label {
    color: white;
    display: block;
    width: 100%;
  }

  .inlineLabel {
    display: inline-block;
    width: auto;
    margin-right: 5px;
    color: white;
  }

  .fileChooseSelection {
    margin-bottom: 10px;
    .fileSelection {
      position: relative;
    }
    label {
      margin-bottom: 3px;
      font-size: 11px;
    }
    .chooseFileInput {
      width: 100% !important;
      padding: 5px;
      height: 50px;
      font-size: 11px;
      background-color: #373c4a;
      border-radius: 5px;
      border: 1px dashed #7487a7;
      position: relative;

      .uploadedImg {
        height: 40px;
        margin-right: 10px;
      }
      .uploadedTitle {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      .defaultFileInputWrapper {
        width: fit-content;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        .uploadSvg {
          width: 18px;
          height: 18px;
          margin-right: 2px;
          transform: translateY(4px);
          filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(345deg)
            brightness(107%) contrast(101%);
        }

        label {
          font-size: 13px;
          font-weight: 600;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      cursor: pointer;
      padding-right: 30px;
      &:disabled {
        background-color: #ffffff;
        border: 1px solid $text-inputs-border-color;
      }
    }

    .chooseFileInput:hover {
      background-color: #444b60 !important;
      border: none;
    }
    .loadingImage {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 8px;
      right: 6px;
      display: inline-block;
    }
    .closeButton {
      width: 15px;
      height: 15px;
      /*  border: 1px solid grey;
            background-color: grey; */
      border-radius: 50%;
      position: absolute;

      top: 15px;
      right: 6px;
      display: inline-block;
      cursor: pointer;
      svg {
        width: 13px !important;
        height: 11px !important;
        position: absolute;
        top: 3px;
        left: 2px;
        cursor: pointer;
      }
    }

    .chooseFileButton {
      color: $default-label-text-color;
      margin-left: -76px;
      border-radius: 2px;
      border: 1px solid $text-inputs-border-color;
      letter-spacing: 0.5px;
      padding: 5px;
      display: inline-block;
      font-size: 10px;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.16) !important;
        color: #000000;
      }
    }
  }

  .textFieldInputSection {
    margin-bottom: 5px;
  }

  .variantNameFieldInputSection {
    margin-bottom: 5px;
  }

  .descriptionFieldInputSection {
    margin-bottom: 5px;
  }

  .priceFieldInputSection {
    margin-bottom: 5px;
  }

  .colorPicker {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 0px !important;
    .colorPickerDiv {
      right: 30px;
    }
    .colorPickerSelection > div {
      padding: 0px;
    }
    .colorPickerContainer {
      width: 50px !important;
    }
    > div {
      float: right;
    }
    label {
      float: left;
    }
  }

  .positionAndSize {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    width: calc(50% - 5px);
    input[type="number"] {
      font-size: 11px;
      float: left;
      width: 100%;
      margin-right: 10px;
      padding: 6px;
      color: white !important;
      background-color: #373c4a;
      border: 1px solid #7487a7;
    }
    input:focus {
      border: 1px solid #7487a7;
    }
    .sectionNames {
      display: none;
    }
    label {
      font-size: 11px;
      float: left;
      width: 100%;
      @include textEllipsis;
      margin-bottom: 3px;
    }
  }

  .actionsCheckedRadioButton,
  .actionsUnCheckedRadioButton {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .actionsCheckedRadioButton {
    color: #126cfc;
  }
  .actionsUnCheckedRadioButton {
    color: grey;
  }
  .actionsList {
    padding-left: 0px !important;
    .actionsSection:first-child {
      padding-top: 0px;
    }
    .actionsSection {
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
      height: 60px;
      .actionTitle {
        width: 100%;
        position: relative;
        label {
          width: 90%;
          display: inline-block;
        }
        img {
          width: 20px;
          height: 12px;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 0px;
        }
      }
      &:hover {
        input:not(.TimeInput_input) {
          background-color: $thin-left-panel-bg-color;
          border: 1px solid $default-label-text-color;
        }
      }
      .linkArrow {
        img {
          width: 20px;
          height: 15px;
        }
        float: left;
        width: 8%;
      }
      .actionOptions {
        width: 92%;
        float: left;
      }
      .fadeOutWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 240px;
        height: 24px;
        align-items: center;
      }
      .playPauseOptions {
        span {
          padding-right: 2px;
        }
        label {
          margin-right: 15px;
        }
        label:last-child {
          margin-right: 0px;
        }
      }
      .openUrlAction {
        position: relative;
        height: 24px;
        input {
          display: inline-block;
          margin-right: 10px;
        }
        .openUrlActionOptions {
          display: inline-block;
          span {
            padding-right: 2px;
          }
          label {
            margin-right: 15px;
          }
          label:last-child {
            margin-right: 0px;
          }
        }
      }
    }
    .actionsSection:first-child {
      margin-top: 0px !important;
    }
  }
  .actionSelectionMenu {
    margin-bottom: 10px;
    .dropdownLabel {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    option {
      cursor: pointer;
      padding: 5px !important;
      color: #000000;
      opacity: 1;
      &:disabled {
        //background-color: $thin-left-panel-bg-color;
        color: lightgrey;
      }
    }
  }
  .actionText {
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: #000000;
    }
  }

  .dynamicActionField {
    cursor: pointer;
    border: 1px solid #7487a7;
    text-align: center;
    border-radius: 2px;
    margin: 5px;
    font-weight: 600;
    background-color: #323641;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    height: 30px;
    width: 60%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    .wrapper {
      @include alignTextImageCenter;
      display: inline-flex;
      text-align: center;
      height: 26px;
      img {
        width: 15px;
        height: 15px;
        margin-right: 15px;
      }
      .dynamicActionText {
        cursor: pointer;
        width: auto;
      }
    }
  }

  .interactItem {
    font-weight: 600;
    .deleteInteractItem {
      background-color: $thin-left-panel-bg-color;
    }
    .interactItemDetail {
      color: white;
      width: 90%;
      background-color: #323641;
    }
  }

  .toggle {
    position: relative;
    height: 36px;
    border-bottom: 1px solid #7487a7;
    .toggleButton {
      cursor: pointer;
      position: absolute;
      left: 10px;
      height: 12px;
      width: 12px;
      top: 12px;
      -webkit-transition: display 0.2s ease-out;
      transition: display 0.2s ease-out;
      background: url("./../../../../assets/images/notexpanded.svg") no-repeat !important ;
      filter: invert(100%) sepia(5%) saturate(18%) hue-rotate(141deg)
        brightness(103%) contrast(100%);
    }
    .propertySectionTitle {
      cursor: pointer;
      position: absolute;
      left: 35px;
      top: 10px;
    }
  }
  .toggle.active .toggleButton {
    height: 12px;
    top: 14px;
    background: url("./../../../../assets/images/expanded.svg") no-repeat !important;
  }

  .generateCombinationsButton {
    width: fit-content;
    border-radius: 2px !important;
    border: 2px solid transparent !important;
    padding: 4px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    // box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: none !important;
    background-color: $btn-default-color-active !important;
    color: $header-bg-color !important;
    font-size: $default-font-size !important;
    letter-spacing: 1px !important;
    text-transform: none !important;
    font-family: $body-font !important;

    //font-size: $X-large-size !important;
    &:hover {
      background-color: $btn-default-color-hover !important;
      color: $tooltip-text-color !important;
      box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      cursor: pointer;
    }
  }

  .generateCombinationsButtonDisabled {
    opacity: 0.4;
  }

  .editVariablesButton {
    width: fit-content;
    border-radius: 2px !important;
    border: 2px solid transparent !important;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    right: 2%;
    // box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: none !important;
    background-color: $btn-default-color-active !important;
    color: $header-bg-color !important;
    font-size: $default-font-size !important;
    letter-spacing: 1px !important;
    text-transform: none !important;
    font-family: $body-font !important;
    top: 50%;
    transform: translateY(-50%);

    //font-size: $X-large-size !important;
    &:hover {
      background-color: $btn-default-color-hover !important;
      color: $tooltip-text-color !important;
      box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      cursor: pointer;
    }
  }
  .generateCombinationsButtonWrapper {
    width: 100%;
    position: relative;
    margin-top: 5px;
    margin-bottom: 0px !important;
  }
  .toggle.active + .toggleDescription {
    display: block;
  }
  .toggle + .toggleDescription {
    display: none;
  }

  .toggleDescription {
    padding-left: 10px;
    padding-right: 10px;
    .interactPropertiesSection:last-child {
      //border-top: 1px solid #7487A7;
    }
    .positionAndSize:nth-child(odd) {
      margin-right: 5px;
    }

    .positionAndSize:nth-child(even) {
      margin-left: 5px;
    }
  }

  /* .descriptionProductImages {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 100px;

    label {
      width: 50% !important;
    }
  } */

  .fieldSettings {
    width: inherit;
    margin-bottom: 10px;
    background-color: #373c4a;
    padding-bottom: 5px;
  }

  .appearanceSection {
    > div {
      label {
        width: 40%;
        display: inline-block;
      }
      input {
        width: 60%;
        display: inline-block;
      }
    }
    .typographyEdit {
      float: right;
      width: 15% !important;
    }
    .toggleDescription {
      > div {
        margin-bottom: 5px;
      }
    }
    .textFieldInputSection {
      margin-top: 5px;
      margin-bottom: 0px !important;

      input,
      select,
      textarea {
        color: white !important;
        border: 1px solid #7487a7;
        background-color: #373c4a;
      }

      input:focus {
        border: 1px solid #7487a7;
      }
    }

    .variantInputsGrid {
      display: grid;
      grid-row-gap: 10px;
      grid-template-columns: 30% 70%;
      grid-column-gap: 10px;
    }

    .variantNameFieldInputSection {
      margin-top: 5px;
      margin-bottom: 0px !important;
      display: flex;
      flex-direction: column;

      label {
        width: 100%;
        margin-bottom: 5px;
      }

      input,
      select,
      textarea {
        color: white !important;
        border: 1px solid #7487a7;
        background-color: #373c4a;
        border-radius: 0%;
      }

      input:focus {
        border: 1px solid #7487a7;
      }
    }

    .descriptionFieldInputSection {
      margin-top: 5px;
      margin-bottom: 0px !important;

      label {
        transform: translateY(-55px);
      }

      input,
      select,
      textarea {
        width: 60% !important;
        color: white !important;
        border: 1px solid #7487a7;
        background-color: #373c4a;
        padding: 5px;
        height: 70px;
        border-radius: 5px;
      }

      input:focus {
        border: 1px solid #7487a7;
      }
    }

    .variantValuesFieldInputSection {
      margin-bottom: 0px !important;
      margin-top: 5px;

      /* .MuiFormControl-root-214 {
        border: 1px solid #7487a7;
        background-color: #373c4a;
        border-radius: 5px;
      } */

      /*   .MuiChip-root-319 {
        color: white !important;
        border-radius: 0% !important;
        background-color: #7365e2 !important;
        height: 20px !important;
      }

      .MuiChip-label-338 {
        padding: 0% !important;
      } */
      .variantValuesFieldInputSectionFirstRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        svg {
          margin-right: 3%;
          margin-bottom: 4px;
          cursor: pointer;
        }
        label {
          margin-bottom: 4px;
        }
      }

      input[type="text"] {
        color: white !important;
      }
    }

    .tableSize6 {
      grid-template-columns:
        minmax(40px, 1fr)
        minmax(80px, 1.67fr)
        minmax(80px, 1.67fr)
        minmax(150px, 1.67fr)
        minmax(150px, 1.67fr)
        minmax(150px, 1.67fr);
    }

    .tableSize5 {
      grid-template-columns:
        minmax(40px, 1fr)
        minmax(100px, 1.67fr)
        minmax(150px, 1.67fr)
        minmax(150px, 1.67fr)
        minmax(150px, 1.67fr);
    }

    .variantCombinationsSummaryMenu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      span {
        font-size: 12px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
    .variantCombinationsVariablesMenu {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      span {
        font-size: 12px;
        letter-spacing: 0px;
        color: #ffffff;
      }

      .variantCombinationsVariablesMenuButtons {
        display: flex;
        flex-direction: row;

        .variantCombinationsVariablesMenuButtonsItem {
          font-size: 12px;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 10px;
          margin-right: 10px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .variantCombinationsDropdown {
      display: inline-block;
      position: relative;
      width: 60px;
    }

    .variantCombinationsDropdown-button {
      display: inline-block;
      border-bottom: 0.5px solid #ffffff;
      cursor: pointer;
      white-space: nowrap;
      padding-right: 15px;
    }

    .variantCombinationsDropdown-button:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid white;
    }

    .variantCombinationsDropdown-button:hover {
      background-color: #2f3443;
    }

    .variantCombinationsDropdown-input {
      display: none;
    }

    .variantCombinationsDropdown-menu {
      position: absolute;
      top: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0;
      margin: 2px 0 0 0;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      background-color: #373c4a;
      list-style-type: none;
      transform: translateX(-60px);
      z-index: 2;
    }

    .dropzoneVariant {
      cursor: pointer;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .variantCombinationsImageResult {
      height: 30px;
      width: auto;
      left: 50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .variantCombinationsDropdown-input + .variantCombinationsDropdown-menu {
      display: none;
    }

    .variantCombinationsDropdown-input:checked
      + .variantCombinationsDropdown-menu {
      display: block;
    }

    .variantCombinationsDropdown-menu li {
      padding: 10px 20px;
      cursor: pointer;
      white-space: nowrap;
    }

    .variantCombinationsDropdown-menu li:hover {
      background-color: #2f3443;
    }

    .variantCombinationsSection {
      margin-top: 5px;
      margin-bottom: 0px !important;
      background-color: #2f3443;
      display: grid;
      border-collapse: collapse;
      width: 100%;
      overflow-x: scroll;

      thead,
      tbody,
      tr {
        display: contents;
      }

      th {
        /*  position: sticky; */
        top: 0;

        text-align: left;
        font-weight: normal;
        font-size: 14px;
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;

        span {
          span {
            input {
              left: 7px;
            }
          }
        }
      }

      .variantComboCheckboxHeader {
        padding: 0px;
        padding-left: 5px;
      }

      .variantCombinationsItemRow {
        td {
          background-color: #373c4a !important;
          position: relative;
          margin-bottom: 5px;
          height: 40px;

          span {
            span {
              input {
                left: 7px;
              }
            }
          }
        }
      }
      .variantCombinationsImage {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .variantCombinationsImageButton {
          cursor: pointer;
        }
      }

      .variantComboCheckboxItem {
        padding: 0px;
        padding-left: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .MuiPrivateSwitchBase-input-175 {
        left: 50%;
        transform: translateX(-38%);
      }
      .variantCombinationsInput {
        color: white !important;
        border: 1px solid #7487a7;
        background-color: #373c4a;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        width: 85% !important;
        height: 80%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .variantCombinationsName {
        color: white;
        font-weight: 600;
        padding: 5px;
        @include textEllipsis;
        font-size: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .variantCombinationsItemWrapper {
        border-radius: 3px;
        color: white;
        font-weight: 600;
        padding: 5px;
        padding-left: 10px;
        margin-top: 7px;

        .variantCombinationFirstRowWrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    .variantCombinationsSection::-webkit-scrollbar {
      height: 5px;
    }
    .variantCombinationsSection::-webkit-scrollbar-thumb {
      background: #6c7473;
      border-radius: 10px;
    }

    .variantCombinationsSection::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px darkgrey;
      border-radius: 10px;
    }

    .priceFieldInputSection {
      margin-top: 5px;
      margin-bottom: 0px !important;
      display: flex;
      flex-direction: row;

      input,
      select,
      textarea {
        color: white !important;
        border: 1px solid #7487a7;
        background-color: #373c4a;
        padding-left: 5px;
      }

      input:focus {
        border: 1px solid #7487a7;
      }

      .currencySelectInput {
        width: 38%;
        height: 28px;
        border-radius: 5px;
        padding: 5px;
        margin-left: 2%;
      }
      .priceLabel {
        transform: translateY(5px);
      }
    }
  }

  .objectNameField {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;

    input,
    select,
    textarea {
      color: white !important;
    }

    input:focus {
      border: 1px solid #7487a7;
    }

    .buttonNameValue {
      width: 60%;
      color: white;
      border: 1px solid #7487a7 !important;
      background-color: #373c4a;
    }
    .buttonNameLabel {
      display: inline-block;
      width: 40%;
      color: white;
    }
  }

  .objectTitle {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #7487a7;
  }

  .fontTypography {
    padding-top: 10px;
    padding-bottom: 10px;
    .typographyEdit {
      border: 1px solid #7487a7;
      text-align: center;
      img {
        margin: 0 auto;
        width: 15px;
        height: 15px;
      }
      cursor: pointer;
      svg {
        width: 12px !important;
        height: 12px !important;
        display: block;
        margin: 0 auto;
        margin-top: 5px;
        margin-bottom: 5px;
        filter: invert(100%) sepia(5%) saturate(18%) hue-rotate(141deg)
          brightness(103%) contrast(100%);
      }
      .pen rect {
        fill: inherit;
      }
    }

    .typographyContent {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 10px;
      padding-bottom: 5px;
      padding-top: 8px;
      border: 1px solid #7487a7;
      .singleTypographyContent.fontAdjuster {
        display: block;
        height: 60px;
      }
      .singleTypographyContent {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        .sortingDropDownWrapper {
          float: right;
          width: 60% !important;
          border-radius: 2px;
        }
        input[type="number"] {
          position: absolute;
          top: 2px;
          right: 0px;
          width: 17% !important;
          text-align: center;
        }
        .colorPickerSelection {
          margin-left: auto;
        }
        .fontSizeAdjuster {
          width: 100%;
          display: block;
          position: relative;
          .fontSlider {
            position: absolute;
            top: 10px;
            width: 100%;
          }
          input[type="range"] {
            float: left;
            width: calc(100% - 100px) !important;
          }
          input[type="number"] {
            text-align: center;
            padding-left: 5px;
            width: 55px !important;
            right: 30px;
          }
          .unitOfMeasure {
            position: absolute;
            right: 0px;
            top: 2px;
            height: 20px;
            width: 24px;
            text-align: center;
            display: table;
            background-color: $thin-left-panel-bg-color;
            label {
              font-size: 13px;
              display: table-cell;
              vertical-align: middle;
              color: #000000;
            }
          }

          .slidecontainer {
            width: 100%;
          }
          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 2px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: 0.2s;
            transition: opacity 0.2s;
          }
          .slider:hover {
            opacity: 1;
          }
          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #000000;
            cursor: pointer;
          }
          .slider::-moz-range-thumb {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #000000;
            cursor: pointer;
          }
        }
      }
    }
  }
  .InteractMediaControlsSection {
    padding-top: 10px;
    .InteractMediaControls {
      float: right;
      .rotate {
        animation: rotation 8s infinite linear;
      }

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
    }
  }
}
