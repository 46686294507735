@import "../../global";

#interactEditorScreen.liveMedia {
  .gobrain-controlbar {
    background-color: transparent !important;
    .gobrain-title,
    .gobrain-progress-wrapper,
    .gobrain-controller-settings,
    .gobrain-icon-timeshift,
    .gobrain-controller-live {
      display: none !important;
    }
  }
}
#interactEditorScreen {
  width: 100%; //calc(100% - 5px);
  display: block;
  background-color: $movable-grid-color;
  height: calc(100% - 50px);
  &[trimtool=true] {
    #interactElements,
    #interactElementsListTitleHeader,
    .interactItemElements:not([attr=trimtool]),
    .vis-item:not(.video) {
      opacity: 0.4;
      pointer-events: none;
    }
    .vis-item.video {
      opacity: 1;
      pointer-events: auto;
      background-color: transparent !important;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 3px;
      border-color: #fff;
      //top: 5px !important;
     .vis-item-overflow {
        position: relative;
        width: 100%;
        height: 90%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        border-top: 2.5px solid white;
        top: 5%;
        border-bottom: 2.5px solid white;
      }
    }
    .vis-item.video:before {
        width: 100%;
        position: absolute;
        top: -10px;
        display: block;
        height: calc(100% + 18px);
        content: " ";
        border-left: 2.5px solid #fff;
        left: 0px;       
    }
    .vis-item.video:after {
        width: 100%;
        position: absolute;
        top: -10px;
        display: block;
        height: calc(100% + 18px);
        content: " ";
        border-right: 2.5px solid #fff;
        right: 0px;
    }
  }
  .openInNewWindowIcon {
    display: none !important; //adding temporarily for demo as feature is incomplete
  }

  .interactTopRightWrapper {
    height: 99%;
  }

  #interactElementsListTitleHeader {
    @include alignTextImageCenter;
    background-color: #ffffff;
    padding: 0px;
    height: 25px;

    background-color: #2a2e38;
    svg {
      width: 25px !important;
      height: 9px !important;
      fill: #ffffff !important;
    }
    label:last-child {
      font-weight: 600;
    }
    .interactELementListTitle {
      text-decoration: underline;
      cursor: pointer;

      color: #ffffff;
    }

    .interactELementListTitle:last-child {
      margin-right: 0px;
      text-decoration: none !important;
    }
    .openInNewWindowIcon {
      float: right;
      svg {
        height: 25px !important;
      }
    }
  }
  .interact-semibold-headers {
    font-weight: 600;
  }

  .saveAsPresetButton {
    font-size: 12px !important;
    font-weight: 600;
    letter-spacing: 0.2px !important;
    min-width: 100px !important;
    height: 40px;
    color: white;
  }

  .settingsBottomButtons {
    display: flex;
    justify-content: center;
  }

  .interactMainButtons {
    height: auto;
    width: 100%;
    display: table;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    background-color: #373c4a;
    .previewButton,
    .saveProjectButton {
      margin-right: 20px;
      float: right;
      font-size: 12px !important;
      font-weight: 600;
      letter-spacing: 0.2px !important;
      min-width: 100px !important;
      height: 40px;
      color: white;
      svg {
        width: 12px !important;
        height: 12px !important;
        margin-right: 10px;
        margin-bottom: 1px;
      }
    }
  }
}
