@import "../../../global";

#StopLiveEventConfirmationDialog{
    label{
        text-align: left;
        display: block;
    } 
    .snapShotReplaceInfo {
        font-weight: 600;
        font-size: 14px;
    }
   
    .odSnapShotsList {
        border: 1px solid #D3D3D3;
        padding: 0px;
        margin-bottom: 30px;
        max-width: 100%;
        .odSnapShot {
            font-size: 13px;
            list-style-type: none;
            text-align: left;
            color: #000000 !important;
            cursor: pointer;
            padding: 5px;
            padding-left: 20px;
            border-bottom: 1px solid #D3D3D3;
            @include textEllipsis;
            &:hover{
                background-color: $thin-left-panel-bg-color !important;
            }
        }
        .odSnapShot.selected {
           // color: #ffffff;
            background-color: #b7e4ff !important;  
            border: 1px solid #0091EA;         
            &:hover {
               // background-color: #2ebedc;
            }
        }
        .odSnapShot:last-child{
            border: 0;
        }
    }
}