@import '../../global.scss';

#mediaLibrarySection {
	button {
		font-size: 12px;
	}
	.mediaAddBtnSection {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		img {
			width: 20px;
			margin-right: 10px;
			position: relative;
		}
		a {
			cursor: pointer;
			@include alignTextImageCenter;
			svg {
				fill: $customer-primary;
			}
		}
	}
}

#MiniMediaLibrary {
	.dialogActionsWrapper {
		text-align: center;
		padding: 12px 24px 24px;
		border-top: 1px solid #e5e9ec;
		margin-left: 0 !important;
	}
	.showMoreButton {
		text-align: center;
		color: palevioletred;
		padding: 10px;
		cursor: pointer;
		label {
			cursor: pointer;
		}
	}
	.tabsSection {
		display: flex;
		padding-bottom: 0px;
		overflow-y: initial;
		position: relative;
		align-items: flex-end;
		margin-bottom: 12px;
		button:first-child {
			margin-left: 24px;
		}
		.viewTab {
			height: 35px;
			display: flex;
			background-color: inherit;
			float: left;
			border: none;
			outline: none;
			cursor: pointer;
			padding: 14px 0px 5px 0px;
			letter-spacing: 0.5px;
			margin-right: 20px;
			margin-left: 24px;
			&[active='true'] {
				border-bottom: 3px solid $customer-primary;
				font-weight: bold;
			}
		}
		.miniLibSearchField {
			position: absolute;
			right: 24px;
			width: 33%;
			color: #000;
			font-size: $default-font-size;
			margin-right: 24px;
			&::placeholder {
				color: #000;
			}
		}
	}
	.dialogContent {
		max-height: 50vh;
		min-height: 50vh;
		> *:first-child {
			margin-left: 24px;
		}
		> span {
			color: #a09c9c;
			font-size: 12px;
		}
	}
	.uploadContainer,
	.messageContainer,
	.mediaInternalGridContainer {
		height: inherit;
		display: block;
	}
	.defaultMessage {
		height: 40vh;
		line-height: 40vh;
		text-align: center;
		span {
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
		}
	}

	.mediaInternalGridContainer {
		height: calc(100% - 50px);
		display: grid;
		grid-template-columns: repeat(5, auto);
		grid-gap: 1rem;
		padding-top: 15px;
		padding-left: 24px;
		padding-right: 24px;
		.mediaContentWrapper {
			width: 212px;
			height: 120px;
		}
	}

	.viewUnixTimeStamp {
		visibility: hidden;
	}

	.singleMediaBlock {
		position: relative;
		border: 3px solid transparent;
		&.selected {
			border: 3px solid $customer-primary;
		}

		&:hover {
			box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.08);
			.mediaCount {
				color: #fff !important;
			}
		}

		.container[attr='selectAll'] {
			display: inherit;
			.mediaContentRelativeContentTop.visible {
				display: none;
			}
		}
		.container[topContainer='showMoreButton'] {
			display: block;
		}

		.container {
			display: none;
		}
		> img {
			max-width: 100%; // change width and height to max width and height to keep aspect ratio
			max-height: 87px;
			display: flex;
			margin: 0 auto;
			vertical-align: middle;
		}
		> .imgParentDiv {
			width: 100%;
			height: 100%;
			img {
				width: 50%;
				height: 62%;
				margin-top: 10%;
				margin-left: 24%;
			}
		}

		.mediaDetailsSection {
			.mediaDetailType {
				display: flex;
				align-items: center;
				margin-top: 5px;
				color: #747474;
			}
			.typeIcon {
				display: inline-block;
				svg,
				img {
					margin-right: 5px;
					width: 12px !important;
					height: 15px;
					fill: #747474 !important;
				}
			}
			p {
				white-space: normal;
				display: inline-block;
				color: $body-table-font-color;
				font-size: $default-font-size;
			}
			p.gridTypeText {
				padding-right: 10px;
				text-transform: capitalize;
				font-size: 11px;
				color: #747474;
			}
			p.title {
				margin-top: 5px;
				text-align: left;
				left: 0px;
				font-weight: bold;
				width: 100%;
				word-spacing: inherit;
				word-break: break-all;

				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal;
				-webkit-line-clamp: 2;
				line-height: 1.6rem;
				max-height: 38px;
			}
			p.timeStamp {
				text-align: left;
				right: 0px;
				font-weight: lighter;
				font-size: 11px;
				color: #747474;
			}
		}
		.mediaContentRelativeContent {
			position: absolute;
			background-color: transparent;
			width: 100%;
			align-items: flex-start;
			flex-direction: column;
			cursor: pointer;
		}
		.mediaContentRelativeContentBottom {
			bottom: 5px;
			padding-bottom: 5px;
			position: absolute;
			width: 100%;
			.duration {
				background-color: $default-label-color;
				bottom: 5px;
				color: $header-bg-color;
				font-size: 12px;
				padding: 2px 5px;
				position: absolute;
				right: 5px;
			}

			.mediaCount {
				text-align: right;
				right: 15px;
				color: #000;
				position: absolute;
				bottom: 8px;
				padding: 2px 10px;
				font-size: 12px;
			}
		}
	}
	.MuiDialogActions-root {
		flex-direction: column;
	}
}
