
.TimeInput {
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    display: inline-flex;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid transparent;
    flex: 0 1 auto;
    &.TimeInput--disabled, &.TimeInput--readonly {
        color: rgba(0, 0, 0, 0.38);
    }
    &:not(.TimeInput--disabled):not(.TimeInput--readonly):focus,
    &:not(.TimeInput--disabled):not(.TimeInput--readonly):hover {
        background-color: #f4f4f4;
        box-shadow: 5px black;
        border: 1px solid #818181;
    }
    input {
        background-color: inherit;
        border: none;
        font-size: 12px;
        outline: none;
        padding: 5px;
        max-width: 32px;
        color: inherit;
    }
    span {
        font-size: 12px;
        color: inherit;
        line-height: 1;
    }
}
