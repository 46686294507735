.AppViewerWrapper {
    height: inherit;
    .fullScreenMode {
        width: 100%;
        height: inherit !important; //takes from screenparentcontainer
        border: none;
    }

    .normaScreenMode {
        width: 100%;
        //margin-left: 3%;
        //height: calc(100vh - 81px);
        height: inherit;
        border: none;
        iframe {
            height: inherit !important;
        }
    }  

}