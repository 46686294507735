@import "../../../../global";
.interactItemList {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  height: 100%;
  .interactElementInfo {
    height: calc(100% - 95px);
    display: flex;
    align-items: center;
    label {
      margin: 0 auto;
      color: white;
    }
  }
}
#interactItemList {
  .assetLibraryButton {
    display: block;
    width: calc(50% - 10px);
    padding: 20px;
    text-align: left;
    list-style-type: none;
    background-color: #2a2e38;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 0px;
    margin-bottom: 10px;
    @include textEllipsis;
    svg {
      cursor: inherit;
      fill: #ffffff !important;
      width: 24px !important;
      height: 24px !important;
      margin-right: 10px;
      margin-left: 5px;
      display: block;
    }
    svg,
    label {
      //to place avg and label inline
      cursor: inherit;
      vertical-align: middle;
      color: #ffffff;
    }
    &:hover {
      background-color: #1c1c1c !important;
    }
  }
  

  .itemList {
    height: calc(100% - 100px);
    overflow-y: auto;
    @include addWhiteScrollBar;
  }

  .interactItemElements[viewonly=disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
  .interactItemElements {
    margin: 5px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    cursor: pointer;
    @include textEllipsis;
    padding-top: 5px;
    background-color: #373c4a;
    padding-bottom: 5px;
    .deleteInteractItemElements {
      right: 2%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 25px;
      width: 25px;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 25px !important;
        height: 25px !important;
      }
    }

    .deleteInteractItemElements:hover {
      border: 1px solid #fff;
      right: calc(2% + 1px);
      top: calc(50% - 1px);
    }

    .interactItemDetailPenIcon {
      right: 12%;
      position: absolute;
      top: 50%;

      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 25px;
      width: 25px;
      border-radius: 2px;
      cursor: pointer;
      /*  svg {
        margin: 2px;
        cursor: pointer;
        width: 21px !important;
        height: 21px !important;
      } */
    }

    .interactItemDetailElements {
      display: flex;
      flex-direction: column;

      cursor: pointer;
      padding: 5px;
      padding-left: 10px;

      .interactItemDetailTitle {
        color: white;
        @include textEllipsis;
        font-size: 18px;
      }

      .interactItemDetailTypeTime {
        color: white;
        font-weight: 400;
        @include textEllipsis;
        font-size: 10px;
      }
    }
  }

  .interactItemElements:hover {
    background-color: #444b60;
    box-shadow: 3px 3px 10px #00000029;
  }
  .interactItem {
    margin: 5px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    cursor: pointer;
    @include textEllipsis;
    padding-top: 5px;
    padding-bottom: 5px;
    .deleteInteractItem {
      background-color: #ffffff;
      right: 0px;
      position: absolute;
      top: 8px;
      border-radius: 2px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 20px !important;
        height: 20px !important;
      }
    }
    .interactItemDetail {
      border-radius: 3px;
      color: white;
      border: 1px solid #7487a7;
      font-weight: 600;
      background-color: #323641;
      cursor: pointer;
      width: 90% !important;
      @include textEllipsis;
      font-size: 12px;
      padding: 5px;
      padding-left: 10px;
    }
  }
}
