.medias-display-wrapper.medias-display-wrapper--mini .grid-media-display {
	border-radius: 4px;
}

.grid-media-display {
	overflow: auto;
	min-height: 21vh;
	max-height: 100%;
	padding: 24px;
	display: grid;
	grid-gap: 1.5rem;
	grid-auto-rows: 1fr;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	background-color: #f7f8fa;

	&--empty {
		grid-template-columns: minmax(0, 0.96fr) 4fr !important;
	}
}

body[screen-type='medium'] {
	.grid-media-display:not(.dashboard__media-grid) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

body[screen-type='tablet'],
body[screen-type='small'] {
	.grid-media-display {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
body[screen-type='small'] {
	.grid-media-display--empty {
		grid-template-columns: none !important;
	}
}

body[screen-type='mobile'] {
	.grid-media-display {
		grid-template-columns: none !important;
	}
}

.media-container {
	margin-bottom: 5px;

	&[data-type='catalog'] {
		.media-container__main img {
			object-fit: none;
		}
	}
	&__title {
		display: block;
		font-size: 12px;
		margin-top: 5px;
		line-height: 17px;
		font-weight: 600;
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__detail {
		color: #868a8d;
		display: flex;
		justify-content: space-between;
		font-size: 10px;
		margin-top: 5px;
		line-height: 18px;
		* > * {
			color: inherit;
		}
	}

	&__type {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		svg {
			margin-right: 5px;
			width: 15px;
		}
	}

	&__main {
		position: relative;
		border-radius: 4px;
		padding-bottom: 56.25%;
		width: 100%;
		height: calc(100% - 45px);

		&--processing,
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 4px;
		}
		&--processing {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #000000;
			color: #ffffff;
			font-size: 16px;
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}

		img {
			display: block;
			object-fit: cover;
			background: #e5e9ec;
			&.media-container__main-no-thumbnail {
				object-fit: none;
			}
		}
		&:hover .media-container__toolbar {
			visibility: visible;
		}

		&-shortcut {
			background-color: #e5e9ec;
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 4px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #e5e9ec;
			cursor: pointer;
			&:hover {
				background-color: #cdd4d9;
			}
			&-flex-container {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: inherit;
				> * {
					font-size: 12px;
					color: #909ca5;
					margin: 4px;
					cursor: inherit;
				}
			}
		}
	}

	&__toolbar {
		visibility: hidden;
		cursor: pointer;
		position: absolute;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		font-size: 12px;
		color: #ffffff;
		border-radius: 4px;

		&--tools {
			display: flex;
		}

		&--bigBtn {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			color: inherit;
		}
	}

	&__duration {
		position: absolute;
		font-size: 10px;
		color: #ffffff;
		background: #000000;
		padding: 0px 5px;
		bottom: 5px;
		right: 5px;
		line-height: 20px;
		border-radius: 4px;
	}

	&__adobe-express {
		position: absolute !important;
		bottom: 5px;
		left: 5px;
		background-color: #ffffff !important;
		border-radius: 4px !important;
		z-index: 1;
	}
}
