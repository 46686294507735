.media-library-modal-wrapper {
	display: flex;
	flex-direction: row;

	& .structure-navigator {
		flex: 1 0 10px;
		min-width: 50px;
		display: flex;
		flex-direction: column;

		& .encoding-profiles {
			flex: 1 0;
			margin-top: 5px;
			& .form-control {
				width: 100%;

				& .title {
					align-items: center;
				}

				& .select {
				}
			}
		}

		& .catalog-tree {
			&.video {
				flex: 9 0 390px;
			}

			&.image {
				flex: 9 0 439px;
			}

			margin-top: 10px;
			overflow-y: scroll;
		}
	}

	& .content-wrapper {
		flex: 4 0 10px;
		display: flex;
		flex-direction: column;
		background-color: #f7f8fa;

		& .media-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 1rem;
			height: 380px;
			margin-top: 10px;
			margin-left: 10px;
			overflow-y: scroll;

			& .media-item {
				display: flex;
				flex-direction: column;
				width: 230px;

				& .item-image {
					position: relative;
					width: 100%;
					height: 150px;
					cursor: pointer;
					.processing {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #000000;
						color: #ffffff;
						font-size: 12px;
						width: 100%;
						height: 100%;
						border-radius: 4px;
					}

					& .overlay {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						border-radius: 4px;

						&.selected {
							background-color: #00000072;
							border: 2px solid #126cfc;
						}

						&:hover {
							background-color: #00000072;

							&.disabled {
								background-color: transparent;
								cursor: default;
							}
						}
					}

					& img {
						object-fit: cover;
						width: 100%;
						height: 100%;
						border-radius: 4px;

						&.no-thumbnail {
							object-fit: none;
							background-color: #e5e9ec;
						}
					}

					& .item-duration {
						position: absolute;
						bottom: 10px;
						right: 10px;
						color: white;
						font-size: 10px;
						background-color: #0000007e;
						padding: 1px 5px;
						border-radius: 2px;
					}

					& .artist-information {
						position: absolute;
						bottom: 10px;
						left: 10px;
						color: white;
						font-size: 10px;
						background-color: #0000007e;
						padding: 1px 5px;
						border-radius: 2px;
						visibility: hidden;
					}
				}

				& .item-info {
					display: flex;
					flex-direction: column;
					padding: 5px 0;

					.type-and-elapsed-time {
						display: flex;
						justify-content: space-between;
						color: #868a8d;
						font-size: 10px;
					}

					& .item-title {
						padding-top: 5px;
						font-size: 12px;
						font-weight: 600;
						overflow: hidden;
					}
				}

				&:hover {
					& .artist-information {
						visibility: visible;
					}
				}
			}
		}

		& .empty-medias {
			width: 100%;
			height: 380px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
		}

		& .container {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& .selectedItemsContentWrapper {
			width: 100%;
			border-top: 0;

			& .eachSelectedItemWrapper {
				position: relative;
				> button {
					width: auto;
					height: auto;
					display: flex;
				}

				.fileNameUploaded {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.fileSizeUploaded {
					margin-left: 5px;
				}

				& .headercloseBtn {
					right: 0;
				}
			}
		}
	}
}
