button,
svg {
	&:focus {
		outline: none;
	}
}
.defaultActionBtn.neutralBtn {
	background-color: #ffffff !important;
	color: #383838 !important;
	border: 2px solid #383838 !important;
	&:hover {
		color: #1c1c1c !important;
		background-color: #ffffff !important;
		border-color: #1c1c1c !important;
	}
}

.defaultActionBtn.deleteUserBtn,
.defaultActionBtn.deleteUserBtn:hover {
	background-color: $default-alert-btn-bg-color !important;
}

.defaultActionBtn.deleteUserBtn {
	&:hover {
		background-color: $default-alert-btn-bg-hover-color !important;
	}
}

.defaultActionBtn {
	min-width: 70px !important;
	max-width: 250px;
	height: 36px;
	border-radius: 2px !important;
	border: 2px solid transparent !important;
	padding-top: 4px !important;
	// box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
	box-shadow: none !important;
	background-color: $btn-default-color-active !important;
	color: $header-bg-color !important;
	font-size: $default-font-size !important;
	letter-spacing: 1px !important;
	text-transform: none !important;
	font-family: $body-font !important;
	&.defaultActionBtn--error {
		background-color: $default-alert-btn-bg-color !important;
		&:hover {
			background-color: $default-alert-btn-bg-hover-color !important;
		}
	}
	//font-size: $X-large-size !important;
	&:hover {
		background-color: $btn-default-color-hover !important;
		color: $tooltip-text-color !important;
		box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
		cursor: pointer;
	}
	&:disabled {
		opacity: 0.4;
		box-shadow: none !important;
	}
	&:focus {
		outline: none;
	}
	img {
		margin-right: 10px;
	}
}

.deleteButtonWithIcon,
.replaceButtonWithIcon {
	border: 1px solid transparent !important;
	background-color: transparent !important;
	color: $default-alert-btn-bg-color !important;
	font-weight: 500 !important;
	box-shadow: none !important;
	border: 2px solid transparent !important;
	&:hover {
		background-color: $default-alert-btn-bg-hover-color !important;
		color: $header-bg-color !important;
		font-weight: normal;
		svg {
			fill: $header-bg-color;
		}
	}
	svg {
		width: 15px;
		height: 15px;
		fill: $default-alert-btn-bg-color;
		margin-right: 10px;
	}
}

button.newUserBtn {
	svg {
		margin-right: 5px;
		width: 13px !important;
		height: 13px !important;
	}
}
