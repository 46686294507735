#calendar-media-display__wrapper {
	font-size: 12px;
	width: 100%;
	* {
		font-size: 12px;
	}
	.calendar-media-display__event {
		background-color: #126cfc;
		border: none;
		border-radius: 4px;
		font-size: 12px;
		.rbc-event-content {
			line-height: normal;
		}
		&.rbc-event.rbc-selected,
		&.rbc-day-slot .rbc-selected.rbc-background-event {
			background-color: #0053d8;
		}
		&:hover {
			background-color: #0053d8;
		}
	}
	.rbc-agenda-table {
		.calendar-media-display__event {
			background-color: transparent;
			&:hover {
				background-color: transparent;
			}
		}
	}

	.rbc-header span[role='columnheader'],
	.rbc-toolbar-label {
		font-weight: 600;
		font-size: 12px;
	}

	.rbc-toolbar {
		margin: 0px 24px 12px 24px;
		button {
			border: 1px solid rgba(0, 0, 0, 0.12);
			color: rgba(0, 0, 0, 0.87);
			font-size: 12px !important;
			letter-spacing: 0px !important;
			height: 32px;
		}
	}
}
