@import './global';

@media only screen and (max-width: 319px) {
	.medialListView {
		overflow-x: auto;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
	#mediaFilterOptions {
		.listingIconsSection {
			display: none;
		}
	}
	div[role='dialog'] {
		div[role='document'] {
			> div {
				margin: 4px;
			}
		}
	}
	#informationDialog {
		padding: 2px !important;
	}
	.medialListView {
		tr {
			td:nth-child(4) {
				width: 100px !important;
			}
			td:nth-child(4),
			td:nth-child(5),
			td:nth-child(6),
			td:nth-child(8),
			td:nth-child(9) {
				display: none !important;
			}
			td.titleLabel {
				width: 150px !important;
			}
		}
	}

	.profileScreenWrapper {
		.paginatedTableWrapper .tableContentSingleWrapper {
			> div {
				&:nth-child(2) {
					width: 30%;
				}
			}
		}
		.licencesContentWrapper .paginationContentWrapper {
			> div:first-child {
				width: 30%;
			}
			> div:last-child {
				width: 50% !important;
			}
			.mediaPerPageDropDownSelectBox > select {
				margin-left: 0px;
			}
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 640px) and (orientation: portrait) {
	.medialListView {
		tr {
			td.thumbnailContainer {
				width: 60px !important;
			}
		}
	}
}
/* Portrait and landscape phones- HTC */
@media only screen and (min-width: 320px) and (max-width: 640px) {
	.paginationContentWrapper {
		display: grid;
		position: relative;
		justify-content: center;
	}
	#mediaFilterOptions {
		#sortByTypeSortingDropdown {
			#sortingMenuButton {
				border: none;
			}
		}
	}
	.medialListView tr {
		td:nth-child(4),
		td:nth-child(5),
		td:nth-child(6),
		td:nth-child(8) {
			display: none !important;
		}
		td.mediaCreation {
			width: 110px !important;
		}
		.desktopMoreButton {
			display: none;
		}
		.mobileViewListingMenuContainer {
			display: block;
		}
	}
	#welcomeScreen {
		.welcomeTexts label:first-child {
			font-size: 1.4em;
		}
	}
	.mediaContentWrapper .filtersSection {
		.uploadsSection {
			button {
				padding-top: 2px;
			}
		}
		.catalogSwitchChange {
			margin: 0 !important ;
			width: 45px !important;
			height: 35px;
		}
	}
}

@media only screen and (min-width: 320px) and (max-width: 640px) and (orientation: landscape) {
	#mediaFilterOptions {
		// added responsive code without mobile specific
		width: 100%;
		height: auto;
		> div {
			text-align: center;
		}
		> div.catalogSwitchChange {
			margin-left: 30px;
			width: auto !important;
		}
		button {
			left: 0px;
		}
		.uploadsSection {
			width: 100% !important;
			margin-top: 5px;
			text-align: center;
			position: relative;
			float: left;
			right: 0px !important;
			width: 100%;
		}
		.listingIconsSection {
			display: none !important;
		}
	}
}

@media only screen and (min-width: 641px) and (max-width: 800px) {
	.medialListView tr {
		td:nth-child(4),
		td:nth-child(5),
		td:nth-child(6),
		td:nth-child(7),
		td:nth-child(9) {
			display: none !important;
		}
		td.mediaType {
			width: 100px !important;
		}
		td.titleLabel {
			width: 150px !important;
		}
		.desktopMoreButton {
			display: none;
		}
		.mobileViewListingMenuContainer {
			display: block;
		}
	}
}

@media only screen and (min-width: 801px) and (max-width: 900px) {
	.medialListView tr {
		td:nth-child(4),
		td:nth-child(5),
		td:nth-child(6),
		td:nth-child(7) {
			display: none !important;
		}
		td.mediaCreation,
		td.mediaType {
			width: 110px !important;
		}
		td.titleLabel {
			width: 200px !important;
		}
		.desktopMoreButton {
			display: none;
		}
		.mobileViewListingMenuContainer {
			display: block;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
	header {
		justify-content: space-between;
		a {
			margin-right: $spacing-Z;
		}
	}
}

/* Genric css for certain min width */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
	._loading_overlay_wrapper {
		height: 100%;
	}
	.MuiPopover-paper-155 {
		position: relative;
		width: auto;
		right: 20px;
		left: calc(100% - 180px) !important;
	}
	.navRightArrow {
		width: 25px;
		height: 16px !important;
		background: url('./assets/images/hamburger.svg') !important;
	}
	.navLeftArrow {
		background: url('./assets/images/X_white.svg') !important;
	}
	#navDrawer {
		.mobileDownArrowBtn {
			margin-left: 60px;
			margin-top: 10px;
			border: none;
			background: none;
		}
		.topHeaderBlocks {
			display: block !important;
			margin-right: 35px;
			padding-top: 10px;

			> button {
				border: none;
				background: none;
			}
		}
		.mediaContentWrapper .filtersSection .formControl {
			min-width: 120px;
		}
		.sortingDropDownWrapper {
			//min-width: 120px;
			padding-top: 0;
			padding-bottom: 0;

			&:after {
				right: 10px;
			}
		}
		.sortingDropDownWrapper .sortingDropDown {
			width: 120px;
			height: 32px;
			padding-left: 15px;
			padding-right: 25px;
		}
		.mediaContentWrapper .filtersSection .selectAllWrapper {
			margin-left: 5px;
			margin-right: 5px;
			height: 32px;
			width: 65px;
			padding-left: 2px;

			> span:first-child {
				padding-left: 3px;
				padding-right: 3px;
			}
			span > svg {
				width: 0.8em;
				height: 0.8em;
			}
			> button > svg {
				width: 12px;
				height: 12px;
			}
		}
		.mediaContentWrapper .filtersSection .listingIconsSection {
			width: 65px;
			padding-top: 15px;
			padding-bottom: 15px;
			height: 32px;
		}
		.mediaContentWrapper .filtersSection .listingIconsSection > button {
			width: 12px;
			height: 12px;
			left: -10px;
			top: -5px;
		}
		.mediaContentWrapper .filtersSection .selectAllWrapper > button {
			margin-left: 15px;
		}
		header {
			background-color: $header-bg-color !important;
		}
		#navDrawerClose > div {
			width: 0px;
			border: 0;
		}
		.authenticatedHeader {
			display: none;
		}
		.mediaContentWrapper .filtersSection .actionsSection > button {
			font-size: 11px;
			letter-spacing: 0px;
			margin-left: 0;
			left: -5px;
			height: 12px;
			width: 12px;
			font-weight: 600;
		}
	}
	.mobileOnlyMenuContainer {
		display: block !important;
	}
	.mobileViewPortItem {
		display: none !important;
	}
	.mobViewlanguageListHeader {
		display: block;
	}
	.mobileLanguageList div[role='document'] {
		left: auto !important;
		right: 16px;
	}
	.authenticatedHeader .rightSectionHeader {
		z-index: 5000;
		left: 0;
		top: 0px;
		display: flex;
		align-items: center;
		justify-content: space-around;

		.topHeaderBlocks {
			width: 50px;
		}
	}
	.langSelectionBox {
		display: none !important;
	}
	.mobileProfileIcon {
		> button {
			display: block;
			position: relative;
			left: 60px;
			top: 20px;
			width: 16px;
			height: 16px;
			border: none;
			background: none;
		}
	}
	.profileScreenWrapper {
		display: block;
	}
	.profileLeftColumn {
		display: none !important;
	}
	.genericTextInput {
		width: 100%;
		min-width: 100%;
		padding-left: 3%;
		padding-right: 3%;
	}
	.profileSectionInputBlock {
		&:nth-child(7) {
			width: 100% !important;
		}
		&:last-child {
			width: 100%;
		}
	}
	.licencesContentWrapper {
		padding-left: 0;
		padding-right: 0;
	}
	.usersListingTopHeader {
		justify-content: flex-end;
	}
	.usersListingTableHeader {
		display: none !important;
	}
	.dialogBoxInternalBlock {
		> input[type='text'] {
			min-width: 220px;
		}
	}
	.newUserHeader {
		text-align: center;
	}
	.headercloseBtn {
		margin-right: 0;
	}
	.deleteUserBtnContentWrapper {
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		display: flex;
		width: 100%;
	}
	.dialogActionsWrapper {
		padding-bottom: 0;
		width: 100%;
		position: relative;
		top: 0px;
	}
	.dialogContentWrapper .dialogContentWrapper:first-child {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
	.dialogTitle > *[class*='MuiTypography-h'] {
		margin: 24px 0 0 !important;
		.headercloseBtn {
			right: 24px;
		}
	}
	.authenticatedHeader .rightSectionHeader #headerSearchBlock > button {
		padding-top: 4px;
	}
	.licencesContentWrapper .paginationContentWrapper > div:first-child > span {
		font-size: $small-font-size;
		letter-spacing: 0px;
	}
	.licencesContentWrapper .usersListingTopHeader {
		height: 50px;
		padding: 0 15px;
	}
	.licencesContentWrapper .usersListingTopHeader .searchTextInput {
		width: 195px;
		height: 37px;
		padding-left: 30px;
		padding-right: 20px;
		font-size: 10px;
		letter-spacing: 1px;
	}
	.profileScreenWrapper .profileRightColumn .profileSectionInputBlock {
		align-items: flex-start !important;
	}
	#topMenuSystem {
		left: 0 !important;
	}
	#welcomeScreen {
		height: auto;
		.backgroundHolder {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: auto;
			justify-content: flex-start;
			align-items: center;
			padding-top: 20px;
		}
		.profileImage {
			margin-left: 0;
			border-radius: 50%;
			width: 100px;
			height: 100px;
		}
		label:first-child {
			line-height: 1.13;
		}
		.welcomeTexts {
			margin-left: 0;
			label {
				height: auto;
			}
		}
	}
	.mobileProfileIcon {
		background-image: none !important;
	}
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	._loading_overlay_wrapper {
		height: 100%;
	}
	.MuiPopover-paper-155 {
		position: relative;
		width: auto;
		right: 20px;
		left: calc(100% - 180px) !important;
	}
	.navRightArrow {
		height: 16px !important;
		background: url('./assets/images/hamburger.svg') !important;
	}
	.navLeftArrow {
		background: url('./assets/images/X_white.svg') !important;
	}
	#navDrawer {
		background-color: $header-bg-color !important;
		margin-top: 0px;
		width: 100%;

		#navDrawerMain {
			padding: 0 !important;
			display: block;
			margin-top: -5px;
		}

		.mobileDownArrowBtn {
			margin-left: 60px;
			margin-top: 10px;
			border: none;
			background: none;
		}
		.topHeaderBlocks {
			display: block !important;
			margin-right: 35px;
			padding-top: 10px;

			> button {
				border: none;
				background: none;
			}
		}
		.mediaContentWrapper .filtersSection .formControl {
			min-width: 120px;
		}
		.sortingDropDownWrapper .sortingDropDown {
			width: 100%;
			height: 32px;
			padding-left: 15px;
			padding-right: 25px;
		}
		.mediaContentWrapper .filtersSection .selectAllWrapper,
		.mediaContentWrapper .filtersSection .catalogSwitchChange {
			margin-left: 5px;
			margin-right: 5px;
			height: 32px;
			width: 55px !important;
			padding-left: 5px;

			> span:first-child {
				padding-left: 3px;
				padding-right: 3px;
			}
			span > svg {
				width: 0.8em;
				height: 0.8em;
			}
			> button > svg {
				width: 12px;
				height: 12px;
			}
		}
		.mediaContentWrapper .filtersSection .listingIconsSection {
			width: 60px;
			height: 32px;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		.mediaContentWrapper .filtersSection .listingIconsSection > button {
			width: 12px;
			height: 12px;
			left: -10px;
			top: 0;
		}
		.mediaContentWrapper .filtersSection .selectAllWrapper > button {
			margin-left: 5px;
		}
		header {
			background-color: $header-bg-color !important;
		}
		#navDrawerClose,
		#navDrawerClose > div {
			width: 0px;
			border: 0;
		}
		.authenticatedHeader {
			display: none;
		}
		.mediaContentWrapper .filtersSection .actionsSection > button {
			font-size: 11px;
			letter-spacing: 0px;
			margin-left: 0;
			left: -5px;
			height: 12px;
			width: 12px;
			font-weight: 600;
		}
	}
	#paginationContentForDashboard {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.dashboardContentInternalWrapper {
			width: 75% !important;
			margin-top: 0;
			display: flex;
			flex-direction: row;
			padding-top: 0;
			height: 50px;
		}
	}
	.mediaContentWrapper {
		padding: 0;
		margin-left: 0;
		height: calc(100% - 120px);
	}
	.deleteUserBtnContentWrapper {
		flex-direction: column;
		display: flex;
		justify-content: space-between;
	}
	.mediaInternalListContianer {
		display: none;
	}

	.rightSectionHeader {
		width: 100%;
		justify-content: flex-end;
		padding-right: 15px;
	}
	.mobileOnlyMenuContainer {
		display: block !important;
	}
	.mobileViewPortItem {
		display: none !important;
	}
	.mobViewlanguageListHeader {
		display: block;
	}
	.mobileLanguageList div[role='document'] {
		left: auto !important;
		right: 16px;
	}
	.langSelectionBox {
		display: none !important;
	}
	.mobileProfileIcon {
		> button {
			display: block;
			position: relative;
			left: 60px;
			top: 20px;
			width: 16px;
			height: 16px;
			border: none;
			background: none;
		}
	}
	.thumbnailsContentWrapper .actualThubGallery {
		float: left;
		height: 200px;
		overflow-y: auto;
		margin-bottom: 20px;
	}
	.licencesContentWrapper .paginationContentWrapper > div:first-child > span {
		font-size: $small-font-size;
		letter-spacing: 0px;
	}
	.licencesContentWrapper .usersListingTopHeader {
		height: 50px;
		padding: 0 15px;
	}
	.licencesContentWrapper .usersListingTopHeader .searchTextInput {
		width: 195px;
		height: 37px;
		padding-left: 30px;
		padding-right: 20px;
		font-size: 10px;
		letter-spacing: 1px;
	}
	.profileScreenWrapper .profileRightColumn .profileSectionInputBlock {
		align-items: flex-start !important;
	}
	#topMenuSystem {
		left: 0 !important;
	}
	#welcomeScreen {
		height: auto;
		.backgroundHolder {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: auto;
			justify-content: flex-start;
			align-items: center;
			padding-top: 20px;
			padding-left: 0;
		}
		.profileImage {
			margin-left: 0;
			border-radius: 50%;
			width: 100px;
			height: 100px;
		}
		label:first-child {
			line-height: 1.13;
		}
		.welcomeTexts {
			margin-left: 0;
			label {
				height: auto;
			}
		}
	}
	.mobileProfileIcon {
		background-image: none !important;
	}
	#addToPlaylistDialog div[class*='MuiPaper-rounded-'] {
		min-width: 300px !important;
	}

	#menuSeparatorDiv {
		display: none;
	}

	#mediaFilterOptions {
		// added responsive code without mobile specific
		width: 100%;
		height: auto;
		> div.catalogSwitchChange {
			margin-left: 30px;
			width: auto !important;
			transform: translateY(2px);
		}
		button {
			left: 0px;
		}
	}

	#searchScreen #mediaFilterOptions {
		.selectAllWrapper {
			.boxed {
				//top: -12px;
			}
		}
		.actionsSection {
			padding-top: 8px;
			transform: translateY(4px);
		}
	}

	.monthDisplayCalendar {
		padding-left: 20px;
		width: 100%;
		display: block;
	}
	#allSearchHeaderContainer {
		display: none;
	}
	#mobileViewSearchScreenHeader {
		display: block;
	}
	.searchResultLabelWrapper {
		padding: 20px;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		justify-content: space-between;
	}
	.authenticatedHeader .leftSectionHeader {
		#breadCrumbSection.mobileView {
			h1 {
				display: none;
			}
		}
	}
	.listBodyContainerMobileView {
		min-height: auto !important;
	}
	.catalogsSearchMobileViewWrapper {
		.sortingDropDownWrapper {
			margin-top: -10px;
			height: 30px;
		}
		.downArrowWrapperSearchDropEx {
			left: 22px;
			top: -6px;
		}

		.searchInCatalogsMobileWrapper {
			transform: translateX(10px);
		}
	}
	.searchPanelThirdrow {
		.mediaTypeWrapper {
			width: 100px;
			margin-top: -10px;
			margin-right: 0;

			svg[role='presentation'] {
				margin-top: 0;
				margin-left: 0;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	#catalogContainer {
		max-height: none !important;
	}

	#loggedEmailDisplayBlock {
		width: 90px;
		margin-right: 15px;
		.accountNamesWrapper a {
			max-width: 170px;
		}
		.mobileProfileIcon {
			width: auto;
			height: auto;
		}
		.headerIcon {
			position: relative;
			left: -10px;
		}
	}

	.profileScreenWrapper {
		.profileLeftPanelMenu {
			display: inline-flex;
			height: auto;
			width: 100%;
			.profileIconBlock .thinLineProfileIcon {
				height: 8px !important;
				width: 100% !important;
			}
			.profileIconBlock .thickLineProfileIcon {
				width: 45px !important;
			}
		}
		.paginatedTableWrapper {
			padding-left: 10px;
			padding-right: 10px;

			.tableContentSingleWrapper {
				> div {
					&:first-child {
						display: none;
					}
					&:nth-child(2) {
						// width: 30%;

						> img {
							width: 40px;
							height: 40px;
							margin-right: 5px;
						}
						> button {
							width: 16px;
							height: 16px;

							> img {
								width: inherit;
								height: inherit;
							}
						}
					}
					&:nth-child(3) {
						padding-left: 10px;
						padding-right: 10px;

						> h3 {
							font-size: 10px;
							word-break: break-all;
						}
					}
				}
			}
		}
	}
	#navDrawer #navDrawerOpen {
		z-index: 5000;
	}
	.medialListView tr {
		td.titleLabel {
			.listViewSettingContainer {
				display: none !important;
			}
		}
	}
	.medialListView tr {
		td.deleteColumn {
			display: none !important;
		}
		.desktopMoreButton {
			display: none;
		}
		td.mobileViewMenu {
			display: block !important;
			.mobileViewListingMenuContainer {
				display: block;
			}
		}
	}

	.mediaContentWrapper .medialListView tr td button.moreButton {
		border: 1px solid #000;
		padding: 2px 6px 5px 5px;
		border-radius: 5px;
		margin-left: 0;
	}
	.mediaContentWrapper .medialListView tr .round {
		left: 10px;
	}
	.authenticatedHeader .leftSectionHeader {
		padding-left: 10px;
		padding-right: 10px;
	}
	.authenticatedHeader {
		#breadCrumbSection {
			display: none;
		}
		.rightSectionHeader {
			width: 80%;
			z-index: 5000;
			float: right;
			align-items: center;
			justify-content: space-evenly;
			margin-left: 100px;
			position: fixed;
		}
	}

	#breadCrumbMobileView {
		padding-left: 5px;
		padding-right: 5px;
		display: inline-block;
		width: 100%;
		#breadCrumbGroup ol {
			max-width: 100%;
			max-height: 30px;
			overflow: auto;
		}
		#breadCrumbSection {
			h1 {
				display: none;
			}
		}
	}
	.profileScreenWrapper {
		display: block;
		.customPropertiesContentWrapper {
			height: calc(100% - 110px);
		}
	}
	.profileLeftColumn {
		display: none !important;
	}
	.profileRightColumn {
		padding-top: 65px !important;
		width: 100% !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	.genericTextInput {
		width: 100%;
		min-width: 100%;
		padding-left: 3%;
		padding-right: 3%;
	}
	.profileSectionInputBlock {
		&:nth-child(7) {
			width: 100% !important;
		}
		&:last-child {
			width: 100%;
		}
	}
	.licencesContentWrapper {
		padding-left: 0;
		padding-right: 0;
		.newUserBtn {
			min-width: 100px !important;
			margin-left: 10px;
		}
	}
	.usersListingTopHeader {
		justify-content: flex-end;
	}
	.usersListingTableHeader {
		display: none !important;
	}
	.dialogBoxInternalBlock {
		> input[type='text'] {
			min-width: 220px;
		}
	}
	.newUserHeader {
		text-align: center;
	}
	.headercloseBtn {
		margin-right: 0;
	}
	.deleteUserBtnContentWrapper {
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		display: flex;
		width: 100%;
	}
	.dialogTitleContentWrapper {
		padding-top: 0;
		padding-left: 0;
	}
	.dialogActionsWrapper {
		padding-bottom: 0;
		width: 100%;
		position: relative;
		top: 0px;
	}
	.thumbnailsContentWrapper .actualThubGallery {
		float: left;
		height: 200px;
		overflow-y: auto;
		margin-bottom: 20px;
	}
	#InspectBottomActions .bottomActionButtons {
		text-align: center;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:first-child {
		width: 11%;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(2) {
		display: none;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(3) {
		width: 64%;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(4) {
		display: none;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(5) {
		display: none;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(6) {
		display: none;
	}
	.sortableDataTableContainer .headersContainer .singleHeader:nth-child(7) {
		width: 16%;
	}
	.sortableDataTableContainer .headersContainer {
		.singleHeader h4 {
			overflow: inherit !important;
			font-size: 11px !important;
		}
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:first-child {
		width: 5%;
	}
	.sortableDataTableContainer .draggabaleEachRowContainer {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(2) {
		width: 5%;
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(3) {
		width: 25%;
		margin-left: 5px;
		.playicon {
			display: none;
		}
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(4) {
		width: 40%;
		label {
			font-size: 11px;
			word-break: break-all;
		}
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(5) {
		display: none;
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(6) {
		display: none;
	}
	.sortableDataTableContainer .draggabaleEachRowContainer div:nth-child(7) {
		width: 16.5%;
		justify-content: flex-start;
		label {
			font-size: 10px;
		}
	}
	.searchScreenPaginationWrapper {
		.paginationContentWrapper {
			align-items: center;
			justify-content: center;

			> div:last-child {
				width: 50% !important;

				> button {
					background: #ddd8d8;
					width: 27px;
					height: 19px;
					border-radius: 6px;

					svg {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
	.mediaFilterOptionsForMobilePlaylistOverrided {
		height: 90px !important;
	}
	.playlistListViewDataContainer {
		tr td:nth-child(1) {
			width: 10% !important;
		}
		tr td:nth-child(2) {
			width: 15% !important;
		}
		tr td:nth-child(4) {
			width: 65% !important;
		}
		tr td:nth-child(7) {
			display: block !important;
			width: 20% !important;

			.trashIconContainer {
				margin-left: 0;
				margin-top: 35px;
				width: 100%;
			}
		}
	}
	.paginationContentFirstRow {
		display: flex !important;
		width: 100% !important;
		justify-content: space-evenly !important;
		height: 80px !important;
		align-items: center;

		.paginationFirstBlock {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			span {
				font-size: 11px;
				color: #6f7775;
				transform: translateY(1px);
			}

			select {
				margin-left: 10px !important;
				top: 0 !important;
				font-size: 11px !important;
			}
		}
		.paginationSecondBlock {
			font-size: 11px;
			color: #6f7775;
			margin-top: 5px;
		}
	}
	.searchListBody tr td:nth-child(1) {
		width: 10% !important;
	}
	.searchListBody tr td:nth-child(2) {
		width: 20% !important;
	}
	.searchListBody tr td:nth-child(4) {
		width: 50% !important;
	}
	.searchListBody tr td:nth-child(6) {
		width: 20% !important;
		display: block !important;

		.trashIconContainer {
			padding-top: 28px;
			margin-left: 0;
		}
	}
	.mediaContentWrapper .listHeader {
		display: none !important;
	}
	.mobileOnlyTitle {
		display: block;
		margin-right: 15px;
	}
	.searchResultLabelWrapper {
		margin-top: 0;
	}
	.mediaFilterOptionsMobileView {
		display: block !important;
	}
	#searchKeywordsMobileWrapper {
		display: flex !important;
	}
	.authenticatedHeader .topHeaderBlocks {
		justify-content: space-around;
	}
	.popover-content {
		min-width: 300px;
	}
	.popover-content.-arrow::before,
	.popover-content::after {
		right: 65px !important;
	}
}

@media only screen and (min-width: 1025px) {
	.authenticatedHeader .navBurger {
		display: none;
	}
	#navDrawer #navDrawerOpen {
		.navLeftArrow {
			display: none;
		}
	}

	#breadCrumbMobileView {
		display: none;
	}
	#InspectBottomActions .bottomActionButtons {
		text-align: right;
		.cancelButton {
			margin-right: 2%;
		}
	}
	.mobViewlanguageListHeader {
		display: none !important;
	}
	#navDrawerClose {
		.navLeftArrow {
			display: none !important;
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.authenticatedHeader .rightSectionHeader {
		height: 65px;
		justify-content: space-around;
		margin-left: 25px;
		width: 100%;
	}
	.uploadingStatusWrapper {
		display: flex;
		border: none;

		> label {
			display: none;
		}
	}

	.popover-content.-arrow::before,
	.popover-content::after {
		right: 65px !important;
	}

	.popover-content {
		width: 300px;
	}
}
