.portalThemesWrapper {
	display: flex;
	width: 100%;

	& .configurationThemes {
		flex: 1;
		background-color: #f2f5f7;
		overflow: auto;
		padding-bottom: 100px;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 600;
			padding: 12px 24px;
			height: 46px;
			background-color: white;
		}

		&__sub-header {
			font-size: 12px;
			padding: 12px 24px;
			height: 46px;
			margin-top: 2px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: white;
			cursor: pointer;
		}

		&__default-themes,
		&__saved-themes {
			padding: 24px;
			font-size: 12px;
			background: #f2f5f7 0% 0% no-repeat padding-box;
		}

		&__default-themes {
			margin-top: 10px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: 120px;
			grid-gap: 0 15px;

			& .theme-item {
				background-size: cover !important;
				background-position: center center;
				color: white;
				padding: 5px;
				display: flex;
				flex-direction: column;
				justify-content: end;
				border-radius: 4px;
				border: 3px solid transparent;
				transition: margin 0.05s ease-in-out;
				cursor: pointer;
				background-image: url('../../../../../assets/images/computer.png');
				position: relative;

				&:hover {
					margin: 2px;
					border-color: #126cfc;
				}
				&--selected {
					border-color: #126cfc;
				}

				button {
					position: absolute;
					top: 5px;
					right: 5px;
					cursor: pointer;
					width: 24px;
					height: 24px;
					border: none;
					border-radius: 4px;
					border: 1px solid transparent;
					&:hover {
						border-color: #126cfc;
					}
				}
			}
		}

		&__saved-themes {
			padding-top: 0px;
			border-bottom: 1px solid #dfe7eb;

			& .saved-theme-select__control {
				margin-top: 16px;
				border-radius: 4px;

				.MuiInputBase-root {
					background-color: white;
					border-radius: 4px !important;
					&.Mui-focused,
					&:not(.Mui-disabled):focus,
					&:not(.Mui-disabled):hover {
						border-color: #126cfc;
						background-color: white;
						border-radius: 4px !important;
					}
					.MuiSelect-select.MuiSelect-select {
						border-color: transparent;
					}
				}
				.MuiSelect-root {
					padding: 10px 12px;
				}
				.MuiSelect-icon {
					top: calc(50% - 10px);
				}

				&.selected {
					border: 1px solid #126cfc;
				}

				& .saved-themes-option__action {
					display: none !important;
				}
			}
		}

		& .configurationTheme-editing-title,
		& .configurationTheme-editing-name {
			font-size: 12px;
			padding: 12px 24px;

			&.borderBottom {
				border-bottom: 1px solid #dfe7eb;
			}

			&.sub-header {
				font-weight: 600;
			}
		}

		& .configurationTheme__input {
			margin-top: 12px;
			display: flex;
			align-items: center;

			& input {
				border: 1px solid #dfe7eb;
				border-radius: 5px;
				padding: 8px 14px;
				min-height: 30px;
				margin-right: 10px;
				font-size: 12px;
				background: white !important;
				border: 0;
			}
		}

		&-notice {
			font-size: 12px;
			text-align: center;
			padding: 12px 0px;
		}

		& .configurationTheme-cards {
			& .configurationTheme-card {
				background: white;
				border-radius: 2px;
				&:not(:first-child) {
					margin-top: 12px;
				}

				&__header {
					margin-bottom: 2px;
					padding: 12px 24px;
					border-bottom: 2px solid #f2f5f7;
					font-size: 14px;
					font-weight: 600;
					display: flex;
					justify-content: space-between;
					align-items: center;
					cursor: pointer;
					height: 46px;
					&:hover {
						background-color: #e5eaed;
					}
				}

				&__content {
					padding: 12px 48px;

					& .configurationTheme__control {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						font-size: 12px;
						min-height: 36px;
						padding: 0 12px;
						margin-left: -12px;

						&:hover {
							background-color: #f2f5f7;
							.MuiFormControlLabel-root {
								background-color: #f2f5f7;
							}
						}
						&-end {
							justify-content: flex-end;
						}

						.MuiFormControlLabel-root {
							margin-right: 0 !important;
							&:hover {
								background-color: #f2f5f7;
							}
						}
						.MuiIconButton-colorSecondary:hover {
							background-color: transparent !important;
						}
						.MuiFormHelperText-root {
							font-size: 0.85rem;
							margin-bottom: 5px;
							margin-top: -5px;
						}

						.configurationTheme__input {
							padding: 8px 14px;
							height: 32px;
							min-width: 170px;
							background-color: #fff;
						}
					}
				}
			}
		}

		&_tooltip-container {
			position: relative;
			height: 25px;
			&:hover .tooltiptext {
				display: block;
			}
			.tooltiptext {
				top: -26px;
				left: -80px;
				max-width: none;
				opacity: 1;
				border-radius: 4px;
			}
		}
	}

	& .previewThemes {
		width: 60%;
	}

	.portal-theme-actions {
		background: white;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 0 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: 2px solid #f2f5f7;
		flex-wrap: wrap;

		button {
			font-weight: 600;
			height: 30px;
		}

		> *:not(:last-child) {
			margin-right: 12px !important;
		}

		.MuiButton-label {
			line-height: 1;
		}

		.portal-theme-actions__change-notice {
			width: 100%;
			padding: 0px 10px 10px 10px;
			text-align: center;
			display: flex;
			flex-direction: column;
		}
	}

	&__no-data {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		label {
			margin-top: 24px;
		}
	}
}

.saved-themes-option {
	.saved-themes-option__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&--edit,
	&--delete {
		display: none;
	}

	&:hover {
		.saved-themes-option {
			background-color: #f2f5f7;
			&__action button {
				display: block;
			}
		}
	}
}

.saved-themes-option__action {
	display: flex;
	align-items: center;
	button {
		cursor: pointer;
		width: 24px;
		height: 24px;
		border: none;
		border-radius: 4px;
		margin-right: 10px;
		border: 1px solid transparent;
		&:hover {
			border-color: #126cfc;
		}
	}
}

div[role='presentation'] li.saved-themes-option:not(.treeCatalogItem) {
	height: 32px;
	min-height: 0px;
	padding: 0 14px 0 24px;
}
