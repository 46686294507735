.filesSection {

  padding-top: 30px;
  padding-bottom: 30px;

  .uploadSection {
    text-align: left;
    margin-left: 10px;
  }
  .uploadButton {
    display: inline-block;
    cursor: pointer;
    svg {
      float: left;
      margin-right: 10px;
    }
    span {
      font-weight: 600;
      float: left;
    }
  }

  .videoAudioImageInfoDiv {
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }

  .infoDiv {
    a {
      color: black;
    }
  }

  .previewImage {
    cursor: pointer;
    img,
    span {
      float: left;
      margin-right: 5px;
    }
  }

  img.deleteIcon,
  img.previewIcon,
  img.exchangeIcon,
  img.downloadIcon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
}

.subtitleTitle {
  cursor: default;
}

.subtitleRadioButton .MuiIconButton-root {
  width: 19%;
}

.subtitleRadioButton .MuiIconButton-root .MuiIconButton-label div {
  justify-content: center;
}

.subtitleRadioButton .MuiIconButton-root .MuiIconButton-label div svg {
  left: auto;
}
