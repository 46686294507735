.editor-timeline {
	background-color: #ffffff;
	flex: 1;
	padding-bottom: 12px;
	.vis-custom-time > .vis-custom-time-marker,
	.scene-editor-marker > .vis-custom-time-marker {
		visibility: visible;
	}
	&__item {
		background-color: #dbe9ff;
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		&.vis-item.vis-selected {
			background-color: #dbe9ff;
			border: 1px solid #326af3;
		}
		.vis-item-content {
			padding: 5px 20px;
			border-radius: 4px;
			font-size: 12px;
			color: #676767;
			width: 100%;
			div {
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		&--pink {
			background-color: #fac0ea;
			border-color: #ff8edf;
			&.vis-item.vis-selected {
				background-color: #fac0ea;
			}
		}
		&--purple {
			background-color: #cbc4ff;
			border-color: #a397f9;
			&.vis-item.vis-selected {
				background-color: #cbc4ff;
			}
		}
		&--blue {
			background-color: #dbe9ff;
			border-color: #a4c4f5;
			&.vis-item.vis-selected {
				background-color: #dbe9ff;
			}
		}
		&--yellow {
			background-color: #fae1c0fa;
			border-color: #e9b28cfa;
			&.vis-item.vis-selected {
				background-color: #fae1c0fa;
			}
		}
		&--green {
			background-color: #c7f2c9;
			border-color: #69c66f;
			&.vis-item.vis-selected {
				background-color: #c7f2c9;
			}
		}
		&--red {
			background-color: #fbc3bc;
			border-color: #f38375;
			&.vis-item.vis-selected {
				background-color: #fbc3bc;
			}
		}
	}
	.vis-item.vis-range {
		border-radius: 4px;
		.vis-drag-left,
		.vis-drag-right {
			width: 12px !important;
			top: 0px;
			bottom: 0px;
			background-color: #ffffff;
			opacity: 0.5;
			height: auto;
			border-radius: 2px;
		}

		.vis-drag-left {
			left: 0;
		}
		.vis-drag-right {
			right: 0;
		}
	}

	&__toolbar {
		display: flex;
		padding: 12px;
		background-color: #ffffff;
		&-slot {
			display: flex;
			flex: 1;
			align-items: center;
			&:last-child {
				justify-content: flex-end;
			}
			&:not(:last-child):not(:first-child) {
				justify-content: center;
			}
		}
		&--btn {
			padding: 0 !important;
			margin: 0 12px !important;
			width: 33px !important;
			height: 33px !important;
		}
		&--btnZoom {
			margin: 0 !important;
			background-color: #f7f8fa !important;
			border-radius: 4px !important;
		}
		&--slider {
			margin: 0 5px;
		}
		&--btnFit {
			background-color: #f7f8fa !important;
			height: 33px !important;
			width: 45px !important;
			border-color: transparent !important;
			min-width: 45px !important;
			margin: 0 0 0 5px !important;
		}
		&--timestamp {
			color: #cdd4d9;
			font-size: 12px;
			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}

	// Override style of vis-timeline
	.vis-timeline {
		border-left: 1px solid transparent;
		border-right: 1px solid transparent;
	}
	.vis-center {
		background-color: #f7f8fa;
	}
	.vis-label:last-child {
		font-weight: 600;
		text-transform: uppercase;
	}
	.vis-labelset .vis-label {
		border: 1px solid transparent;
	}
	.vis-custom-time {
		background-color: #326af3;
		position: relative;
		width: 1px;
		&::before {
			content: ' ';
			position: absolute;
			width: 0px;
			height: 0px;
			border-left: solid transparent;
			border-right: solid transparent;
			border-top: solid #326af3;
			left: -7px;
			border-width: 7.5px;
		}
	}

	.vis-time-axis {
		margin-top: 10px;
		height: 30px !important;
		border-bottom: 3px solid transparent;
		transition: all 0.25s;

		&.mouse-over {
			border-bottom: 3px solid #3269f362;
		}
	}

	.vis-group {
		border-bottom: none;
	}

	.video-group {
		& .vis-item {
			transition: all 0.05s;

			&.mouse-over {
				border-top: 3px solid #3269f362;
			}
		}
	}

	.vis-content {
		& .vis-label .vis-inner {
			height: 39px;
		}
	}
}
