@import '../../global';

.popover {
	position: relative;
	&--open {
		z-index: 1300;
	}
}

.popover-content {
	min-width: 500px;
	width: auto;
	height: auto;
	background-color: $header-bg-color;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
	background-clip: padding-box;
	position: absolute;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: 0;
	&:empty {
		visibility: hidden;
	}
}

.popover-inner {
	.dialogTitle {
		display: flex;
		justify-content: space-between;
		border: none;
		border-bottom: 1px solid #e5e9ec;
	}

	.eachSelectedItemWrapper-label {
		flex: 1 0 95%;
	}

	.selectedItemsContentWrapperForStatusDialog .eachSelectedItemWrapper--wrapper:not(:last-child) {
		margin-bottom: 8px;
	}
}

.popover-content.-arrow {
	margin-top: 10px;
}

.popover-content.-arrow::before,
.popover-content::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	right: 165px;
}

.popover-content.-arrow::before {
	top: -10px;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-bottom: 10px solid rgba(0, 0, 0, 0.15);
}

.popover-content.-arrow::after {
	top: -8px;
	margin-left: 1px;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid $header-bg-color;
}

.selectedItemsContentWrapperForStatusDialog .tooltip {
	width: 60% !important;
}
.selectedItemsContentWrapperForStatusDialog .fileSizeUploaded {
	width: 10% !important;
}
.selectedItemsContentWrapperForStatusDialog {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 1px solid $media-content-border-color;
	border-radius: 4px;
	padding-bottom: 12px;
}
