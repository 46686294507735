@import "../../../../global";

#interactEditorSVGOverlay {
  .noVisibility {
    visibility: hidden;
  }
  #interactEditorSVGOverlayElement {
    position: absolute;
    z-index: 5;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    //  z-index: 5000000;
    color: #ffffff;
    //pointer-events: none;
    #svgElement svg {
      width: 100% !important;
      height: 100% !important;

      g rect {
        // pointer-events: none;
        // stroke: lightslategrey;
        // stroke-width: 5px;
        cursor: pointer;
      }
    }
    .coordinateOverlay {
      width: 100% !important;
      height: 100% !important;
      position: absolute;

      top: 0px;
      svg {
        //width: inherit;
        //height: inherit;
      }
      circle {
        fill: #52acff;
        stroke: transparent;
        display: block;
        cursor: nw-resize;
      }

      circle[attr="bottomleft"],
      circle[attr="topright"] {
        cursor: ne-resize;
      }

      .coordinateText {
        fill: #52acff;
        font-weight: 600;
        font-size: 35px;
      }
    }

    .draggable-element {
      pointer-events: bounding-box;
      fill: transparent;
      stroke: #ffffff;
      stroke-width: 3px;
      cursor: move;
    }
  }
}
