
#streamInfo {
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
      }
      
      th, td {
        text-align: left;
        padding: 4px;
      }
}
