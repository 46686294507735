.media-library {
	& .title {
		h6 {
			font-weight: bold;
		}

		button.MuiButton-sizeSmall {
			width: 20px;
			height: 20px;
			min-width: 20px;
			background-color: #eaf0fe;
		}
	}

	& .dialog-content {
		height: auto;

		& .tabs-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e5e9ec;

			& .tab-title {
				display: flex;
				align-items: center;

				& .icon {
					display: flex;
					margin-right: 5px;
				}

				& .progress-icon {
					color: rgba(0, 0, 0, 0.5);
					height: 20px;
				}
			}
		}

		& .clear-search-icon {
			cursor: pointer;
		}
	}

	& .dialog-actions {
		border-top: 1px solid #e5e9ec;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;

		& .dialog-notion {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;
		}
	}
}

.MuiTab-textColorPrimary.Mui-selected {
	color: black !important;
}
.MuiTabPanel-root {
	padding: 0 !important;
}
