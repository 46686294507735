#inspectView.inspectView__scenes-project {
	.inspectViewMenuListItems {
		&#scenes:not(.inspectViewMenuListItems__mobile) {
			padding: 0px;
			h3 {
				padding: 10px 12px;
				margin-bottom: 0;
				img {
					margin-left: 10px;
				}
			}
		}
		&:not(#scenes) h3 {
			padding: 15px 12px 10px;
			position: relative;
			z-index: 10;
			background-color: white;
			margin-top: -15px;
		}
		&__mobile {
			.scene-chart-graph {
				min-height: 75vh;
			}
		}
	}
}
