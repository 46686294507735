@import '../../global';

.profileScreen__parent {
	position: relative;
}

.profileScreenWrapper {
	background-color: $header-bg-color;
	height: 100%;
	width: 100%;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	flex-direction: row;

	.errorIndication {
		border: 1px solid $default-alert-btn-bg-color !important;
	}

	.successNotificationIndicator {
		color: $customer-primary;
		font-size: $default-font-size;
		font-weight: bold;
	}

	.welcomeMessage {
		position: absolute;
		top: 50px;
	}

	.submitBtn {
		width: 153px;
		height: 38px;
		border-radius: 40px;
		box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
		background-color: $btn-primary-bg-color;
		color: $body-bg-color;
		font-size: $X-small-font-size;
		cursor: pointer;
		margin-bottom: 0;
		letter-spacing: 1px;
		font-weight: lighter;

		&:hover {
			background-color: $btn-primary-bg-color-hover;
		}
	}

	.profileLeftPanelMenu {
		float: left;
		min-height: 90vh;
		display: block;
		padding: 4px 5px 5px 5px;
		border-right: 1px solid #ededed;
		svg {
			width: 15px;
			height: 15px;
			fill: #878cac;
			cursor: pointer;
		}
		.profileIconBlock {
			width: 275px;
			height: 43px;
			cursor: pointer;
			margin-bottom: 5px;
			display: flex;
			border-radius: 2px;
			.thinLineProfileIcon {
				background-color: $thin-left-panel-bg-color;
				width: 5px;
				height: 40px;
				float: left;
			}

			.thickLineProfileIcon {
				background-color: $thin-left-panel-bg-color;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;

				> img {
					width: 16px;
					height: 16px;
					cursor: pointer;
				}
			}
			.profileMenuItem {
				width: 275px;
				height: 43px;
				display: flex;
				padding: 0 16px;
				align-items: center;
				font-size: 12px;
				color: #000000;

				&__expand-icon {
					display: flex;
					width: 36px;
					align-items: center;
					justify-content: center;
					margin-left: 4px;
				}
			}
			.profileMenuItem,
			.profileMenuItem__expand-icon {
				background: #f2f5f7;
				border-radius: 2px;
			}
			&:hover .profileMenuItem,
			&:hover .profileMenuItem__expand-icon {
				background-color: #e5eaed;
			}
		}

		.selectedProfileIconBlock {
			.thinLineProfileIcon {
				background-color: $customer-primary;
			}

			.thickLineProfileIcon {
				background-color: $header-bg-color;
			}

			.profileMenuItem,
			.profileMenuItem__expand-icon {
				background-color: $btn-default-color-active;
				color: #fff !important;
				font-weight: 600;
				svg {
					fill: #fff;
				}
			}
			&:hover .profileMenuItem,
			&:hover .profileMenuItem__expand-icon {
				background-color: $btn-default-color-hover;
			}
		}
		.playerConfiguration-sub-menu {
			padding-top: 0;
			.playerConfiguration-sub-menu__item {
				background-color: #f2f5f7;
				margin: 4px 0;
				border-radius: 2px;
				&:hover {
					background-color: #e5eaed;
				}
				&--selected {
					background-color: #e5eaed;
					span.MuiListItemText-primary {
						font-weight: 600;
					}
				}
			}
		}
	}

	.profileLeftColumn {
		width: 40%;
		height: 95%;

		float: left;
		padding-top: 10%;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		.profileAvatarIcon {
			width: 141px;
			height: 141px;
			box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
			border-radius: 50%;
		}
	}

	.profileRightColumn {
		width: 60%;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 24px;
		padding-right: 24px;
		justify-content: space-evenly;
		padding-top: 90px;

		.profileSectionInputBlock {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: 15px;
			justify-content: flex-start;
			width: 100%;

			.defaultActionBtn {
				min-width: 200px;
			}

			> label {
				font-size: $default-font-size;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				text-align: left;
				color: $default-label-color;
			}

			.profileBlockHeaderTitle {
				font-family: $body-font;
				font-size: $XX-large-size;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				text-align: left;
			}

			.profileConnectedAccounts {
				margin-bottom: 30px !important;
			}

			.genericTextInput {
				height: 30px;
				border-radius: 5px;
				border: solid 1px $gray-links-color;
				padding-left: 10px;
				padding-right: 10px;
				min-width: 250px;
			}

			&.profileSectionPasswordReq {
				margin-top: 20px;
				width: 68%;
				word-break: break-all;
			}

			&:last-child {
				width: 68%;
				margin-top: 20px;
				align-items: flex-start;
			}
		}

		.profileBlockErrorMessage {
			color: #f44352;
			font-size: 12px;
		}
	}

	/* Customized css for the material table view */
	.MuiInput-underline-142:before {
		display: none;
	}
	.MuiFormControl-root-127 {
		vertical-align: baseline;
	}
	.MuiNativeSelect-select-132 {
		padding-right: 20px;
		padding-left: 20px;
		font-size: 12px;
		padding-top: 10px;
	}
	/* Customized css for the material table view */
}

/* Code related to the Custom Properties section */
.customPropertiesContentWrapper {
	width: 100%;
	padding: 25px 26px;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	align-content: flex-start;

	.customPropertiesTopHeader {
		height: 60px;
		display: flex;
		justify-content: space-between;
		width: 100%;

		.plusSign {
			font-size: 25px;
			line-height: 8px;
			margin-right: 10px;
		}

		.customPropertiesBtn {
			box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
		}
	}

	.customPropertiesTableHeading {
		width: 100%;
		display: flex;
		justify-content: space-between;

		> h4,
		> span {
			font-size: $default-font-size;
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 1px;
			text-align: left;
		}

		h4 {
			color: $default-label-color;
		}

		> span {
			position: relative;
			left: -45px;
		}
	}

	.customPropertiesTableWrapper {
		font-size: 1rem;
		line-height: 1.5;
		height: calc(100% - 80px);
	}

	.customPropertiesTableHeader {
		font-weight: 700;
		background-color: transparent !important;
	}

	@media (max-width: 500px) {
		.customPropertiesTableHeader {
			display: none;
		}

		.customPropertiesTableItem {
			justify-content: space-between !important;
		}

		.customPropertiesTableRow {
			width: 85% !important;
		}
	}

	.customPropertiesTableRow {
		border-bottom: solid 1px $table-borders-color;
	}

	.customPropertiesTableRow:hover {
		background-color: #f4f4f4;
	}
	@media (min-width: 500px) {
		.customPropertiesTableRow {
			display: -webkit-box;
			display: -moz-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-flow: row wrap;
			-moz-flex-flow: row wrap;
			flex-flow: row wrap;
		}
	}
	.customPropertiesTableItem {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-flow: row wrap;
		-moz-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-flex-grow: 1;
		-moz-flex-grow: 1;
		flex-grow: 1;
		-ms-flex-positive: 1;
		-webkit-flex-basis: 0;
		-moz-flex-basis: 0;
		flex-basis: 0;
		-ms-flex-preferred-size: 0;
		word-wrap: break-word;
		overflow-wrap: break-word;
		word-break: break-all;
		word-break: break-word;
		height: 65px;
		align-items: center;

		> button {
			cursor: pointer;
			border: none;
			background: transparent;
			width: 16px;
			height: 16px;

			> img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.customPropertiesTableRow .customPropertiesTableItem:last-child {
		flex-grow: 0.29;
		justify-content: space-evenly;
	}
	.customPropertiesTableRow .customPropertiesTableItem:first-child {
		flex-grow: 0.29;
		justify-content: space-evenly;
		margin-right: 8%;
	}

	.customPropertiesTableItem:before {
		content: attr(data-header);
		font-weight: 700;
	}
	@media (min-width: 500px) {
		.customPropertiesTableItem {
			padding: 0.5em;
		}
		.customPropertiesTableItem:before {
			content: none;
		}
	}
	.customPropertiesTableRowCollection:not(.customPropertiesTableHeader) {
		height: calc(100% - 110px);
	}

	.customPropertiesTableItem:before {
		content: attr(data-header);
		font-weight: 700;
	}
	@media (min-width: 500px) {
		.customPropertiesTableItem {
			padding: 0.5em;
		}
		.customPropertiesTableItem:before {
			content: none;
		}
	}
	.customPropertiesTableRowCollection {
		transform: translateY(48px);
		overflow-y: auto;
		scrollbar-gutter: stable;
	}

	.customPropertiesTableRowCollection:hover {
		overflow-y: auto;
		-ms-overflow-style: none; /* IE and Edge */
	}

	.typeDropDown {
		width: 50%;
		height: 20px;
		border-radius: 5px;
		border: none;
		background-color: $header-bg-color;
		cursor: pointer;
		padding-left: 15px;
		color: $primary-filtering-icons-color;

		&:focus {
			outline: none;
		}
	}
}

.editNameInApiProp {
	font-size: 13px;
}

.customPropertiesDialogBoxInternalBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: auto;
	align-items: flex-start;
	justify-content: space-evenly;
	margin-bottom: 10px;

	> label {
		font-size: $default-font-size;
		letter-spacing: 1px;
		color: $default-label-color;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
	}

	> input[type='text'] {
		height: 30px;
		border-radius: 5px;
		border: solid 1px $gray-links-color;
		min-width: 464px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: $default-font-size;
		letter-spacing: 1px;
		width: 100%;
	}

	.alreadyExistsSpan {
		color: red;
	}

	.alreadyExistsInput {
		border: solid 1px red !important;
		color: red;
	}
}

.hideDiv {
	display: none;
}

.customPropertiesMultiSelectDialogBoxInternalBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: auto;
	align-items: flex-start;
	justify-content: space-evenly;
	margin-bottom: 10px;

	> label {
		font-size: $default-font-size;
		letter-spacing: 1px;
		color: $default-label-color;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
	}

	.customPropertiesMultiSelectInput {
		width: 100%;
		display: flex;
		flex-direction: row;
		height: auto;
		align-items: flex-start;
		justify-content: space-evenly;
		margin-bottom: 10px;

		> label {
			font-size: $default-font-size;
			letter-spacing: 1px;
			color: $default-label-color;
			font-weight: 400;
			text-align: center;
			margin-bottom: 10px;
			margin: auto;
		}

		> input[type='text'] {
			height: 30px;
			border-radius: 5px;
			border: solid 1px $gray-links-color;
			min-width: 164px;
			padding-left: 10px;
			padding-right: 10px;
			font-size: $default-font-size;
			letter-spacing: 1px;
			width: 100%;
			margin-left: 5px;
			margin-right: 5px;
		}

		> button {
			margin: auto;
			background-color: $btn-default-color-active; /* Green */
			border: none;
			color: white;
			padding: 7px 10px;
			text-align: center;
			text-decoration: none;
			display: inline-block;
			font-size: 14px;
			border-radius: 5px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.optionsLabel {
		width: 100%;
		border-bottom: solid 1px $gray-links-color;
		text-align: left;
		margin-bottom: 0px;
	}

	.multiSelectTableRow {
		width: 100%;
		display: grid;
		grid-template-columns: 120px 180px 10px;
		grid-gap: 10px;
		height: 33px;
		align-items: center;
		justify-content: space-between;
		padding-right: 12px;
		border-bottom: solid 1px $gray-links-color;

		.deleteIconMultiSelect {
			border: none;
			background-color: transparent;
			display: flex;
			align-items: center;
		}

		.deleteIconMultiSelect:hover {
			cursor: pointer;
		}
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.multiSelectTableRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

/* Code related to the User licences section */
.licencesContentWrapper {
	width: 100%;
	padding: 25px 26px;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	align-content: flex-start;

	.usersListingTopHeader {
		height: 60px;
		display: flex;
		justify-content: space-between;
		width: 100%;

		.searchTextInput {
			background-image: url('../../assets/images/search.svg');
			background-repeat: no-repeat;
			background-position: 10px center;
			width: 228px;
			height: 37px;
			border-radius: 5px;
			border: solid 1px $text-inputs-border-color;
			background-size: 16px 16px;
			padding-left: 35px;
			padding-right: 25px;
			font-size: $default-font-size;
			letter-spacing: 1px;
		}

		.newUserBtn {
			box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
		}
	}

	.usersListingTableHeader {
		height: 60px;
		width: 100%;
		display: flex;
		justify-content: space-between;

		> h4,
		> span {
			font-size: $default-font-size;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 1px;
			text-align: left;
		}

		h4 {
			color: $default-label-color;
		}

		> span {
			position: relative;
			left: -45px;
		}
	}

	.paginatedTableWrapper {
		width: 100%;
		height: auto;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px $gray-links-color;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background: $btn-primary-bg-color;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: $btn-primary-bg-color;
		}

		.tableContentSingleWrapper {
			width: 100%;
			height: 65px;
			display: flex;
			border-top: solid 1px $table-borders-color;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-evenly;
			color: $body-table-font-color;
			font-size: $default-font-size;
			letter-spacing: 1px;

			> div {
				display: flex;
				align-items: center;
				flex-direction: row;
				height: 100%;

				&:first-child {
					width: 5%;
				}
				&:nth-child(2) {
					width: 10%;
					justify-content: flex-start;

					> img {
						width: 48px;
						height: 48px;
						border-radius: 50%;
						margin-right: 20px;
					}

					> button {
						cursor: pointer;
						border: none;
						background: transparent;
						margin-top: 7px;
					}
				}
				&:nth-child(3) {
					width: 80%;
					cursor: pointer;
					h3 {
						max-width: 75%;
						@include textEllipsis;
					}
				}
				&:nth-child(4) {
					width: 5%;

					> button {
						cursor: pointer;
						border: none;
						background: transparent;
						width: 16px;
						height: 16px;

						> img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.endOfTableContent {
			border-bottom: solid 1px $table-borders-color;
		}

		.userDetailsView {
			width: 100%;
			height: 330px;
			background-color: $header-bg-color;
			position: relative;
			top: -1px;

			.userDetailsViewInternalBlock {
				width: 100%;
				display: flex;
				flex-direction: column;
				height: 50px;
				align-items: flex-start;
				justify-content: space-evenly;
				margin-bottom: 10px;

				> label {
					font-size: $default-font-size;
					letter-spacing: 1px;
					font-weight: bold;
				}

				> input[type='text'] {
					height: 30px;
					border-radius: 5px;
					border: solid 1px #bfbfbf;
					padding-left: 10px;
					padding-right: 10px;
					font-size: $default-font-size;
					letter-spacing: 1px;
					min-width: 300px;
				}

				&:nth-child(5) {
					margin-bottom: 0;
				}
			}
		}
	}

	.paginationContentWrapper {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;

		> div {
			&:first-child {
				width: 15%;
				align-items: center;
				display: flex;
				flex-direction: row;

				> span {
					color: $default-label-text-color;
					font-size: $default-font-size;
					letter-spacing: 1px;
				}
			}
			&:nth-child(2) {
				width: 15%;
				align-items: center;
				display: flex;
				flex-direction: column;

				> span {
					color: $default-label-text-color;
					font-size: $default-font-size;
					letter-spacing: 1px;
				}
			}
			&:last-child {
				width: 15%;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;

				> button {
					height: 10px;
					width: 14px;
					border: none;
					cursor: pointer;
					background: transparent;

					> svg {
						width: 100%;
						height: 100%;
						fill: $body-table-font-color;
					}

					&:disabled {
						> svg {
							fill: $gray-links-color;
						}
					}
				}
			}
		}
	}
}

.dialogBoxInternalBlock {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: auto;
	align-items: flex-start;
	justify-content: space-evenly;
	margin-bottom: 10px;

	> label {
		font-size: $default-font-size;
		letter-spacing: 1px;
		color: $default-label-color;
		font-weight: bold;
		text-align: center;
	}

	> input[type='text'] {
		height: 30px;
		border-radius: 5px;
		border: solid 1px $gray-links-color;
		min-width: 464px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: $default-font-size;
		letter-spacing: 1px;
		width: 100%;
	}
}

.newUserHeader {
	font-family: $body-font;
	font-size: $XX-large-size;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
}

.deletePropertyHeader {
	margin-top: 40px;
	margin-bottom: -30px;
	font-family: $body-font;
	font-size: $XX-large-size;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
}

.createNewUserBtnContentWrapper {
	align-items: center;
}

.deleteUserBtn {
	background-color: $default-alert-btn-bg-color !important;
	color: $header-bg-color !important;
}

.deleteUserBtnContentWrapper {
	flex-direction: row;
	height: auto;
}

.dialogTitleContentWrapper {
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
}

.dialogActionsWrapper {
	width: 100%;
}

.dialogContentMidSectionWrapper {
	display: flex;
	flex-direction: row;
}

.customPropsDialogText {
	max-width: 450px;
	font-size: 12px;
}

.customPropertiesDialogLabel {
	font-size: $default-font-size;
	letter-spacing: 1px;
	color: $default-label-color !important;
	font-weight: bold;
	text-align: left;
}

.customPropertiesLabel {
	font-size: 13px;
	color: #000;
}

.typeDropDownWrapper {
	min-width: 40px;
	height: 32px;
	overflow: hidden;
	border: none;
	display: flex;
	padding: 5px 0;
	margin-right: 0;
	align-items: center;
	border-radius: 5px;
	border: solid 1px $gray-links-color;
	cursor: pointer;
	width: 220px;
	margin-right: 30px;
	margin-bottom: 20px;
	margin-top: 10px;

	.typeDropDown {
		width: 100%;
		height: 20px;
		border-radius: 5px;
		border: none;
		background-color: #ffffff;
		cursor: pointer;
		padding-left: 15px;
		color: #6c7473;
	}
}

.requiredCheckBoxWrapper {
	min-width: 40px;
	height: 32px;
	border: none;
	margin-right: 0;
	cursor: pointer;
}
.mediaPerPageDropDownSelectBox {
	width: 65px;
	overflow: hidden;
	border: none;
	position: relative;

	&:after {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $media-item-header-bg-color;
		position: absolute;
		top: 48%;
		right: 10px;
		content: '';
		z-index: 98;
	}

	> select {
		width: 56px;
		border: 0;
		position: relative;
		z-index: 99;
		border: none;
		background-color: transparent;
		margin-left: 5px;
		color: $media-item-header-bg-color;
		cursor: pointer;
		background: none;

		&:focus {
			outline: none;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.profileScreenWrapper {
		.profileLeftPanelMenu {
			flex-wrap: wrap;
			min-height: auto;
			.profileIconBlock {
				width: auto;
				&:not(.profileIconBlock__playerConfiguration) .profileMenuItem {
					width: auto;
				}
				&.profileIconBlock__playerConfiguration {
					flex-basis: 100%;
					width: 100% !important;
					.profileMenuItem {
						flex: 1;
					}
				}
			}
		}
	}

	#screenParentContainer.mobileView .profileScreenWrapper {
		overflow: auto;
		.profileLeftPanelMenu {
			flex-wrap: wrap;
		}
	}
}
