@import "../../../../../global";

#accessRestrictionSection {
  .panel {
    overflow: visible !important;
  }

  .dateField {
    height: 30px;
    width: 130px;
    border-radius: 5px;
    box-shadow: none;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    margin-top: 5px;
  }

  .react-datepicker-popper {
    z-index: 10000;
  }
  .dateFieldContainer {
    position: relative;
    float: left;
    margin-right: 10px;
  }
  .timeFieldContainer {
    position: relative;
    float: left;
  }
  .calender_icon {
    right: 0;
    top: 0;
    position: absolute;
    padding: 8px;
    padding-top: 8px;
    padding-right: 8px;
  }
  .timeField {
    height: 30px;
    width: 85px;
    border-radius: 5px;
    box-shadow: none;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    margin-top: 5px;
  }
  .date_time_container {
    //   margin-top: 10px !important;
    margin-bottom: 5px;
    height: 35px;
  }
  #date_picker_from {
    height: 45px !important;
  }
  #enable_unpublished_section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  input[type="text"] {
    padding: 10px;
  }
  hr {
    color: #f5f5f5;
    opacity: 0.5;
  }
  .hide {
    display: none;
  }

  label {
    text-align: left !important;
    display: block;
    font-weight: 600;
    color: #000 !important;
    &.unpublishTime_checkbox {
        background-color: #fff;
        border: 1px solid #bfbfbf;
        border-radius: 5px;
        cursor: pointer;
        height: 18px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
        &:after {
            position: absolute;
            }
        }
    }

  label.timezone {
    margin-top: 5px;
    font-weight: normal;
  }
  label.headers {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #000 !important;
  }
  label.headerRegular {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #000 !important;
  }
  #date_picker_from > div {
    margin-top: 0px !important;
  }
  label.range {
    font-size: 12px !important;
    padding-top: 35px;
    width: 15px;
  }
  .tabContent {
    margin-top: 30px;
  }
  .genericDropDown {
    border: solid 1px #ccc;
  }
  .tabHeader {
    color: #000;
    font-size: 14px !important;
    text-align: right;
  }
  #full-width-tab-2 {
    width: 48%;
    border-bottom: 1px solid #ccc;
    text-transform: none;
  }
  #full-width-tab-1 {
    width: 48%;
    border-bottom: 1px solid #ccc;
    text-transform: none;
  }
  #new_rule_name {
    border: solid 1px #ccc;
  }

  .dateSettings {
    display: block;
  }
  .unPublishTimeSection {
    .round {
      position: relative;
      left: 0;
      z-index: 0;
      span {
        font-size: 12px;
        float: left;
        margin-left: 30px;
        margin-top: 3px;
      }
    }

    .round + label {
      background-color: $header-bg-color;
      border: 1px solid $checkboxes-border-color;
      border-radius: 5px;
      cursor: pointer;
      height: 20px;
      left: 0;
      top: 0;
      width: 20px;
    }

    .round label:after {
      border: 2px solid $header-bg-color;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 3px;
      opacity: 0;
      top: 5px;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round input[type="checkbox"] {
      visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
      background-color: $checkboxes-highlighted-color;
      border-color: $checkboxes-highlighted-color;
    }

    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }

  .restrictionRuleWrapper {
    .sortingDropDownWrapper {
      width: 40%;
      .sortingDropDown {
        width: 100%;
      }
    }
  }

  .clickRuleInfo {
    color: #b7b7b7;
    font-size: 12px;
    hr {
      color: #b7b7b7;
      margin-top: 10px;
      width: 300px;
    }
  }
  .ruleInfo {
    color: #b7b7b7;
    font-size: 14px;
  }

  .appliedRulesList,
  .inheritedRulesList,
  .ipSets {
    max-width: 400px;
    /*max-height: 200px;
        overflow: auto; */
    .ruleChip:focus {
      // background-color: black !important;
    }
  }

  .ruleButton {
    display: inline-block;
    width: 500px;
    .rulesRadioButtons {
      float: left;
    }
    .ruleChip {
      width: 250px;
      float: left;
    }
  }

  .appliedRulesList {
    .ruleChip {
      background-color: #9ed9e5;
    }
    .ruleChip.selectedRule {
      background-color: #126cfc;
    }
    .rulesRadioButtons {
      color: #126cfc;
    }
  }

  .inheritedRulesList {
    .rulesRadioButtons {
      color: #9d9d9d;
    }
    .ruleChip.selectedRule {
      background-color: #9d9d9d;
      .deleteIcon {
        background-color: #9d9d9d;
      }
    }
  }

  .ruleChip {
    cursor: pointer;
    border: none;
    min-width: 150px;
    margin: 8px;
    margin-left: 0px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;

    img,
    svg {
      display: block;
      margin: 0 auto;
      margin-top: 9px;
      height: 10px;
      width: 10px;
    }
    .deleteIcon {
      width: 35px;
      height: inherit;
      float: left;
      border-radius: 5px 0px 0px 5px;
    }
    label {
      cursor: pointer;
      margin-left: 10px;
      float: left;
      color: white;
      font-weight: normal;
      max-width: 200px;
      @include textEllipsis;
    }
  }
  .ruleChip {
    background-color: #adb6b5;
    .deleteIcon {
      background-color: #9d9d9d;
    }
  }
  .ruleChip.selectedRule {
    background-color: #126cfc;
    .deleteIcon {
      background-color: #19a4c1;
    }
  }

  .rulesListSection,
  .ruleDisplaySection {
    float: left;
  }
  .ruleDisplaySection {
    width: 100%;
  }

  .rulesListSection {
    max-height: 500px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $body-bg-color-after-login;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $default-label-text-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $body-table-font-color;
    }
  }

  .ipRangeRule,
  .tokenRule {
    label {
      margin-bottom: 10px;
    }
    ul {
      position: relative;
    }
  }

  .tokenRule {
    display: block;
  }

  .ipRangeRule {
    .ipAddressInputSection {
        display: flex;
        align-items: center;
        width: 100%;
        div {
            display: flex;
            flex-direction: column;
        }
        .ipAddressToInput,
        .ipAddressFromInput {
            max-width: 35%;
        }

      .ipaddressField {
        margin-right: 10px;
      }
      .ipaddressField.from {
        margin-left: 0px !important;
      }
    }
  }

  .tokenField,
  .ipaddressField {
    border: none; /* <-- This thing here */
    border: solid 1px #ccc;
    border-radius: 5px;
  }
  .tokenField {
    // width: 500px;
  }

  .accessActionButtons {
    .updateButton,
    .saveButton,
    .clearDatesButton,
    .deleteButton,
    .cancelButton,
    .addButton {
      // width: 130px;
      height: 35px;
      border-radius: 0px !important;
      box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.16);
      background-color: $btn-primary-bg-color !important;
      color: #ffffff !important;
      letter-spacing: 1px !important;
      text-transform: none !important;
      font-size: 14px !important;
      float: left;
      margin-right: 10px;

      // padding-left: 20px;
      // padding-right: 20px;
      padding: 6px 16px;
      min-width: 130px !important;
      max-width: 250px;
    }

    .fullWidthButton {
      min-width: 150px;
    }

    .updateButton {
      background-color: white !important;
      border: 1px solid $btn-primary-bg-color !important;
      color: $btn-primary-bg-color !important;
      font-weight: 600;
    }

    .deleteButton {
      background-color: white !important;
      //border: 2px solid $default-alert-btn-bg-color !important;
      color: $default-alert-btn-bg-color !important;
      //font-weight: 600;
      cursor: pointer !important;
      &:hover {
        background-color: $default-alert-btn-bg-hover-color !important;
        //border: 2px solid $default-alert-btn-bg-hover-color !important;
        color: #ffffff !important;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
        svg {
          fill: #ffffff;
        }
      }
      &:disabled {
        //border: 2px solid rgba(253, 94, 107, 0.40) !important;
        color: rgba(253, 94, 107, 0.4) !important;
        svg {
          fill: rgba(253, 94, 107, 0.4);
        }
      }
      svg {
        fill: $default-alert-btn-bg-color;
        margin-right: 10px;
      }
    }

    .cancelButton {
      border: 2px #383838 solid;
      background-color: white !important;
      color: #383838 !important;
      &:hover {
        // background-color: #FF6347 !important;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      }
    }
    .saveButton {
      background-color: $btn-default-color-active !important;
      &:hover {
        // background-color: #FF6347 !important;
        background-color: $btn-default-color-hover !important;
        color: #fff !important;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      }
      &:disabled {
        background-color: #b2c1ea !important;
      }
    }

    .clearDatesButton {
      background-color: $btn-default-color-active !important;
      &:hover {
        // background-color: #FF6347 !important;
        background-color: $btn-default-color-hover !important;
        color: #fff !important;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      }
      &:disabled {
        background-color: #b2c1ea !important;
      }
    }

    img {
      float: left;
      width: 30x;
      height: 30px;
    }

    .centerButton {
      float: none !important;
    }

    .addButton {
      border-radius: 5px !important;
      width: auto;
      float: inherit;
    }
    .ipAdd {
      &:hover {
        // background-color: #FF6347 !important;
        background-color: $btn-default-color-hover !important;
        color: #fff !important;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
      }
      height: 35px;
      border-radius: 5px !important;
      box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.16);
      background-color: $btn-default-color-active !important;
      color: #ffffff !important;
      font-size: 14px !important;
      letter-spacing: 1px !important;
      text-transform: none !important;
      float: left;
      // margin-right: 10px;
      padding: 6px 10px;
    }
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background: url("./../../../../../assets/images/tree_plus.svg") no-repeat;
    background-color: #f7f7f7;
    background-position: right;
    background-size: 1em;
    background-origin: content-box;
    color: #444;
    cursor: pointer;
    padding: 18px;
    padding-left: 10px;
    width: inherit;
    text-align: left;
    border: none;
    outline: none;
    transition: display 0.2s ease-out;
  }

  .accordion.active {
    cursor: pointer;
    background: url("./../../../../../assets/images/tree_minus.svg") no-repeat;
    background-color: #f7f7f7;
    background-position: right;
    background-size: 1em;
    -moz-background-size: contain;
    background-origin: content-box;
    color: #444;
    background-color: #f7f7f7;
    transition: display 0.2s ease-out;
  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    background-color: white;
    display: block;
    overflow: hidden;
    label {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .accordion:after {
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
  }

  .centerElements {
    text-align: center;
  }

  .notAllowedInputText {
    height: 20px;
    font-size: 12px;
    font-style: italic;
    color: red;
  }
}
