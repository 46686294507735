@import './../AnalyticsCommon';

.analyticsBody {
    .pageTitle {
        color: #3b3b3b;
        font-weight: 600;
        font-size: 18px;
    }
    .mainSec{
        display: block;
        p , a {
            font-size: 1rem;
            margin: 0;
        }
    }

}


