

@import "../../../../../../global";


#CaptureThumbnailDialog {
    label {
        color: black;
    }
    .dialogTitleNewMediaContentWrapper {
        padding-top:0px;
        //padding-bottom:10px;
    
    }
    #infoTitle {
        label {
            color: black;
            font-weight: 600;
            font-size: 18px;
        }
    }

    table.infoTable {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        tr {
            margin: 10px;
            border: 1px solid $media-content-border-color;
        }
        td {
            color: black;
            padding: 8px;
            width: 150px;
        }

        td:nth-child(odd)  {
            font-weight: 600;
        }
    } 

    img.imgIcons {
        cursor: pointer;
        width: 15px;
        height: 15px;
    }
    a {        
        img,
        span {
            cursor: pointer;
            float: left;
            margin-right: 5px;
            font-weight: normal;
        }
    }

    textarea {
        resize: none;
      }


      .thumbnailImgWrapper {
        height: 100px;

        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
       // border: 1px solid #e8e8e8;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        background-color: transparent !important;

        > img {
            max-width: 100%;
            max-height: 100%;
            display: flex;
            margin: 0 auto;
            vertical-align: middle;
        }
    }
    
    .cameraSvg {
        width: 20px;
        height: 20px;
        margin-top: 1px;
        margin-right: 10px;
        margin-left: 3px;
        path { fill: #FFF; } 
    }

    .captureThumbnailGallery{
        min-height: 115px; 
        border-radius: 5px;
        
    }

    .secondaryTitleCaptureThumbnailDialog{
        font-size: 14px;
    }

    .videoPlayerCaptureThumbnail{
        margin-top: 8px;
    }
    
}
