.qna-container {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__header,
	&__prompt {
		min-height: 45px;
		padding: 0px 12px 0px 0px !important;
		justify-content: center;
		flex-basis: auto !important;
	}

	&__header {
		border-bottom: 1px solid #1a1c26 !important;
		.MuiFormControlLabel-label {
			flex: 1 !important;
		}
	}

	&__prompt {
		min-height: 70px;
		margin-bottom: 12px;

		.MuiInput-underline:before,
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom-color: #ffffff99;
		}
		.MuiFormControlLabel-label {
			color: #ffffff99;
			display: block;
			width: 100%;
			margin-bottom: 5px;
		}
	}

	&__panel {
		padding: 0px 12px;
		overflow-y: auto;
	}

	&__question {
		background-color: #373c4a;
		padding: 12px;
		margin: 5px 5px 0px 5px;
		border-radius: 4px;

		&--message {
			color: #ffffff99;
			text-align: center;
			padding: 24px;
		}

		&-sender {
			margin-right: 5px;
		}

		&-time {
			font-size: 8px;
		}

		&-title {
			display: flex;
			align-items: center;
			div:first-child {
				flex: 1;
				margin-right: 5px;
				svg {
					margin-right: 5px;
				}
			}
		}

		&-content {
			font-size: 11px;
			margin-top: 5px;
		}

		&-actions {
			display: flex;
			justify-content: space-between;
			margin-top: 12px;
			min-height: 35px;
		}

		&-rank {
			display: inline-flex;
			align-items: center;
			svg {
				margin-right: 5px;
			}
		}

		&--answering {
			background-color: #323641;
			height: 70px;
			width: 100%;
			border-radius: 0px;
			margin: 0;
			box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
			.qna-container__question-content {
				color: #ffffff99;
				max-width: 300px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&--archive-btn {
			border-radius: 4px !important;
			background-color: #2a2e38 !important;
			box-shadow: 0px 3px 3px #0000005c;
			height: 30px;
		}
	}
}
