.list-media-display {
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: #f7f8fa;
	border-top: 1px solid rgba(0, 0, 0, 0.12);

	&:not(.library-main-display) table {
		display: block;
		height: 100%;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		background-color: #fff;

		th {
			font-size: 12px;
			line-height: 17px;
			text-align: left;
		}

		tr {
			border-top: 1px solid rgba(0, 0, 0, 0.12);
			&:last-child {
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			}
			&:first-child {
				border-top: none;
			}
		}

		tr.list-media-display__header th {
			padding: 10px 0px;
			background-color: white;
		}

		tr:not(.list-media-display__header),
		td.media-item-container {
			height: 75px;
		}

		.list-media-display__right-align {
			text-align: right;
		}
	}
}

.media-item-container {
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}

	&__main {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 100%;

		&-tr {
			padding: 0 5px;
		}
		&--processing {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #000000;
			color: #ffffff;
			font-size: 8px;
			width: 100%;
			height: 100%;
		}
	}

	&__thumbnail,
	&__main--processing {
		width: 75px;
		border-radius: 4px;
	}

	&__thumbnail {
		margin-right: 10px;
		&:not(:empty) {
			height: 42px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
			border-radius: 4px;
			background: #e5e9ec;
			&.media-item-container__main-no-thumbnail {
				object-fit: none;
			}
		}
	}

	&__title {
		display: block;
		font-weight: 600;
		margin-top: 5px;
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 12px;
	}

	&__type,
	&__date,
	&__title {
		font-size: 12px;
		line-height: 17px;
	}

	&__index {
		width: 8%;
		max-width: 100px;
	}

	&__action {
		width: 10%;
		max-width: 100px;
	}
}

body[screen-type='mobile'],
body[screen-type='small'] {
	.media-item-container__title {
		max-width: 150px;
	}
}
