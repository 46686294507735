.dashboard {
	overflow: auto;
	height: calc(100% - 45px);

	&--shadow {
		box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
		border-radius: 10px !important;
		border: 1px solid #e5e9ec !important;
	}
	&--mobile {
		.dashboard__container {
			padding: 12px;
		}
	}
	&__media-grid {
		padding: 0px;
		background-color: transparent;
	}
	&__container {
		padding: 20px;
		&-wrapper {
			min-height: calc(100% - 50px);
		}
		iframe {
			width: 100%;
			height: 100%;
			min-height: 250px;
		}
		&-medias {
			flex-direction: column;
			position: relative;
			padding-bottom: 40px;
			&:hover {
				background-color: #f7f8fa;
			}

			&__message-container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				border-radius: 4px;
				border: 1px dashed #cdd4d9;
			}
			&__main-message,
			&__sub-message {
				font-style: normal;
				color: #909ca5;
			}
			&__main-message {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 12px;
			}
			&__sub-message {
				font-size: 12px;
				font-weight: 400;
			}
		}
		&-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 12px;
			min-height: 40px;
		}
		&-text {
			font-size: 12px;
			margin-bottom: 12px;
		}
		&-grid {
			display: grid;
			column-gap: 10px;
			grid-template-columns: repeat(5, minmax(0, 1fr));
			> div {
				background-color: #126cfc1a;
				padding: 20px;
			}
		}
		&-action {
			display: flex;
			align-items: center;
		}
		&--footer {
			padding: 24px 24px 40px;
			font-size: 12px;
			color: #909ca5;
			width: 100%;

			a {
				color: inherit;
				cursor: pointer;
				&:last-child {
					margin: 0;
				}
				&:not(:last-child) {
					padding-right: 1rem;
					margin: 0;
				}
				&:not(:first-child) {
					padding-left: 1rem;
				}
				&.dashboard__blue-link {
					color: #126cfc;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.dashboard__left,
			.dashboard__right {
				text-wrap: nowrap;
			}
		}
	}
	.dashboard__left,
	.dashboard__right {
		cursor: default;
	}

	&__link {
		font-weight: normal;
		font-size: inherit;
		color: #126cfc;
		cursor: pointer;
		margin-right: 5px;
		&:hover {
			text-decoration: underline;
		}
	}

	&__action {
		&-item {
			position: relative;
			background: #fff;
			padding: 16px !important;
			font-size: 12px !important;
			text-align: left;
			justify-content: flex-start !important;
			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&--locked:hover .dashboard__action-locked-layer {
				visibility: visible;
			}
		}
		&-locked-layer,
		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}
		&-locked-layer {
			position: absolute;
			background-color: #e5e9ecb2;
			border: 1px solid #e5e9ecb2;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			visibility: hidden;
		}
		&-icon {
			padding: 10px;
			margin-right: 10px;
		}
	}

	&__upsales-form {
		font-size: 12px;
		margin: 12px 24px 40px;
		label {
			font-size: 12px !important;
			text-align: left;
			margin: 12px 0px 8px 0;
			min-width: 150px;
			flex-basis: calc(100% / 3);
			span.required-mark {
				color: #f44352;
			}
		}
		input {
			padding: 12px;
		}
		.dashboard__features {
			display: flex;
			flex-wrap: wrap;
			text-align: left;
			margin-left: -4px;
			label {
				margin: 0;
			}
			&--excluded {
				display: flex;
				padding: 8px;
				color: rgba(0, 0, 0, 0.38);
			}
		}
	}
}

body[screen-type='tablet'] .dashboard__container-grid {
	grid-template-columns: repeat(3, minmax(0, 1fr));
	.dashboard__action-item {
		padding: 10px !important;
		.MuiButton-label {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 10px;
			text-align: center;
		}
	}
}
body[screen-type='small'],
body[screen-type='mobile'] {
	.dashboard__container:not(.dashboard__container--footer) {
		display: block;
		> div:not(:first-child) {
			margin-top: 12px;
		}
	}
	.dashboard__container-grid {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

body[screen-type='mobile'] {
	.dashboard__container-wrapper {
		min-height: calc(100% - 100px);
	}
	.dashboard__container--footer {
		display: block;
		padding: 40px 24px;
		.dashboard__left {
			margin-bottom: 24px;
		}
		.dashboard__left,
		.dashboard__right {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			> * {
				flex: 1;
				margin: 0 0 4px 0;
				padding: 0;
				&:last-child {
					margin: 0 0 12px 0;
				}
			}
		}
	}
}

body[screen-type='tablet'],
body[screen-type='large'],
body[screen-type='medium'] {
	.dashboard__container {
		display: flex;
	}
}

body[screen-type='tablet'],
body[screen-type='large'],
body[screen-type='medium'],
body[screen-type='small'] {
	.dashboard__container {
		display: flex;
		&.dashboard__container--footer {
			justify-content: space-between;
			a:not(:last-child) {
				border-right: 1px solid #e5e9ec;
			}
			.dashboard__left {
				margin-right: 12px;
			}
			.dashboard__right {
				text-align: right;
				flex: 1;
			}
		}
		&:not(.dashboard__container--footer) {
			.dashboard__left {
				min-width: 25%;
				margin-right: 20px;
			}
			.dashboard__right {
				flex: 1;
			}
		}
	}
}
