.inspect-media {
	&__container {
		display: flex;
		flex-direction: row;
		height: calc(100% - 50px);
		.inspect-media__tab-bar button {
			max-width: 80px;
			min-width: 80px;
			max-height: 80px;
			min-height: 80px;
		}

		.inspect-media__general-info-container {
			flex: 1;
		}
	}

	.inspect-media__detail-container {
		width: calc(70% - 100px);
		overflow-x: hidden;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		border-left: 1px solid rgba(229, 233, 236, 1);
		.inspect-media__input--full .MuiFormControlLabel-label {
			width: 30%;
		}
		.MuiFormControl-root:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(
				.MuiTablePagination-input
			) {
			flex-basis: auto !important;
		}
		&--section {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			flex: 1;
			overflow-y: auto;
		}
	}
	.inspect-media__player-container {
		height: 60%;
		max-height: 60%;
		min-height: 40%;
		> div {
			margin: auto;
		}
	}

	#inspectViewLivePlayer {
		min-width: 350px;
		width: 30%;
		overflow-y: auto;
	}
}

.inspect-media__container,
.inspect-media-mini__container {
	.player-preview__embed-setting {
		background-color: #ffffff;
	}
	.MuiTab-textColorInherit {
		opacity: 1;
		font-size: 12px;
	}

	.player-preview__player-setting-section,
	.player-preview__embed-section {
		padding: 12px;

		.player-preview__embed-code {
			border: 1px solid rgb(229, 233, 236);
		}
	}
}

.inspect-media-mini {
	&__container {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
		min-width: 500px;
		max-width: 50%;
		box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.1);
		#inspectViewLivePlayer {
			transition: all 0.3s ease-in;
		}
	}

	&__player-container {
		padding: 0 12px 12px;
		height: 300px - 12px;
		width: calc((300px - 12px) * 16 / 9);
		margin: auto;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		font-weight: 500;
		padding: 0 12px;
		font-weight: 600;
	}

	&__media-content-container {
		display: flex;
		flex: 1;
		border-top: 1px solid rgba(229, 233, 236, 1);
		border-bottom: 1px solid rgba(229, 233, 236, 1);
	}

	&__tab-bar {
		padding: 12px;
		border-right: 1px solid rgba(229, 233, 236, 1);

		button {
			box-sizing: border-box;
			border-radius: 4px;
			max-width: 64px;
			min-width: 64px;
			max-height: 64px;
			min-height: 64px;
			margin-bottom: 5px;
			.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
				margin: 0;
			}
		}
		svg {
			width: 24px;
			height: 24px;
		}
	}
	&__actions {
		padding: 12px;
		text-align: right;
	}
}
