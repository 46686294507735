@import './../global.scss';
@import './../assets/styles/main.scss';

#root {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	> span {
		display: block;
		height: 100%;
		> div:last-child:not(.profileSettings__wrapper) {
			height: 100%;
		}
	}
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
	* {
		font-weight: normal !important;
	}
}

.icon--collapsed {
	transform: rotate(-90deg);
}

.hidden-oidc-iframe {
	display: none;
}
/* General css */
.mobileLanguageList .MuiMenu-paper-1 {
	left: auto !important;
	right: 10px;
}
/* General css */
#topMenuSystem {
	z-index: 5000000000;
	a {
		color: black;
	}
	img,
	svg {
		fill-rule: evenodd;
		margin-right: 15px;
	}
}
div[role='presentation'] {
	width: 100%;
	z-index: 50000 !important; //added to avoid dialog boxes appear behind video
	li:not(.treeCatalogItem) {
		min-height: 45px;
	}
	li:active {
		background-color: $link-color !important;
	}
}
div[role='dialog'] {
	z-index: 50000; //added to avoid dialog boxes appear behind video
}
.preview-interact-modal div[role=dialog]  {
	background: transparent;
    box-shadow: none;
	margin-bottom: 50px;
	.MuiDialogTitle-root {
		padding: 0;
		height: 50px;
	}
}
.inspectSnackBar,
.snackbar {
	z-index: 50001 !important;
}
#navDrawerOpen > div,
#navDrawerClose > div {
	background-color: $navigation-panel-bg-color !important;
}
#navDrawerOpen > div:hover,
#navDrawerClose > div:hover {
	background-color: #1f2233 !important;
}
/* Customized css for the loading container overlay*/
.css-df17o1 {
	position: fixed !important;
}
/* Customized css for the loading container overlay*/
.MuiPopover-paper-155 {
	position: absolute;
	width: 175px;
	right: 0;
	left: calc(100% - 240px) !important;
}
._loading_overlay_wrapper {
	height: auto;
}

.addClosedNavWidth,
.addOpenNavWidth {
	-webkit-transition: padding-left 0.3s; /* For Safari 3.1 to 6.0 */
	transition: padding-left 0.3s;
	padding-left: $navigation-panel-closed-width;
	// padding-left: $navigation-panel-closed-width-media;
	overflow-y: auto;
	overflow-x: hidden;
}

/*.addOpenNavWidth {
    -webkit-transition: padding-left 0.3s;
    transition: padding-left 0.3s;
    padding-left: $navigation-panel-closed-width;//added to not move elements
   // padding-left: $navigation-panel-open-width;
}*/

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: transparent url('./../assets/images/expanded_black.svg') no-repeat 0.2em center;
	-webkit-background-size: 1em 1em;
	background-size: 1em;
	background-position: right;
	-moz-background-size: contain;
	background-origin: content-box;
	padding-right: 10px;
	font-family: inherit;
}

.settingsList {
	select {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		background: transparent url('./../assets/images/expanded.svg') no-repeat 0.2em center;
		-webkit-background-size: 1em 1em;
		background-size: 1em;
		background-position: right;
		-moz-background-size: contain;
		background-origin: content-box;
		padding-right: 10px;
		padding-left: 5px;
	}
}

.componentOverlay {
	position: absolute !important;
	height: 100%;
	width: 100%;
	top: 0px;
	> div {
		position: relative;
	}
}

._loading_overlay_content > span {
	display: block;
}

.mosaic {
	background-color: inherit !important;
	.mosaic-split.-row {
		width: 6px !important;
	}
	.mosaic-split {
		background: #000000;
	}
	.mosaic-window {
		border-radius: 0px !important;
		box-shadow: none !important;
	}
	.mosaic-window-body {
		@include addWhiteScrollBar;
		overflow-y: auto;
		background-color: inherit !important;
	}
	.mosaic-window-toolbar,
	.mosaic-window-title {
		padding-left: 5px;
		padding-right: 5px;
		cursor: move;
		color: #ffffff !important;
		background-color: $live-interact-header !important;
		font-size: 12px;
		&:hover {
			background: none !important;
			background-color: $live-interact-header !important;
		}
		label {
			cursor: move;
		}
	}
	.close-button,
	.mosaic-preview,
	.expand-button {
		display: none;
	}
	.expand-button {
		height: 16px !important;
		width: 16px !important;
		background: url('../assets/images/expanded_white.svg');
		background-repeat: no-repeat;
	}
	.customToolbar {
		width: 100%;
	}
	.mosaic-split.-row {
		width: 2px;
	}
}

body {
	&[dark-mode='true'] {
		$textcolor: #fff;
		background-color: $movable-grid-color !important;
		.mainMenuTitle {
			color: #fff !important;
		}
		.breadcrumbMenuIcon {
			&:hover {
				box-shadow: none !important;
				background-color: $dark-mode-element-hover;
			}
		}
		.authenticatedHeader {
			margin-bottom: 0px;
			background-color: $live-interact-header;
			.breadCrumbMenuBar .folderSeparator {
				color: $textcolor;
			}
			.uploadButton {
				.upicon {
					fill: $textcolor;
				}
				&:hover {
					background-color: $dark-mode-element-hover !important;
				}
			}
			.openNavBarWrapper {
				svg .menu_icon_b {
					fill: $textcolor;
				}
				&:hover {
					background-color: $dark-mode-element-hover !important;
					svg .menu_icon_b {
						fill: $textcolor;
					}
				}
			}
			.profileMenuActive {
				background-color: $dark-mode-element-hover;
			}
			#loggedEmailDisplayBlock {
				label {
					color: $textcolor;
				}
				.headerIcon > svg {
					fill: $textcolor;
				}
			}

			#loggedEmailDisplayBlock {
				&:hover {
					background-color: $dark-mode-element-hover;
					.headerIcon > svg {
						fill: $textcolor;
					}
				}
			}

			label {
				color: $textcolor;
			}
			.breadCrumbHeader {
				color: $textcolor;
			}
			#breadCrumbPaper {
				background-color: inherit;
				#breadCrumbGroup li:last-child a {
					color: $textcolor !important;
					font-weight: 600;
				}
				li[aria-hidden='true'] {
					color: $textcolor;
				}
			}
			.rightSectionHeader #headerSearchBlock {
				margin-bottom: 0px;
				.searchParent {
					border: 1px solid $live-interact-header !important;
					&:focus-within,
					&:focus,
					&:hover {
						border: 1px solid #fff !important;
						opacity: 1;
					}
				}
				.headerSearchInput {
					background-color: #373c4a !important;
					color: $textcolor !important;
				}
				button {
					background-color: inherit !important;
					svg {
						fill: $textcolor !important;
						.searchIcon {
							fill: $textcolor !important;
						}
					}
				}
			}
		}
		[role='presentation']:not(.poll__menu) {
			[role='document'] {
				background-color: $live-interact-header !important;
			}
			&.MuiPopover-root .MuiPopover-paper,
			[role='menu'] {
				background-color: $live-interact-header !important;
				span {
					color: $textcolor !important;
				}
			}
			svg {
				fill: $textcolor !important;
			}
			label {
				color: $textcolor;
			}
			li {
				color: $textcolor !important;
				span {
					color: $textcolor !important;
				}
				&:hover {
					background-color: $dark-mode-element-hover !important;
				}
			}
		}
		#mainMenu {
			.menuContainer {
				&:hover {
					//.expandIconWrapper,
					li {
						background-color: $dark-mode-element-hover !important;
					}
				}
				.expandIconWrapper {
					&:hover {
						background-color: $dark-mode-element-hover !important;
					}
				}
			}
			.MainmenuSubElement {
				background-color: inherit !important;
				div {
					background-color: inherit !important;
					&:hover {
						background-color: $dark-mode-element-hover !important;
					}
				}
			}
		}
	}
}

.TimeInput {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.timeInputErrorMsg {
	color: red !important;
	text-align: right;
	padding: 5px;
	label {
		color: red !important;
		font-weight: bold;
		letter-spacing: 0.5px;
	}
}

.tooltiptext {
	@include textEllipsis;
	background-color: #000000;
	border: none;
	color: white !important;
	display: none;
	font-size: 10px;
	max-width: 120px;
	opacity: 0.8;
	overflow: hidden;
	padding: 5px 10px;
	pointer-events: none;
	position: absolute;
	text-align: center;
	user-select: none;
	white-space: nowrap;
	width: auto;
	z-index: 1;
}

.iframe-player-preview {
	border: 0;
	overflow: hidden;
}
