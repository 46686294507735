@import "./../../global.scss";

.sec {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #eaeaf2;
    display: inline-flex;
    margin-bottom: 8px;

    .secTitle {
        font-size: 20px;
        color: #3b3b3b;
        font-weight: normal;
    }
    .secSubTitle {
        font-size: 12px;
        color: #888;
    }
    .inprogress {
        text-align: center;
        width: 100%;
    }

    .MuiPaper-root {
        width: 100%;
        box-shadow: none;
    }
    // .MuiInputLabel-root {
    //     font-size: 1.2rem;
    // }
    .MuiTableCell-root {
        font-size: 1rem;
        padding: 10px;
    }
    .MuiTableRow-hover {
        cursor: pointer;
    }

    .MuiFormControl-root.formControlAnalytics, 
    .MuiInputBase-root.MuiTablePagination-input,
    .MuiFormControl-root.MuiTextField-root {
        flex-basis: inherit !important;
    }

    &.topMetrics {
        .paper {
            padding: 10px;
            // background: linear-gradient(320deg, #2152D0 0%, #126CFC 100%);
            background-color: #F7F8FA;
            //background: linear-gradient(320deg, #F44352 0%, #FD5E6B 100%);
            font-size: 1rem;
            box-shadow: none;
            &:hover {
                background-color: #F2F5F7;
            }
            .paperTxt{
                padding: 10px;
            }
            .paperNum {
                font-size: 2.5rem;
                font-weight: bold;
                border-radius: 10px;
                color: #126CFC;
                padding: 0 10px;
                // background-color: rgba($color: #fff, $alpha: 0.1);
            }
        }
    }
}

.addScroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    height: calc(100vh - 80px);
    @include addGreyScrollBar;
}

.capitalize, .capitalize select, .capitalize option {
    text-transform: capitalize; 

}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    top: 20px;
    width: 1px;
}

.tableThumb {
    background-color: black;
    width: 85px;
    height: 48px;
    display: inline-block; /* makes it fit in like an <img> */
    background-size: cover; /* or contain */
    background-position: center center;
    background-repeat: no-repeat;
}

.analyticsTab {
    padding: 0;
    background-color: #F7F8FA!important;
    color: #000!important;
    
    .MuiTabs-indicator {
        background-color: #126CFC;
    }
    .MuiTab-textColorInherit {
        opacity: 1;
        color: rgba($color: #000000, $alpha: 0.7)!important;
        &.Mui-selected {
            background-color: #F2F5F7;
        }
        &:hover {
            background-color: #F2F5F7 !important;
        }
    }
    .MuiPaper-root {
        width: 100%;
        box-shadow: none;
    }
}

.btnLarge {
    height: inherit;
    &.mr-2 {
        margin-right: calc(8*2px);
    }
}


.tech, .volumePerformance {
    text-transform: capitalize; 
}

