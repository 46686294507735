.uploadDialogEmailSection {
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #e5e9ec;

	&:not(.expanded):hover {
		background-color: #f2f5f7;
		cursor: pointer;
	}

	.uploadEmailContent {
		width: 100%;
		margin: 15px 25px;
		display: flex;
		flex-direction: column;

		.emailNotificationTitle {
			display: flex;
			justify-content: space-between;
			align-items: center;

			svg {
				width: 20px;
			}
		}

		.emailNotificationInput {
			display: flex;
			justify-content: space-between;
			margin: 10px 0;
		}
	}
}
