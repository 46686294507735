@import "../../../global";

.LiveManagerSnapshotSettingsWrapper{

    .interactSettings {
        pointer-events: none;
        opacity: 0.7;
    }
    input[type=text] {
        padding: 5px;
        font-size: 12px;
        -webkit-appearance: button;
        background-color: transparent;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 2px;
        &:focus{
            border-radius: 2px;
        }
    }

    .TimeInput {
        background-color: initial;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 2px;
        height: auto !important;
        text-align: center;
        float: right;
        input[type=number] {
            width: inherit;
            background-color: inherit;
            color: #ffffff;
        }
        &:hover, &:focus-within {
            background-color: #2a2e38 !important;
            border: 1px solid #fff !important;
        }
    }
    .liveGeneralSettings {
        margin-bottom: 10px;
    }

    .title{
        font-size: 14px;
        font-weight: 600;
        float: left;
        max-width: 57%;
        @include textEllipsis;
    }
    .closeButton{
        height: 18px;
        width: 18px;
        border: 0;
        background: none;
        cursor: pointer;
        float: right;
    }

    .inspectMediaButton {
        svg {
            width: 15px;
            height: 15px;
            fill: $header-bg-color;
            margin-right: 10px;
        }
    }

    .actionButtons {
        display: block;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        img {
            width: 15px;
            height: 15px;
            margin-right: 0.5em;
            background-color:transparent;
            color: #FAFAFA;
        }
    }


    .ConvertToMediaButton,
    .DeleteButton {
        min-width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 0.8em;
        text-transform: none !important;
        @include textEllipsis;
        font-size: 12px !important;
    }

    .DeleteButton {
        border: 1px solid #F53240;
        box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.16);
        svg {
            margin-bottom: 2px;
        }
    }

    .generalSubField[attr=true]{
        .TimeInput {
            border: 1px solid red !important;
        }
    }

   /* @media screen and (max-width:1450px) {
        .header {
            height: 20%;
        }
        .actionButtons {
            display: inline-flex;
            button {
                margin-left: 10px;
                font-size: 12px !important;
                img, svg {
                    display: none;
                }
            }
        }
        .actionButtons.mediaButtons {
            display: inline-block;
            button {
                img, svg {
                    display: block;
                }
            }
        }
        .endpointTimeSection {
            margin-top: 0px !important;
        }
    }*/
}