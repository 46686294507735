.inspect-container {
	&-wrapper {
		&:not(.inspect-container-wrapper--mobile) {
			display: flex;
		}
		height: 100%;
		&.inspect-container-wrapper--mobile {
			&.inspect-container-wrapper--scroll {
				overflow: auto;
			}
			.inspect-container {
				width: 100% !important;
				min-width: 100% !important;
				border-right: 1px solid transparent;
				border-left: 1px solid transparent;
			}
			.inspect-container__content-header {
				border-top: 1px solid rgba(0, 0, 0, 0.12);
			}
			.LivePlayerWrapper {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.inspect-container-middle .list-media-display {
				padding: 0 16px;
			}
		}
	}
	&__action {
		padding: 0 16px;
		.inspect-container__back-btn {
			width: 100%;
			height: 40px;
			margin: 12px 0;
			box-shadow: none;
			position: relative;
			&:hover {
				box-shadow: none;
			}
			.MuiButton-label > svg {
				position: absolute;
				top: 50%;
				bottom: 0;
				left: 12px;
				transform: translateY(-50%);
			}
		}
	}
	&-left {
		flex-basis: 18%;
		min-width: 200px;
		border-right: 1px solid #dfe7eb;
	}
	&-middle {
		flex: 47% 1 0;
		height: 100%;

		tbody {
			display: block;
			height: calc(100% - 50px);
			overflow: auto;
			background-color: #fafafa;
		}
		thead,
		tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed; /* even columns width , fix width of table too*/
		}
		thead {
			width: 100%;
		}
		.media-item-container__thumbnail {
			margin-right: 0;
		}
		.list-media-display {
			border-top: none;
			table {
				tbody tr {
					border-top: 1px solid rgba(0, 0, 0, 0.12);
					border-bottom: 1px solid rgba(0, 0, 0, 0.12);
					margin-top: 5px;
				}
				thead tr {
					border-top: none;
				}
			}
			.media-item-container {
				cursor: pointer;
			}
		}
	}
	&-right {
		flex-basis: 35%;
		max-width: 35%;
		border-left: 1px solid #dfe7eb;
		height: 100%;
		.player-preview {
			height: 100%;
		}
	}

	&__content-header {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		font-weight: 600;
		padding: 12px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);

		#mediaLibrarySection {
			position: absolute;
			right: 16px;
		}
	}
	&__content {
		overflow: auto;
		height: calc(100% - 45px);
	}
	&__content-action {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 24px 24px 0;
		> :not(:last-child) {
			margin-right: 12px;
		}
		&:last-child {
			padding: 24px;
		}
	}
	&__content-padding {
		padding: 12px 16px;
	}
}

.inspect-menu_parent-item {
	height: 46px;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.00938em;
	margin: 2px 0px;
	border-radius: 4px;
	width: auto;
	color: #fff;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&_label {
		margin-right: 5px;
		padding-left: 12px;
		padding-right: 4px;
		flex: 1;
	}

	&_icon {
		justify-content: center;
		width: 36px;

		svg {
			width: 1.2em;
			height: auto;
		}
	}

	&_label,
	&_icon {
		display: flex;
		align-items: center;
		background-color: #126cfc;
		border-radius: 4px;
		height: 100%;
		&:hover {
			background-color: #0053d8 !important;
		}
	}
}

.inspect-input__full {
	margin-bottom: 12px;
	.MuiFormControlLabel-label {
		width: 100%;
		padding: 12px 0;
	}

	&.inspect-input__flex-container {
		display: flex;
	}
}

.inspect-input__label--standalone {
	padding: 12px 0;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	margin-right: 16px;
	vertical-align: middle;
	-webkit-tap-highlight-color: transparent;
	margin-left: 16px;
	flex-direction: column-reverse;
}
