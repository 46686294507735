.button-icon-configure-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 1.5rem 0;

	.toggle-button-custom {
		padding-left: 13px;
		padding-right: 13px;

		&.MuiToggleButton-root.Mui-selected {
			background-color: transparent;
			border: 1px solid #126cfc;
		}
	}
}

.divider-extra-space {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	&.divider--short {
		width: 80%;
		margin-left: 20%;
	}
}

.text-widget-sidebar-subtitle {
	font-weight: bold !important;
}

.text-widget-sidebar-edit-button-label {
	margin-right: 15px;
}
