.cardData__container {
	+ .cardData__container {
		margin-top: 10px;
	}

	.MuiCardContent-root {
		padding: 12px 24px 24px !important;
	}
	.MuiCardActions-root {
		font-weight: 600;
		padding: 0 8px !important;
		button {
			font-weight: 600;
		}
	}

	&--box {
		cursor: pointer;
		&:hover {
			background-color: #f2f5f7;
		}

		.MuiCardContent-root {
			padding: 0 0 0 12px !important;
		}
	}

	&.noPadding {
		& .MuiCardContent-root {
			padding: 0 0 0 0 !important;
		}
	}

	&.activeBorder {
		border: 1px solid #126cfc80;
	}
}

.scenario-editor__tab-content > .cardData__container {
	border-right: none !important;
	border-left: none !important;
}
