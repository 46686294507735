.create-scenario-dialog {

    .MuiDialog-paperWidthSm {
        max-width: 720px;
    }

    .MuiDialog-paper {

        overflow: hidden;

        .decoration-image {
            flex: 4;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              overflow: hidden;
            }
        }

        .dialog-content {
            flex: 5;
            margin: 30px;

            .container {
                margin-bottom: 25px;

                & h2 {
                    font-family: 'Lora', serif !important;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                &.close-button {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-bottom: 0;

                    button.MuiButton-sizeSmall {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        background-color: #eaf0fe;
                    }
                }

                &.create-button {
                    display: flex;
                    justify-content: center;

                    & .create-button-text {
                        color: white;
                        font-size: 14px;
                    }
                }
            }
        }

    }

}