@import "../../../global";
#coverUploads {
    display: flex;
    align-items: center;   
    .loadedImage {
        width: 65px;
        height: 45px;
        float: left;
    }    

    .newButton {       
        margin: auto;
    }
    .replaceButton {
        background: #444B60 0% 0% no-repeat padding-box;
        font-size: 12px !important;
        //background-color: #444B60; 
        text-align: center;
        background-color:  $live-interact-element-hover !important;
        &:hover {
            filter: brightness(130%) !important;
        } 
        button {
            background-color: transparent !important;
            &:hover {
                background-color: transparent !important;
            }
        }
       .dropzoneContainer {
            border: 1px solid #FFFFFF;
            border-radius: 4px;
            margin: auto;
            .dropzone {
                padding: 0px;
                &:focus {
                    outline: none !important;
                    outline-width: 0px !important;
                }
            }            
       }
    }

    .removeResource {
        width: 5%;
        margin-left: 20px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        svg {
            margin: auto;
            width: 30px !important;
            height: 30px !important;
            .a {
                fill: transparent;
                &:hover {
                    fill: brightness(50%);
                }
            }
            .b {
                fill: #fff;
                &:hover {
                    fill: brightness(50%);
                }
            }
        }         
    }

    .coverImage {
        display: flex;
        width: 100%;
        align-items: center;
        background: #444B60 0% 0% no-repeat padding-box;
        &:hover {
            background-color: #4A526A !important;
            cursor: pointer;
        }
    }

    .loadedImageContainer {
        display: flex;
        align-items: center;
        width: 70%;        
        label {
            cursor: pointer;
        }  
        
        img {
            margin-right: 20px;
        }
    }
    .dropzoneContainer {
        margin-top: 10px;
        border: 1px dashed #5C5C5C;
        .dropzone {
            display: grid;
            cursor: pointer;
            padding: 10px;
            button {
                margin: auto;
            }
        }
        svg {
            .a,.b{fill:#fff;}.a{fill-rule:evenodd;}
            margin-right: 5px;
        }
        text-align: center;
        color: #ffffff;
        .uploadIconContainer {
            height: 20px; 
        }
        label {
            font-size: 10px;
            color: #9c8e8ee3;
        }        
    }
}