@import "../../../global";
.LiveManagerStreamSettingsWrapper {

    .subFieldDesc{
        display: inline;
        .switchButtonLabel,
        .switchButton {
            float:left;
            color: #ffffff;
            text-transform: capitalize;
        }
        .switchButton {
            cursor: pointer;
            margin-left: 5px;
            margin-right: 10px;
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            -webkit-transition: background-image 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-image 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            width: 28px;
            height: 30px;
        }
        .switchButton[attr=disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .switchOption {
       float: right !important;
       width: 70px;
    }
    .subFieldSelect {
        background: white;
        border-radius: 4px;
        select {
            height: 28px;
            border-radius: 4px;
            min-width: 40px;
            padding: 5px;
            border: 1px solid #e8e8e8;
            text-align: left !important;
            width: 100%;
            &:not(:disabled) {
                cursor: pointer;
            }
        }
    }

    .switchButton.checked {
        background: url('../../../assets/images/toggle_on.svg') no-repeat;
    }
    .switchButton.unchecked {
        background: url('../../../assets/images/toggle_off.svg') no-repeat;
    }

    /*.switchButton.checked {
        > span {
            color: #A2E2A7 !important;
        }
        > span:nth-child(2) {
            background-color: #A2E2A7 !important;
        }
    }
    .switchButton.unchecked {
        > span {
            color: #000 !important;
        }
        > span:nth-child(2) {
            background-color: #565E74 !important;
        }
    }*/
}