@import '../../../../../global';

.thumbnailsContentWrapper {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	justify-content: space-evenly;

	h2 {
		margin-left: 0px;
	}

	.imageSelectionTabsList {
		text-align: center;
		margin-bottom: 10px;
		.selectionTab {
			cursor: pointer;
			display: inline-block;
			margin-right: 10px;
			label {
				padding: 5px;
				font-size: 14px;
			}
		}
		.selectionTab.selected {
			background-color: $checkboxes-highlighted-color;
			color: white;
		}
	}

	.actualThubGallery {
		border: 1px solid lightgrey;
		display: grid !important;
		grid-template-columns: repeat(auto-fill, 160px);
		grid-gap: 1rem;
		justify-content: center !important;
		padding-bottom: 10px;

		> div {
			margin-right: 10px;
			display: flex;
			flex-direction: column;
			width: 150px;

			.thumbDeleteSvgBtnWrapper {
				width: 100%;
				height: 15px;
				z-index: 500;
				text-align: right;

				> svg {
					height: 100%;
					margin-top: 20px;
					margin-right: 5px;
					background-color: #000;
					fill: #fff;
					border-radius: 4px;
					padding: 2px;
					cursor: pointer;
				}
			}

			.playlistThumbnailImgWrapper {
				height: 100px;
				cursor: pointer;
				margin-right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				// border: 1px solid #e8e8e8;
				box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
				background-color: transparent !important;

				> img {
					max-width: 100%;
					max-height: 100%;
					display: flex;
					margin: 0 auto;
					vertical-align: middle;
				}
			}

			.overlayForPlaylistThumbImg {
				position: relative;
				top: -75px;
				width: 150px;
				height: 50px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				> svg {
					width: 40px;
					background-color: $tooltip-text-color;
					cursor: pointer;
				}
			}
		}
		.singleThumbnail {
			float: left;
		}
	}
}
.thumbnailsContentWrapperExtraTopMargin {
	margin-top: 0;
}
.singleThumbnail {
	width: 90px;
}
.selectedThumbnailByDefault {
	border: 2px solid $customer-primary !important;
	border-radius: 3px;
	padding: 2px;
	cursor: default !important;
}

.thumbnailManagerButtonWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 14px;
}

.hideCaptureButton {
	visibility: hidden !important;
}

.thumbnailImageItem:hover .media-library__item--locked {
	visibility: visible;
}
