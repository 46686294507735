@import "../../global";
.liveSettingsHeader {
  display: flex;
  padding: 5px;
  .backButton {
    cursor: pointer !important;
    background-color: #2a2e38;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #fff;
      width: 12px;
      height: 14px;
    }
    &:hover {
      background-color: $cancel-button-hover;
    }
  }
  label {
    color: #fff;
    margin: auto;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}
#liveStreamSettings {
  height: 100%;
  .streamSettingsOption {
    height: 30px;
    display: inline-block;
    width: 100%;
    padding: 0px;
    li {
      clear: float;
      display: block;
      width: calc(50% - 10px);
      padding: 24px;
      text-align: left;
      list-style-type: none;
      background-color: #2a2e38;
      cursor: pointer;
      float: left;
      border-radius: 4px;
      margin-left: 10px;
      margin-bottom: 10px;
      @include textEllipsis;
      svg {
        cursor: inherit;
        fill: #ffffff !important;
        fill-rule: evenodd;
        width: 24px !important;
        height: 24px !important;
        margin-right: 10px;
        margin-left: 5px;
        display: block;
      }
      svg,
      label {
        //to place avg and label inline
        cursor: inherit;
        vertical-align: middle;
        color: #ffffff;
      }
      &:hover {
        background-color: #1c1c1c !important;
      }
    }
  }

  .toggle {
    position: relative;
    height: 36px;
    border-bottom: 1px solid $movable-grid-color;
    background-color: #373c4a;
    cursor: pointer;
    svg,
    label {
      vertical-align: middle;
      display: inline-block;
    }
    svg {
      position: absolute;
      left: 30px;
      top: 10px;
      margin-right: 10px;
    }
    .toggleButton {
      cursor: pointer;
      position: absolute;
      left: 10px;
      height: 12px;
      width: 12px;
      top: 12px;
      -webkit-transition: display 0.2s ease-out;
      transition: display 0.2s ease-out;
      background: url("./../../assets/images/notexpanded.svg") no-repeat !important ;
    }
    .propertySectionTitle {
      cursor: pointer;
      position: absolute;
      left: 54px;
      top: 10px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 0.5px;
    }
  }
  .toggle.active .toggleButton {
    height: 12px;
    top: 14px;
    background: url("./../../assets/images/expanded.svg") no-repeat !important;
  }

  .toggle.active + .toggleDescription {
    display: block;
    background-color: #373c4a;
  }
  .toggle + .toggleDescription {
    display: none;
  }

  .toggleDescription {
    .generalSubField {
      //height: 35px;
      display: block;
      padding: 10px;
      width: 100%;
      min-height: 50px;
      color: #ffffff;
      position: relative;
      .subFieldTitle {
        width: 50%;
        text-align: left;
      }
      .subFieldDesc {
        width: 50%;
        text-align: right;
        float: left;
      }
      label {
        float: left;
      }
      .snapShotMediaControls {
        button {
          min-width: 25px !important;
          font-size: 10px !important;
          height: 30px;
        }
        button:first-child {
          margin-right: 10px;
        }
      }
      .switchOption {
          position: absolute;
          top: 10px;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-end;
          > * {
              height: 100%;
          }
          .switchButton {
              margin-top: 3px;
          }
      }
    }
    .generalSubField.disabled {
      input {
        pointer-events: none;
      }
    }
  }

  .fieldSettings {
    width: inherit;
    margin-bottom: 10px;
    // background-color: #ffffff;
    padding-bottom: 5px;
  }
}
