@import "../../global";

#singleMediaDropDownList {
    .listingMenuDropDown {
        img {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
}

div#singleMediaDropDownList[role="presentation"] {
    div[aria-hidden="true"] {
      background-color: transparent !important;
    }
}

div#uploadsMenuSystem[role="presentation"] {
    div[aria-hidden="true"] {
      background-color: transparent !important;
    }
}