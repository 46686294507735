@import "../../global";

#interactElements {
  height: inherit;
  background-color: #323641;
  .dragInfo {
    font-size: 10px;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: #323641;
  }
  .interactElementsList {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-template-rows: minmax(80px, max-content) repeat(auto-fill, 80px);
    //grid-template-rows: 80px 80px 80px 80px 80px 80px;
    grid-gap: 1rem;
    justify-content: center !important;
    background-color: #323641;
  }
  .interactSingleElements {
    margin: 5px;
    padding: 6px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #373c4a;
    color: white;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-right: 5px;

    float: left;
    text-align: center;
    position: relative;
    &:hover {
      background-color: #444b60;
      box-shadow: 3px 3px 10px #00000029;
      cursor: move;
    }
    /* &:before {
            content: "≣";
            color: grey;
        }*/
    .interactElementIcon {
      height: 16px;
      margin-top: 8px;
      svg {
        width: 32px !important;
        height: 15px !important;
        filter: brightness(0) saturate(100%) invert(100%) sepia(5%)
          saturate(18%) hue-rotate(141deg) brightness(103%) contrast(100%);
      }
    }
    .dragIcon svg {
      width: 15px !important;
      height: 40% !important;
      top: 30%;
      fill: #c6c6c6 !important;
      position: absolute;
      left: 2px;
      left: 2px;
      circle {
        display: block !important;
        fill: inherit !important;
      }
      &:hover {
        cursor: move;
      }
    }
    label,
    .dragIcon {
      /* display: table-cell;
            vertical-align: middle;*/
      &:hover {
        cursor: move;
      }
    }
    label {
      max-width: 85px;
      @include textEllipsis;
    }
  }

  /* .interactElementsTitleContainer {
        position: relative;
        background-color: #000000;
        width: 100%;
        height: 36px;
        @include alignTextImageCenter;
        svg {
            fill:#ffffff !important;
            margin-right: 5px;
            position: absolute;
            margin-left: 20px;
            height: 12px !important;
            circle {
                fill: inherit !important;
                display: block;
            }
        }
        .interactElementsTitle {
            float: left;
            font-weight: 600;
            letter-spacing: 0.5px;
            padding: 5px;
            padding-left: 46px;   
            color: #ffffff;     
        }
    } */

  .interactElementsTitleContainer {
    background-color: #2a2e38;
    color: #ffffff;
    height: 25px;
    line-height: 25px;
    text-align: center;
    label {
      padding-left: 6px;
      display: block;
      float: left;
    }
    .openInNewWindowIcon {
      float: right;
      svg {
        height: 25px;
      }
    }
  }

  .interactElementsList {
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 20px;
    @include addGreyScrollBar;
    height: calc(100% - 56px);
    overflow: auto;
    background-color: #323641;
  }
}
