.viewer-engagement-manager {
	height: 100%;
	font-size: 12px;
	color: #ffffff !important;

	input:not(.poll__form--input),
	textarea,
	button:not(:disabled) {
		color: #ffffff !important;
	}

	svg:not(.infoIcon) {
		fill: #ffffff !important;
	}

	&__loading._loading_overlay_wrapper {
		height: 100%;
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		> ._loading_overlay_overlay {
			position: absolute !important;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	&__active-indicator {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #a2e2a7;
		margin-right: 5px;
		&--inactive {
			background-color: #e68173;
		}
	}

	&__header {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 44px;
		border-top: 1px solid #1a1c26;
		border-bottom: 1px solid #1a1c26;
		position: relative;
	}

	&__title {
		font-weight: 600;
	}

	&__container {
		background-color: #2a2e38;
		height: calc(100% - 44px);
		display: flex;

		&--inactive {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 12px;
			> * {
				margin-bottom: 12px;
			}
		}

		> * {
			color: inherit;
		}
	}

	&__tab-bar {
		width: 52px;
		background-color: #323641;
		border-left: 1px solid #2a2e38;
		&--vertical {
			.viewer-engagement-manager__tab {
				font-size: 10px !important;
				min-height: 60px !important;
				border-left: 2px solid transparent !important;
				&--selected {
					border-left: 2px solid #0053d8 !important;
				}
			}
		}
		&--horizontal {
			font-size: 12px !important;
			border-bottom: 1px solid #1a1c26 !important;
			.viewer-engagement-manager__tab {
				border-bottom: 2px solid transparent !important;
				flex: 1;
				&--selected {
					border-bottom: 2px solid #0053d8 !important;
				}
			}
		}
	}

	&__tab {
		opacity: 1 !important;
		min-width: 0px !important;

		&-polls svg {
			width: 15px;
			height: 15px;
		}

		&--selected {
			background-color: #262a34 !important;
		}

		&-content {
			flex: 1;
			position: relative;
			&__wrapper {
				height: 100%;
				overflow-y: auto;
				padding-bottom: 5px;
				border-right: 1px solid #1a1c26;
				font-size: 11px;
				&--hasInput {
					height: calc(100% - 72px);
				}
				&--short {
					height: calc(100% - 142px);
				}
				&:not(.qna-container__wrapper) {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}

	&__back-btn {
		position: absolute !important;
		left: 12px;
	}

	&__account-btn {
		position: absolute !important;
		right: 3px;
	}

	&__activated-btn {
		margin-top: 12px !important;
	}

	&__input {
		flex: 1;
		height: 48px;
		flex-basis: 100% !important;

		&--helper {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 10px;
			padding: 3px 14px;
			color: #ffffff99;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #373c4a !important;
		}

		.MuiOutlinedInput-root {
			background-color: #2a2e38;
		}

		&-container {
			display: flex;
			align-items: center;
			height: 72px;
			position: relative;
			padding: 22px 0px 12px 12px;
			background-color: #323641;
			padding-right: 12px;
		}

		&-action {
			height: 32px;
			width: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #454853;
			border-radius: 4px;
			&:not(:first-child) {
				margin-left: 12px;
			}
			&:first-child {
				margin-right: 12px;
			}

			&--send {
				position: absolute;
				right: -40px;
				z-index: 1;
			}
		}
	}

	div::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	div::-webkit-scrollbar-track {
		background-color: #323641;
		outline: 2px solid #323641;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #2a2e38;
	}

	&-profile-settings__container {
		background-color: #2a2e38;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&-settings__header {
		padding: 12px;
		color: #fff;

		* {
			color: inherit;
		}
	}

	&-settings__panel {
		border-bottom: 1px solid #1a1c26;
		padding: 12px;
	}

	&-settings__header {
		display: flex;
		align-items: center;
		justify-content: center;

		button:not(:last-child) {
			height: 30px;
			width: 30px;
		}

		> button:last-child {
			right: 0;
			position: absolute;
		}
	}

	&-settings__panel {
		.MuiOutlinedInput-notchedOutline {
			border-color: #ffffff99;
		}
		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border-color: #ffffffff;
		}
		.MuiFormControl-root {
			width: 100%;
		}
		.MuiFormControlLabel-root {
			margin: 0;
		}
		.MuiFormControlLabel-label {
			width: 100%;
			margin-bottom: 5px;
		}

		&:last-child {
			border-bottom-color: transparent;
		}
	}

	&-settings__control {
		.MuiFormControlLabel-label {
			font-weight: 700;
			margin-left: 16px;
		}
	}

	&-settings__helper-text {
		font-weight: 400;
		color: white !important;
		margin-top: 12px;
	}

	&-settings__flex-panel {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-settings__chat-access label {
		width: 100%;
		display: block;
	}

	.MuiFormControlLabel-label.Mui-disabled {
		color: #ffffff99 !important;
	}

	.poll {
		&__form {
			background-color: white;
			border-radius: 4px;
			margin: 10px;
			box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
			border: 1px solid #ced4d8;
			padding: 5%;
			top: auto;

			button.poll__form--secondary-btn:not(:disabled) {
				color: #126cfc !important;
				border-color: #126cfc !important;
			}

			.poll__form--input input {
				color: inherit !important;
			}

			svg {
				fill: #0c306c !important;
			}
		}
	}
}
