@import "../../../../../global";
#informationSection {
    margin: 0 auto;
    margin: 0 24px;
    &:not(.player-preview__information-section--new) {
        background-color: #f9f9f9;
        padding: 24px;
    }
    div.details {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        div.info-section {
            min-width: 49%;
            display: inline-block;
            margin-bottom: 12px;
            flex: 1 1 50%;
            label {
                text-align: left;
                width: 100%;
            }
        }
    }
    .legacyLive__wrapper {
        font-weight: normal;
        font-size: 12px;
        width: 100%;
        .legacyLive__item {
            justify-content: space-between;
            align-items: flex-start;
            display: flex;
            margin-bottom: 12px;
            &--url {
                margin-left: 12px;
            }
        }
        table {
            width: 100%;
        }
        tr {
            text-align: left;
            td, th {
                border-bottom: 1px solid #e8e8e8;
                padding: 0 5px;
                &:not(:last-child) {
                    border-right: 1px solid #e8e8e8;;
                }
            }
            th {
                padding: 12px 5px;
            }
        }
    }

    label.infoHeading { font-weight: 600 }
    label {
        margin: 0.2em;
        font-weight: normal;
        display: inline-block;
    }

    .streamList {
        padding-left: 20px;
        margin-bottom: 50px;
        padding-bottom: 20px;
        .bitrateAndStreamList {
            margin-top: 10px
        }
        .streamBlock {
            width: 100%;
            clear: both;
            display: inline-flex;
        }

        .streamBitrate,
        .streamName,
        .infoSection {
            margin-top: 8px;
            padding: 2px;
            color:#000000;
            display: inline-block;
        }
        .streamBitrate {
            width: 20%;
            float: left;
            padding-left: 0px;
        }
        .streamName {
            width: 70%;
        }
        .infoSection {
            float: right;
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
            }
        }
        .tooltip {
            .tooltip-message {
                width: 290px;
                padding: 0px !important;
                background-color: #000000
            }
            .tooltip-left::after {
                border-left: 9px solid #000000;
            }
            .tooltip-right::after{
                border-right: 9px solid #000000;
            }
            .tooltip-left {
                top: 40%;
            }
        }
    }

}

.url-container {
    position: relative;
    padding: 12px 0;
    .actions {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 0;
        background: #f9f9f9;
        visibility: hidden;
    }
    .text {
        @include textEllipsis;
        &--show {
            white-space: normal;
        }
    }
    &:hover {
        .actions {
            visibility: visible;
        }
    }
}
