@import '../../global';

h1 {
	@include textEllipsis;
}

#catalogContainer {
	width: 100%;
	min-height: 100px;
	max-height: 250px;
	overflow-y: auto;
	overflow-x: auto;
	border: 1px solid #d3d3d3;
	border-radius: 4px;
	&::-webkit-scrollbar {
		width: 5px;
		cursor: pointer;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px $body-bg-color-after-login;
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background: $default-label-text-color;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: $body-table-font-color;
	}

	.catalogLabel {
		.catalogMenuToggleButton {
			display: none !important;
		}
	}
}

.profileMenuList {
	li {
		font-weight: 600;
		padding-left: 25px;
		&:hover {
			background-color: #f2f5f7 !important;
		}
		&:active {
			color: #ffffff !important;
		}
	}
	li:hover {
		background-color: #f2f5f7 !important;
	}
}

.infoContentWrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin: 20px;

	.loggedMenu {
		color: #989898;
		font-size: 11px;
		margin-bottom: 10px;
	}
	.userNameMenu {
		color: #989898;
		font-size: 13px;
	}
	.accountNameMenu {
		color: #989898;
		font-size: 11px;
		font-weight: 600;
		text-transform: uppercase;
	}
}
.hrMenu {
	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #f0f0f0;
	}
}
header {
	background-color: $header-bg-color;
	padding: 23px 20px;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	a {
		color: $primary-icon-color;
		font-size: 20px;
	}
	.globalIcon {
		width: 16px;
		height: 16px;
		float: left;
		margin-right: 7px;
	}
}
.headerIcon {
	width: 16px;
	height: 16px;
	border: none;
	background: none;
	margin-right: 15px;
	margin-left: 5px;
	font-family: $body-font !important;
	cursor: pointer;
	&:focus {
		outline: none;
	}
	> svg {
		fill: $primary-icon-color;
		&:focus {
			outline: none;
		}
	}
}
.headerSearchIcon {
	width: 16px;
	height: 16px;
	border: none;
	background: none;
	cursor: pointer;
	&:focus {
		outline: none;
	}
	> svg {
		fill: $primary-icon-color;
		&:focus {
			outline: none;
		}
	}
}

@media screen and (max-width: 500px) {
	.plainButton {
		width: 40px !important;
		margin-right: 10px;
	}
}
.profileIcon {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}

.navBurger {
	position: relative;
}

.authenticatedHeader {
	background-color: $header-bg-color;
	height: 65px;
	display: flex;
	flex-direction: row;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	box-shadow: 3px 3px 10px #00000010;
	margin-bottom: 8px; //adding for box shadow to be visible

	.leftSectionHeader {
		align-items: center;
		display: flex;
		width: 50%;
		padding-left: 20px;
		padding-top: 0px;
		-webkit-transition: padding-left 0.3s; /* For Safari 3.1 to 6.0 */
		transition: padding-left 0.3s;

		img {
			z-index: 2000 !important;
			width: 25px;
			height: 25px;
		}
	}
	.rightSectionHeader {
		display: flex;
		width: 50%;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-content: center;
		height: 65px;
		.profileLink > a {
			color: $primary-icon-color;
			margin-right: 10px;
			cursor: pointer;
		}
		.langSelectionBox {
			> a {
				cursor: pointer;
				margin-right: 0px;
				padding-right: 10px;
				color: $primary-icon-color;
			}
		}
		.notificationHeaderIcon {
			> svg {
				fill: $default-label-color;
			}
		}
		#headerSearchBlock {
			margin-bottom: 2px;

			button {
				background-color: #f2f5f7 !important;
				width: 45px;
				height: 45px;
				padding-left: 1px;
				border-radius: 4px 0 0 4px;
				border: none;
				padding-top: 0px !important;
				svg {
					margin-top: 2px;
					width: 16px;
					height: 16px;
					fill: $primary-icon-color;
				}
			}

			.searchParent {
				border-radius: 4px;
				display: flex;
				align-items: center;
				border: 1px solid #f2f5f7 !important;
			}
			.searchParent:focus-within {
				border: 1px solid #126cfc !important;
			}

			.headerSearchInput {
				border: none;
				padding: 6px;
				font-size: 15px;
				height: 45px;
				width: 400px;
				border-radius: 0 4px 4px 0;
				background-color: #f2f5f7;
			}

			.headerSearchInput::placeholder {
				//color: lightgray;
				opacity: 0.5;
			}

			.headerSearchInput:focus::placeholder {
				color: transparent;
			}
			.headerSearchInput:focus {
				outline: none;
			}

			@media screen and (max-width: 1792px) {
				.headerSearchInput {
					width: 400px;
				}
			}

			@media screen and (max-width: 1670px) {
				.headerSearchInput {
					width: 280px;
				}
			}

			@media screen and (max-width: 1460px) {
				.headerSearchInput {
					width: 210px;
				}
			}

			@media screen and (max-width: 1230px) {
				.headerSearchInput {
					width: 180px;
				}
			}
			@media screen and (max-width: 1195px) {
				.headerSearchInput {
					width: 135px;
				}
			}

			@media screen and (max-width: 1095px) {
				.headerSearchInput {
					width: 105px;
				}
			}

			@media screen and (max-width: 820px) {
				.headerSearchInput {
					//width: 150px;
					visibility: hidden;
					width: 1px;
				}
			}

			@media screen and (max-width: 319px) {
				.headerSearchInput {
					visibility: hidden;
					width: 1px;
				}

				> button {
					visibility: hidden;
					width: 1px;
				}
			}
		}
		.profileMenuActive {
			background-color: #f2f5f7;
		}
	}

	@media screen and (max-width: 1280px) {
		.rightSectionHeader {
			width: 50%;
		}
		.leftSectionHeader {
			width: 50%;
		}
	}

	@media screen and (max-width: 1024px) {
		#loggedEmailDisplayBlock {
			width: 210px;
		}
		.topHeaderBlocks {
			//width: 60px;
		}
	}

	@media screen and (max-width: 950px) {
		.rightSectionHeader {
			width: 30%;
			justify-content: flex-end !important;
			margin-left: 150px !important;
		}
	}

	@media screen and (max-width: 650px) {
		.rightSectionHeader {
			width: 30%;
			justify-content: flex-end !important;
			margin-left: 130px !important;
		}
	}

	@media screen and (max-width: 500px) {
		.leftSectionHeader {
			width: 20% !important;
		}
		.rightSectionHeader {
			width: 80% !important;
			justify-content: flex-end !important;
			margin-left: 20px !important;
			right: 0px;
		}
	}
	.headerSearchInputHide {
		background-color: #f0f0f0;
		border: none !important;
		pointer-events: none;
	}
	.topHeaderBlocks {
		display: flex;
		align-items: center;

		> img {
			cursor: pointer;
		}
		> svg {
			fill: $primary-icon-color;
		}
		margin-right: 10px;
	}
	.langDisplayBlock {
		font-size: $default-font-size;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		text-align: right;
		margin-right: 10px;
		color: $top-header-links-color;
		cursor: pointer;

		+ img {
			cursor: pointer;
		}
	}
	#topMenuSystem {
		position: relative;
		top: 10px;
	}
	/* Customized css - Material UI */
	.MuiInputLabel-root-1 {
		transform-origin: top left;
		position: relative;
		left: 30px;
		font-weight: normal;
		color: $top-header-links-color;
		font-size: $default-font-size;
	}
	.MuiSelect-icon-25 {
		display: none;
	}
	.MuiInput-underline-30:before {
		display: none;
	}
	/* Customized css - Material UI */
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 65px;
		height: 4px;
		background: linear-gradient(
			180deg,
			rgba(9, 30, 66, 0.13) 0,
			rgba(9, 30, 66, 0.13) 1px,
			rgba(9, 30, 66, 0.08) 1px,
			rgba(9, 30, 66, 0) 4px
		);
	}
}
.mobileViewPortItem {
	display: none !important;
}
.mobileViewProfileIcon {
	position: relative;
	left: 65px;
	top: 15px;
}
.switchAccountContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
	.headerTitle {
		font-family: $body-font;
		font-size: $XX-large-size;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		text-align: left;
		color: $default-label-color !important;
	}
	.submitBtn {
		width: 286px;
		height: 44px;
		border-radius: 5px !important;
		background-color: $btn-primary-bg-color !important;
		color: $body-bg-color !important;
		font-size: $default-font-size !important;
		cursor: pointer;
		margin-bottom: 0;
		letter-spacing: 1px;
		font-weight: lighter !important;
		width: 130px;
		height: 38px;
		border-radius: 40px;
		box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 40px !important;

		&:hover {
			background-color: $btn-primary-bg-color-hover;
		}
	}
}
.mobileProfileIcon {
	cursor: pointer;
	margin-left: 10px;
	> svg {
		display: none;
	}
}
.switchAccountContainer button {
	&:disabled {
		background-color: $disabled-btn-color !important;
	}
}
.switchAccountContainer {
	.sortingDropDownWrapper {
		margin-bottom: 20px;
	}
}
.uploadingStatusWrapper {
	padding: 5px 15px;

	padding-top: 10px;
	cursor: pointer;

	> label {
		margin-left: 10px;
		color: black;
		position: relative;
		top: -3px;
		cursor: pointer;
		font-weight: bold;
		letter-spacing: 1px;
	}
}

.uploadingStatusWrapper:hover {
	box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
	border-radius: 5px;
}
.uploadingStatusWrapper:active {
	background-color: #f2f2f2;
}
#loggedEmailDisplayBlock {
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: #f2f5f7;
	}
	&:focus {
		background-color: #f2f5f7;
	}
	&:active {
		background-color: #f2f5f7;
	}
	.accountNamesWrapper {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-left: 10px;
		background-color: transparent;
		a {
			max-width: 200px;
			@include textEllipsis;
			&:first-child {
				font-weight: bold;
			}
			&:last-child {
				font-size: 10px;
				text-transform: uppercase;
				font-weight: bold;
			}
		}
	}
	.accountName {
		font-size: 12px;
		font-weight: 600;
		padding: 0 10px;
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
		max-width: 100px;
		overflow: hidden;
	}
}

.gobrain-state-stopped {
	z-index: -1 !important;
}

@media only screen and (max-device-width: 450px) {
	#loggedEmailDisplayBlock {
		height: 45px;
		width: 90px !important;
		margin-right: 0px !important;
		padding-right: 0px !important;
		.accountName {
			display: none;
		}
	}
}

.headerTopMenuBtnIcons {
	width: 12px !important;
	height: 12px !important;
	margin-left: 10px;
}
.headerIconExtendedSearch {
	background-color: #f0f0f0 !important;
	padding-top: 4px !important;
	// margin-top: 12px !important;
	border: none !important;
	pointer-events: none;

	/*  > svg {
        visibility: hidden;
    } */
}
.separateBreadcrumbWrapper {
	h1 {
		color: $body-table-font-color;
	}
	span {
		font-size: 18px;
		color: #3b3b3b !important;
		font-weight: 600;
	}
}
.uploadingStatusWrapperOverridden {
	padding-left: 5px !important;
	//background-color: #8CC738;
	//box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
	min-width: 100px;
	display: flex;
	justify-content: center;

	> svg {
		transform: scale(0.65) translateX(30px) translateY(-3px);
	}
	label {
		color: black;
	}
}
.plainButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background: transparent;
	cursor: pointer;
	height: 32px;
	width: 110px;
	margin-bottom: 2px;

	label {
		margin: 5px;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 2px;
	}

	> svg {
		margin: 5px;
	}

	&:focus {
		outline: none;
	}
}

.plainButton:active {
	background-color: #f2f2f2;
}
.plainButton:hover {
	box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
	border-radius: 5px;
}

.uploadButtonWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border: none;
	background: transparent;
	cursor: pointer;
	height: 45px;
	max-width: 140px;

	button {
		border: none;
		background: transparent;
		cursor: pointer;
		height: 32px;
		width: 32px;
		color: rgba(0, 0, 0, 0.87);
		svg {
			fill: rgba(0, 0, 0, 0.87);
		}
	}
	&:focus {
		outline: none;
	}
}

.uploadButtonWrapper button:hover {
	//box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
	border-radius: 4px;
	background-color: #f2f5f7;
}

.progress-bar {
	position: relative;
	height: 32px;
	width: 150px;
	border-radius: 3px;
	margin-right: 5px;

	background: #f2f5f7;
	opacity: 1;

	border: 1px solid #f2f5f7;
	display: flex;
	align-items: center;

	svg {
		margin-top: 2px;
		position: absolute;
		margin-left: -18px;
	}

	.plabel {
		position: absolute;
		padding: 8px;
		margin-left: 20px;
		font-weight: bold;
		display: flex;
		align-items: center;
		color: #3b3b3b;
	}
}

.filler {
	background: #92bbfd;
	height: 100%;
	border-radius: inherit;
	transition: width 0.2s ease-in;
}

.openNavBarWrapper {
	svg {
		&:hover {
			//box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
			//-webkit-box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
			//-moz-box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
			// border-radius: 4px;
			// background-color: #f2f5f7 !important;
			// cursor: pointer;
		}
	}
	&:hover {
		// box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
		// -webkit-box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
		// -moz-box-shadow: 2px 2px 5px 0px rgba(184,172,172,0.98);
		cursor: pointer;
		border-radius: 4px;
		background-color: #f2f5f7 !important;
		cursor: pointer;
	}

	height: auto;
	width: 45px;
	margin-left: -6px;
	padding: 5px;
	// width:50px;
	// height:50px;
	// top:25px;
	// padding:7px;
	// border-radius:5px;
}

.navMenuHamburger {
	width: 24.3px;
	height: 20.2px;
	z-index: 6000 !important;
	background: url('./../../assets/images/menuHamburger.svg');
	background-repeat: no-repeat !important;
}

.mainMenuWrapper {
	margin-left: 0px;
	z-index: 10000;
	width: 30px;
	padding: 4px;
	border-radius: 5px;
	width: 60px !important;
}

.loadingProgressBar {
	div {
		background-color: #126cfc !important;
	}
}

@media only screen and (max-device-width: 500px) {
	.plainButton {
		width: 50px;

		label {
			display: none;
		}
	}
}

/*mobile responsive fixes*/
@media only screen and (max-width: 1024px) {
	.authenticatedHeader {
		.leftSectionHeader {
			width: 20% !important;
		}
		.rightSectionHeader {
			width: 80% !important;
			margin-left: 0px !important;
			padding-right: 10px;
			position: relative !important;
			justify-content: flex-end !important;
			#headerSearchBlock {
				margin-bottom: 0px;
				margin-right: 10px !important;
				.headerSearchInput {
					width: 160px !important;
				}
			}
			#loggedEmailDisplayBlock {
				width: 200px !important;
				height: 45px;
			}
			.topHeaderBlocks {
				width: auto !important;
			}
		}
	}
	.headerSearchInput {
		width: 0px !important;
		padding: 0px !important;
	}
}
@media only screen and (max-width: 768px) {
	.authenticatedHeader {
		.leftSectionHeader {
			width: 20% !important;
		}
		.rightSectionHeader {
			width: 80% !important;
			margin-left: 0px !important;
			padding-right: 10px;
			#headerSearchBlock {
				margin-bottom: 0px;
				.headerSearchInput {
					width: 0px !important;
				}
			}
			#loggedEmailDisplayBlock {
				width: 85px !important;
				.accountName {
					display: none;
				}
				.headerIcon {
					margin-right: 10px;
				}
			}
		}
	}
	.headerSearchInput {
		width: 0px !important;
		padding: 0px !important;
	}
}

@media only screen and (max-width: 480px) {
	header {
		padding: 23px 15px;
	}
	.authenticatedHeader {
		.leftSectionHeader {
			width: 15% !important;
		}
		.rightSectionHeader {
			width: 85% !important;
			margin-left: 20px !important;
			padding-right: 10px;
			position: fixed !important;
			#headerSearchBlock {
				margin-bottom: 0px;
			}
			.progress-bar {
				width: 145px;
				.plabel {
					font-size: 12px;
				}
			}
			#loggedEmailDisplayBlock {
				width: 75px !important;
			}
		}
		.topHeaderBlocks {
			margin-right: 10px;
		}
	}
	.headerSearchInput {
		width: 0px !important;
		padding: 0px !important;
	}
}
@media only screen and (max-width: 320px) {
	header {
		padding: 23px 15px;
	}
	.authenticatedHeader {
		.leftSectionHeader {
			width: 15% !important;
		}
		.rightSectionHeader {
			width: 85% !important;
			margin-left: 20px !important;
			padding-right: 5px;
			#headerSearchBlock {
				margin-bottom: 0px;
			}
			.uploadButtonWrapper {
				width: 135px;
			}
			.progress-bar {
				width: 135px;
				.plabel {
					font-size: 11px;
				}
			}
			#loggedEmailDisplayBlock {
				width: 70px !important;
			}
		}
		.topHeaderBlocks {
			margin-right: 5px;
		}
	}
	.headerSearchInput {
		width: 0px !important;
		padding: 0px !important;
	}
}

.uploadButtonWrapper.uploadButtonWrapper--encoding {
	.uploadButton {
		background-color: #f2f5f7;
		position: relative !important;
		border-radius: 4px;
	}
	.circular-wrapper {
		position: absolute;
		top: -1px;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
	}
}

body[dark-mode='true'] .uploadButtonWrapper.uploadButtonWrapper--encoding {
	.uploadButton {
		background-color: rgba(0, 0, 0, 0.9);
		> div.MuiCircularProgress-root {
			display: none;
		}
	}
}

.uploadButton {
	background: #fff;
	&:after,
	&:before {
		width: inherit;
		height: inherit;
		content: '';
		position: absolute;
	}
}
@keyframes placeholderAnimate {
	0% {
		background-position: -650px 0;
	}
	100% {
		background-position: 650px 0;
	}
}

.header-container {
	background-color: $header-bg-color;
	height: 45px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	box-shadow: 1px 1px 3px #00000010;

	&.header-container__go-back {
		padding: 0px 24px;
	}

	.left-items-container,
	.right-items-container {
		height: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		flex: 1;
	}

	.left-items-container {
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.right-items-container {
		justify-content: flex-end;
	}

	.home-button {
		width: auto;
		height: 45px;
		cursor: pointer;
	}

	.navigation {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 100%;

		&-items {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 12px;
			cursor: pointer;
			position: relative;
			height: 100%;
			box-sizing: border-box;
			font-size: 12px;
			color: #3b3b3b;

			img {
				width: 18px;
				height: 18px;
			}

			&__label {
				margin-left: 0.5rem;
				color: inherit;
				max-width: 100px;
				@include textEllipsis;
			}

			&::after {
				content: ' ';
				height: 1px;
				bottom: 0;
				right: 0;
				left: 0;
				background-color: transparent;
				position: absolute;
			}

			&:not(.navigation-items--locked) {
				&.navigation-items--selected,
				&:hover,
				&:focus,
				&:focus-within {
					svg {
						fill: #126cfc !important;
					}
					background-color: #f7f8fa;
				}
			}

			&--selected:after,
			&:focus:after,
			&:focus-within:after {
				background-color: #126cfc;
			}
		}
	}

	.profileLink {
		display: flex;
		align-items: center;
	}
	.userProfileMenu {
		width: 200;
		color: '#1b1d2a';
		font-size: 12;
		letter-spacing: 1;
		background-color: 'transparent';
	}

	.user-information {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 0px 10px;
		height: 100%;
		margin-left: 12px;

		&:hover {
			background-color: #f2f5f7;
		}

		> * {
			&:not(:last-child) {
				margin-right: 8px;
			}
			cursor: inherit;
		}
	}
}

body[dark-mode='true'] .header-container {
	background-color: #2a2e38;

	.navigation-items {
		color: #fff;
		opacity: 0.87;
		svg {
			fill: #fff;
		}

		&--selected,
		&:hover,
		&:focus,
		&:focus-within {
			svg {
				fill: #fff;
			}
			background-color: #20242c;
		}

		&--selected:after,
		&:focus:after,
		&:focus-within:after {
			background-color: #fff;
		}
	}

	.uploadButtonWrapper button {
		color: rgba(255, 255, 255, 0.87);
		svg {
			fill: rgba(255, 255, 255, 0.87);
		}
		&:hover {
			background-color: #20242c;
		}
	}

	.user-information {
		color: rgba(255, 255, 255, 0.87);
	}

	.progress-bar {
		background-color: #20242c;
		color: rgba(255, 255, 255, 0.87);
		border-color: #20242c;
		opacity: 0.87;
		.filler {
			background-color: #326af2;
		}
	}
	.header-container__back-btn {
		opacity: 0.87 !important;
		color: #fff !important;
		&:hover {
			background-color: #20242c;
		}
	}
}

.main-menu-mobile {
	ul {
		padding: 12px !important;
	}
	&__item {
		height: 45px !important;
		width: 270px !important;
		border-radius: 4px !important;
		position: relative;
		&--selected {
			background-color: #126cfc !important;
			.MuiListItemText-root,
			.MuiListItemIcon-root {
				color: #fff !important;
			}
		}
	}

	&__title {
		margin-left: 15px;
		margin-right: 15px;
		font-size: 12px;
		font-weight: 700;
		color: #3b3b3b;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.fileNameUploaded {
	padding-right: 5px;
	> label {
		max-width: 150px;
		@include textEllipsis;
	}
	&--status {
		font-size: 10px;
		@include textEllipsis;
		&-icon {
			width: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.main-menu-mobile__item:hover,
.navigation-items:hover {
	.main-menu-mobile__item-locked-layer,
	.navigation-items__locked-layer {
		visibility: visible;
	}
}

.main-menu-mobile__item-locked-layer,
.navigation-items__locked-layer {
	position: absolute;
	background-color: #e5e9ecb2;
	border: 1px solid #e5e9ecb2;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
}

.language-selector {
	text-transform: capitalize !important;
	margin-left: 12px !important;
}
