@import "../../global";

/* Code related to the Users management */
.channelsContentWrapper {
    width: 100%;
    padding: 20px 20px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .channelsListingTopHeader {
        height: 60px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .searchTextInput {
            background-image: url('../../assets/images/search.svg');
            background-repeat: no-repeat;
            background-position: 10px center;
            width: 300px;
            height: 37px;
            border-radius: 5px;
            border: solid 1px $text-inputs-border-color;
            background-size: 16px 16px;
            padding-left: 35px;
            padding-right: 25px;
            font-size: $default-font-size;
            letter-spacing: 1px;
        }

        .newUserBtn {
            box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
        }
    }

    .usersTopHeader {
        height: 60px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .plusSign {
            font-size: 25px;
            line-height: 8px;
            margin-right: 10px;
        }


        .usersBtn {
            box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
        }
    }

    .channelsTableHeading {

        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        >h4,
        >span {
            font-size: $default-font-size;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1px;
            text-align: left;
            //color: $header-bg-color;
        }

        h4 {
            color: $default-label-color
        }

        >span {
            position: relative;
            left: -45px;
        }
    }

    .channelsTableWrapper {
        overflow: hidden;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: column wrap;
        -moz-flex-flow: column wrap;
        flex-flow: column wrap;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        box-pack: center;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        -o-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: center;
        font-size: 1rem;
        margin: 0.5rem;
        line-height: 1.5;

    }

    .channelsTableHeader {
        margin-top: -35px;
        font-weight: 700;
        height: 50px;
        background-color: transparent !important;
    }

    @media (max-width: 500px) {
        .channelsTableHeader {
            display: none;
        }

        .channelsTableItem {
            justify-content: space-between !important;
        }

        .channelsTableRow {
            width: 85% !important;
        }
    }

    .channelsTableRow {
        width: 98%;
        border-bottom: solid 1px $table-borders-color;
    }

    .channelsTableRow:hover {
        background-color: #F4F4F4;
    }

    @media (min-width: 500px) {
        .channelsTableRow {
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-flow: row wrap;
            -moz-flex-flow: row wrap;
            flex-flow: row wrap;
        }
    }

    .channelsTableItem {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-flex-grow: 1;
        -moz-flex-grow: 1;
        flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-flex-basis: 0;
        -moz-flex-basis: 0;
        flex-basis: 0;
        -ms-flex-preferred-size: 0;
        overflow-wrap: break-word;
        word-break: break-all;
        min-height: 65px;
        max-height: 85px;
        align-items: center;
        overflow-y: auto;
        //@include textEllipsis;
    }

    .channelsTableRow .channelsTableItem .userSortIcon {
        width: 14px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .channelsTableRow .channelsTableItem:last-child {
        //flex-grow: 0.29;
    }

    .channelsTableRow .channelsTableItem:first-child {
        flex-grow: 0.50;
    }

    .channelsTableItems {
        margin-top: 30px;
    }

    .channelsTableItem:before {
        content: attr(data-header);
        font-weight: 700;
    }

    @media (min-width: 500px) {
        .channelsTableItem {
            padding: 0.5em;
        }

        .channelsTableItem:before {
            content: none;
        }
    }

    .channelsTableRowCollection {
        transform: translateY(48px);
        position: fixed;
        overflow-y: auto;
        //height: 330px;
        //width: 91.5%;
        width: calc(100vw - 364px);
        min-height: 330px;
        max-height: calc(100vh - 350px);
    }

    .actionButton {
        border: 1px solid #000;
        font-weight: 500;
        width: 150px;
        cursor: pointer;
        min-width: 60px;
        max-width: 90px;
        height: 25px;
        padding: 1px;
        //background: url('./../../assets/images/down_arrow_black.svg') no-repeat .1em right;
        background-color: #fff;

        >img {
            width: 10px;
            height: 10px;
            opacity: 0.7;
            margin-left: 5px;
        }
    }
}

.dialogBoxInternalBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start !important;
    justify-content: flex-start;
    margin-bottom: 10px;

    >label {
        font-size: $default-font-size;
        letter-spacing: 1px;
        color: $default-label-color;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
    }

    >textarea {
        border-radius: 5px;
        border: solid 1px $gray-links-color;
        min-width: 464px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: $default-font-size;
        letter-spacing: 1px;
        width: 100%;
    }

    >input[type='text'] {
        height: 30px;
        border-radius: 5px;
        border: solid 1px $gray-links-color;
        min-width: 464px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: $default-font-size;
        letter-spacing: 1px;
        width: 100%;
    }

    >input[type='email'] {
        height: 30px;
        border-radius: 5px;
        border: solid 1px $gray-links-color;
        min-width: 464px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: $default-font-size;
        letter-spacing: 1px;
        width: 100%;
    }

    .highlight {
        border: solid 1px red !important;
        color: red;
    }
}

.dialogBoxInternalBlock.features {
    display: grid;
    //grid-template-columns: 20px repeat(auto-fill, minmax(60px, 1fr));
    grid-template-columns: 25px 170px 25px 150px;
    padding-left: 0px;
    align-items: center !important;
    justify-content: flex-start;

    .accountTitle {
        color: black;
        background-color: #f2f5f7;
        padding: 5px;
        border-radius: 5px;
    }
}

.dialogBoxInternalBlock.accounts {
    display: grid;
    grid-template-columns: 190px 190px;
    align-items: center !important;
    justify-content: flex-start;
    gap: 8px;

    .accountTitle {
        color: black;
        background-color: #f2f5f7;
        padding: 5px;
        border-radius: 5px;
    }
}

.newUserHeader {
    font-family: $body-font;
    font-size: $XX-large-size;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
}

.deleteUserHeader {
    margin-top: 40px;
    margin-bottom: -30px;
    font-family: $body-font;
    font-size: $XX-large-size;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
}

.createNewUserBtnContentWrapper {
    align-items: center !important;
    font-family: $body-font !important;
}

/* .headercloseBtn {
    width: 15px;
    height: 15px;
    border: none;
    background: none;
    position: absolute;
    right: 2em;
    cursor: pointer;

    >span {
        font-size: 20px
    }

    &:focus {
        outline: none
    }
} */

.deleteUserBtn {
    background-color: $default-alert-btn-bg-color !important;
    color: $header-bg-color !important;
}

.deleteUserBtnContentWrapper {
    flex-direction: row;
    height: auto;
}

.dialogTitleContentWrapper {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
}

.dialogActionsWrapper {
    width: 100%
}

.dialogContentMidSectionWrapper {
    display: flex;
    flex-direction: row;
}

.usersDialogLabel {
    font-size: $default-font-size;
    letter-spacing: 1px;
    color: $default-label-color !important;
    font-weight: bold;
    text-align: left;
}

@media screen and (max-width:1024px) {
    .usersContentWrapper {
        .channelsTableRowCollection {
            width: 92%;
            min-height: 250px;
            max-height: calc(100vh - 350px);
        }
    }
}