#liveManagerTopHeader {
    background: #373C4A 0% 0% no-repeat padding-box;
    width: 100%;   
    position: relative;
    height: 50px;
    //padding: 5px;
    ul {
        //width: calc(100% - 120px);
        width: auto;
        padding: 0px;
        margin: 0px;
        line-height: 50px;
        text-align: center;
        font-size: 0px;    
        li {
            list-style-type: none !important;
            display: inline-block;
            width: 10%;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
            line-height: initial;
            text-align: left;
            label:first-child,
            label:last-child {
               display: block;    
               color: #B2B2B2;        
            }
            label:first-child {
                color: #ffffff;
            }
        } 
    } 
    .liveHelp {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: inline-block;
        right: 10px;
        width: 120px;
        display: none;
    }
    /*button {   
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);     
        width: 120px;
        border: 1px solid white;
        margin: 0 auto;
        color: #ffffff;
        
    }*/
}