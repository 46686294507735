
@import "../../global.scss";
#socialSharing {
    display: flex;
    flex-direction: column;
    width: 100%;

    .socialSharingBlocks {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 10px;
        background-color: #F7F9FC;
        position: relative;
        padding: 10px;
        padding-right: 0px;
        border-bottom: 3px solid #EBF1F7;
        .title {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
            position: absolute;
            top: -20px;
        }
        .statusSection {
            @include alignTextImageCenter;
            .accountStatus {
                display: inline-block;
                margin-left: 10px;
                width: 20%;               
                label:first-child {
                    display: block;
                    font-weight: bold;
                }
                .companyName {
                    display: block;
                }
            }
            .accountLinkData {
                display: grid;
                @include textEllipsis;
                .commonLinkSharing {
                    color: #609CFC;
                    font-weight: bold;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                   
                }
                label {
                    display: block;
                    font-size: 12px;
                }
            }
        }
        
        

        button {
            position: absolute;
            right: 20px;
            padding-left: 20px !important;
            padding-right: 20px !important;
            padding-bottom: 5px;
            width: 100px !important;

        }
        .socialSharingBtnOverriden {
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }

        p {
            font-size: 11px;
            color: #4B4B4B;
        }

        .logo {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-position-y: center;
        }
        .logo.disabled {
            opacity: 0.4;
        }

        .facebook { 
            background-image: url('../../assets/images/facebook.svg');
            //background-color: #4d598f; 
        }
        .youtube { 
            background-image: url('../../assets/images/youtube.svg');
            //background-color: #d22215; 
        }
        .twitter { 
            background-image: url('../../assets/images/twitter.svg');
            //background-color: #64a2d9; 
        }
        .linkln { 
            background-image: url('../../assets/images/linkedin.svg');
            //background-color: #4375b1; 
        }
    } 
}
