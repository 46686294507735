@import '../../../../../global';
#mediaAddSection {
	display: flex;
	.mediaAddBtnSection {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		img {
			width: 20px;
			margin-right: 10px;
			position: relative;
		}
		a {
			cursor: pointer;
			@include alignTextImageCenter;
			svg {
				.cls-1 {
					fill: #126cfc;
				}
			}
		}
	}
}
.action-menu li:not(.treeCatalogItem) {
	&.action-menu__small-item {
		min-height: auto;
		min-width: 190px;
		margin: 5px 0px;
	}
}
