.dialogActionsWrapper {
	.deleteUserBtnContentWrapper {
		display: inline-block !important;
		text-align: center;
		button:nth-child(2) {
			margin-left: 10%;
		}
	}
}

.headercloseBtn {
	background: none;
	border: none;
	cursor: pointer;
	height: 16px;
	outline: none;
	position: absolute;
	right: 48px;
	width: 16px;
	svg.close {
		height: 16px;
		width: 16px;
		rect.a {
			fill: #444a58;
		}
		path.b {
			fill: #fff;
		}
	}
}
.dialogTitle {
	border: 1px solid #e5e9ec;
	padding: 0px 12px 0px 24px !important;
	margin-bottom: 12px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&.dialogTitle--darkMode {
		border: none;
	}

	> *[class*='MuiTypography-h'] {
		display: flex;
		align-items: center;
		font-weight: bold;
	}
}

.itemsList {
	padding: 12px 0 0;
}

.dialogActions {
	justify-content: space-between !important;
	padding: 12px 24px !important;
	&--center {
		justify-content: center !important;
		padding: 12px 24px !important;
	}
}

.dialogContentText {
	color: #000000 !important;
	font-size: 12px !important;
	&.dialogContentText--bold {
		font-weight: 600;
		display: block;
	}
}

body {
	&[dark-mode='true'] {
		#AssetLibrary,
		#LiveChatParticipants,
		#LiveChatSettings,
		#LiveChatSessionPopup {
			div[role='document'],
			div[role='dialog'] {
				letter-spacing: 0.5px;
				border: 1px solid $dark-mode-popup-border-color;
				background-color: $movable-grid-color;
				color: #ffffff !important;
				border-radius: 0px !important;

				label,
				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				span {
					color: #fff;
				}

				.dialogTitle {
					background-color: $live-interact-header !important;
					display: flex;
					vertical-align: middle;
					padding: 8px !important;
					padding-bottom: 7px !important;
					background-color: $live-interact-header !important;
					color: #ffffff !important;
					.dialogLogo {
						float: left;
						margin-right: 8px;
						margin-top: 2px;
						svg {
							width: 15px !important;
							height: 15px !important;
						}
					}
					h2 {
						font-size: 14px;
						letter-spacing: 0.5px;
						color: #ffffff !important;
					}
					.headercloseBtn {
						right: 12px;
						margin-top: 2px;
						cursor: pointer;
						svg {
							width: 16px;
							height: 16px;
							fill: #ffffff;
						}
					}
				}
				.dialogContent {
					@include addWhiteScrollBar;
				}
			}
		}
	}
}
