@import "../../../../../../global";

@media only screen and (min-width: 320px) and (max-width: 800px) {
  #inspectView.mobileView {
    overflow-y: scroll;
    .filesSection {
      .uploadSection {
        text-align: center !important;
      }
    }
    .filesTable {
      > tr {
        background-color: #f4f4f4;
      }
      width: 100%;
      background-color: $header-bg-color;
      th:last-child,
      td:last-child {
        display: block !important;
        border: 0;
      }
      /* th,
            td,
            td div {
                min-width: 120px;    
                max-width: 120px;
            }*/
    }

    /* table.video.filesTable {
            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4), 
            th:nth-child(5),
            td:nth-child(5), 
            th:nth-child(6),
            td:nth-child(6), 
            th:nth-child(7),
            td:nth-child(7)  { //note last menu is more button with options download exchange and delete, so hiding that for now
                display: none !important;
            }
        }
        table.image.filesTable,
        table.audio.filesTable {
            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4), 
            th:nth-child(5),
            td:nth-child(5), 
            th:nth-child(6),
            td:nth-child(6)  {
                display: none !important;
            }
        }
        table.index.filesTable,
        table.subtitle.filesTable {
            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4)  {
                display: none !important;
            }
        }*/
    table.filesTable {
      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5),
      th:nth-child(6),
      td:nth-child(6),
      th:nth-child(7),
      td:nth-child(7) {
        //note last menu is more button with options download exchange and delete, so hiding that for now
        display: none !important;
      }
    }
  }
}

.scrollable-table {
  overflow-x: auto;
  max-width: 100%;
  margin-top: 25px;
}

.scrollable-table:first-child {
  margin-top: 0px;
  table.filesTable th {
    padding-top: 0px;
  }
}

table.filesTable {
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;
  width: 100%;
  overflow-x: hidden;
  .hide,
  th[attr="more"] {
    display: none;
  }

  .sortIcon {
    cursor: pointer;
    position: absolute;
    left: 65px;
    bottom: 15px;
    margin-left: 10px;
  }

  th {
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid $input-placeholder-text;
    position: relative;
    img {
      width: 15px;
      height: 10px;
    }
  }
  tr {
    border-bottom: 1px solid $input-placeholder-text;
  }
  th,
  td,
  td div {
    text-align: left;
    min-width: 187px;
    max-width: 187px;
    @include textEllipsis;
  }

  td {
    padding: 15px;
    color: #3B3B3B;
    button.moreButton {
      padding: 5px;
      padding-top: 0px;
      border: 1px solid #707070;
      border-radius: 5px;
      background-color: transparent;
      cursor: pointer;
      img {
        width: 20px;
        border: 0;
      }
    }
    button.moreButton:focus {
      outline: none;
    }
  }

  th:last-child,
  td:last-child {
    display: none;
  }
}
