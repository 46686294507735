.template-group-wrapper {
	.template-group-header {
		height: 36px;
		border-bottom: 1px solid #e8e8e8;
		background-color: #f7f8fa;
		display: flex;
		align-items: center;
		padding: 0 5px;
		overflow: hidden;
		white-space: nowrap;
		text-align: left;
		text-overflow: ellipsis;

		& .group-title {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-align: left;
			text-overflow: ellipsis;
		}
	}

	.template-item {
		height: 36px;
		border-bottom: 1px solid #e8e8e8;
		background-color: #ffffff;
		padding-left: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:last-child {
			border-bottom: none;
		}

		& .item-title {
			display: flex;
			justify-content: center;
			color: #cdd4d9;

			& span {
				width: 200px;
				overflow: hidden;
				white-space: nowrap;
				text-align: left;
				text-overflow: ellipsis;
				color: #000;
				margin-left: 5px;
			}
		}

		& .item-action {
			display: none;
			margin-right: 10px;
		}

		&:hover {
			background-color: #f7f8fa;

			& .item-action {
				display: block;
			}
		}
	}
}
