@import '../../../../global.scss';

#addToCatalogDialog {
	.hide {
		display: none;
	}
	.allCatalogsContainer {
		border: 1px solid $content-border-color;
		width: 100%;
		min-height: 275px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-y: auto;
		border-radius: 4px;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 5px;
			cursor: pointer;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px $body-bg-color-after-login;
			border-radius: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background: $default-label-text-color;
			border-radius: 5px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: $body-table-font-color;
		}
	}
	.labelWrapperCatalog {
		width: 100%;
		font-size: 12px;
		margin-bottom: 12px;
	}
}
