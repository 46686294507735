#LivePlayerContainer {
    width: 100%;
    height: 100%;
    .bigLivePlayerLiveText {
        position: absolute;
    }
    #liveContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        #live-live,
        #channel-preview-live {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .gobrain-controlbar {
        background-color: transparent !important;
    }
}