@import '../../../global';

#inspectView.mobileView {
	display: block;
	overflow-y: scroll;
	#detailsSection {
		.descriptionSection {
			width: 100%;
		}
	}
	.tab {
		display: none;
		margin-left: 0px;
	}
	/*.content {
        padding-left: 10px;
        padding-right: 10px;
        max-height: 0;
        margin-left: 0px;
        width: 100% !important;
        background-color: $thin-left-panel-bg-color;
    }*/
	.mediaDetailsBottomSection {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 20px;
	}
	.inspectLeftPanelMenu {
		display: inline-flex;
		height: auto;
		width: 100%;
		.thinLineInspectIcon {
			width: 100%;
			height: 8px;
		}
		.thickLineInspectIcon {
			height: 37px;
		}
		.selectedInspectIconBlock {
			.thickLineInspectIcon {
				width: 100%;
				// height: 100%;
			}
		}
	}
	#inspectView .inspectionPageTopMiddleContainer {
		display: block;
		.content {
			&:last-child {
				display: block;
			}
		}
	}

	#accessRestrictionSection {
		.sortingDropDownWrapper {
			width: 100%;
		}
		.rulesListSection {
			max-height: 500px;
			width: 100%;
			overflow-y: auto;
			overflow-x: hidden;
		}
		.ruleDisplaySection {
			float: initial;
			padding-left: 0px;
		}
		.panel {
			width: 100%;
			padding-left: 5px;
		}
	}

	.mediaFullDetails {
		padding-right: 0px;
	}

	.inspectViewMenuListItems {
		width: 100%;
		max-height: none;
		overflow: hidden;
	}

	#inspectViewLivePlayer {
		width: 100%;
		float: left;
		max-height: none;
		overflow: hidden;
		padding: 0px;
	}

	.detailsMenuListWrapper {
		width: 100%;
		max-height: none;
		overflow: hidden;
		margin-left: 0px;
		margin-right: 0px;
		li.menuListItem {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	#informationSection {
		width: 100%;
		.streamName {
			width: 62%;
		}
		.streamList {
			padding-bottom: 20px;
			margin-bottom: 10px;
		}
	}
}
#inspectView {
	width: 100%;
	display: flex; //removing this fixes IE where view width is increasing but causes issue in live manager
	background-color: $tooltip-text-color;
	height: 100%;
	.mediaFullDetails {
		width: 100%;
		display: block;
		flex-direction: column;
	}
	.live-manager {
		height: 100%;
		display: block;
		width: 100%;
		// margin-left: 5px;
	}
	.interact-editor {
		height: 100%;
		display: block;
		width: 100%;
		// height: 88vh;
	}
	.hide {
		display: inherit;
	}
	.inspectLeftPanelMenu {
		display: block;
	}
	.content:last-child {
		margin-bottom: 100px;
	}
	#playlistMediaDetails {
		.mediaAddSection {
			position: relative;
			#mediaLibrarySection {
				position: absolute;
				right: 0px;
				top: 0px;
			}
		}
	}
	.mediaDetailsBottomSection {
		display: flex;
		width: 100%;
		padding: 0% 10%;
		padding-bottom: 50px;
		background-color: $header-bg-color;
		height: 100%;

		#publishDetails {
			display: none !important;
			+ .content {
				display: none !important;
			}
		}
	}

	.detailsMenuListWrapper {
		cursor: pointer;
		width: 16%;
		float: left;
		padding: 0px;
		margin: 0px;
		padding-left: 2px;
		overflow: auto;
		max-height: 90vh;
		margin-top: 0;
		li.menuListItem.selected {
			background-color: $checkboxes-highlighted-color;
			color: white;
			font-weight: 600;

			label {
				color: #ffffff !important;
				font-size: 14px;
				transform: translateY(-2px);
			}
			.arrowImage svg {
				fill: white;
			}
		}
		li.menuListItem:hover {
			background-color: #d3d3d3;
		}
		li.menuListItem.selected:hover {
			background-color: $checkboxes-highlighted-color;
		}
		li.menuListItem {
			cursor: pointer;
			// background-color: $checkboxes-highlighted-color;
			background-color: $thin-left-panel-bg-color;
			list-style-type: none;
			position: relative;
			height: 40px;
			margin: 5px;
			padding: 12px;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
			clear: both;
			padding-right: 20px;
			transition: all 0.1s ease-in;
			margin-top: 0;
			.arrowImage {
				cursor: pointer;
				position: absolute;
				right: 10px;
				svg {
					height: 10px;
					width: 10px;
					fill: black;
				}
			}
			label {
				@include textEllipsis;
				max-width: 90%;
				cursor: pointer;
				float: left;
			}
		}
	}

	.inspectViewMenuListItems {
		border: 1px solid $disabled-btn-color;
		border-top: 0px;
		border-bottom: 0px;
		min-height: 90vh;
		padding-top: 14px;
		max-height: 92vh;
		overflow: auto;
		@include addGreyScrollBar;
		&:not(#scenesGraph) {
			padding-left: 12px;
			padding-right: 12px;
		}
		h3 {
			border-bottom: 1px solid lightgrey;
			padding-bottom: 10px;
			font-size: 15px;
			@include textEllipsis;
			img {
				margin-left: 12px;
			}
			#mediaAddSection,
			.replaceMedia {
				margin-top: 5px;
			}
		}
		#mediaAddSection,
		.replaceMedia {
			float: right;
		}
		h4 {
			@include textEllipsis;
			font-size: 14px;
			padding-bottom: 10px;
			border-bottom: 1px solid lightgrey;
			margin-bottom: 10px;
		}
	}

	&:not(.mediaFullDetails--trashed) #inspectViewMenuListItems {
		float: right;
		width: 50%;
	}

	#inspectViewLivePlayer {
		width: 35%;
		float: right;
		overflow: auto;
		max-height: 92vh;
		div[class^='mediaDetail-'] {
			margin: 0 auto;
		}
	}

	.backToList {
		width: 100%;
		background-color: $checkboxes-highlighted-color;
		color: white;
		text-align: center;
		height: 40px;
		position: relative;
		margin-bottom: 20px;
		cursor: pointer;
		label {
			display: inline-block;
			padding-top: 8px;
			font-size: 16px;
			cursor: pointer;
		}
		svg {
			width: 15px;
			height: 15px;
			fill: white;
			position: absolute;
			left: 20px;
			top: 12px;
			cursor: pointer;
		}
	}
} // inspect view ends here

.exitConfirmationBtnContentWrapper {
	-webkit-flex-direction: row;
	flex-direction: row;
	height: auto;
	padding-bottom: 15px;
	justify-content: center;
	button {
		box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
		margin: 10px;
	}
}
#inspectScreenExitConfirmationDialog {
	.inspectScreenExitConfirmationTexts {
		label {
			color: black;
		}
		label:first-child {
			font-weight: 600;
			font-size: 15px;
			margin-bottom: 10px;
		}
		label:nth-child(2) {
			font-size: 12px;
		}
	}
}
.fullWidthBlock {
	display: block;
	width: 100%;
}
.apps.fullWidthBlock {
	height: inherit;
}

// new media menu
.mediaMenuWrapper {
	width: 15%;
	float: left;
	padding: 0 5px;
	max-height: 86vh;
	overflow-y: auto;
	@include addGreyScrollBar;
	.mediaMenuRow {
		display: flex;
		padding: 2px 0;
		li {
			//width: 86%;
			float: left;
			margin-right: 4px;
			cursor: pointer;
			border-radius: 2px;
			span {
				font-family: $body-font;
				font-size: 12px;
			}
			&:hover {
				background-color: #e5eaed;
			}
		}
		.mediaIconWrapper {
			float: left;
			width: 42px;
			cursor: pointer;
			border-radius: 2px;
			display: flex;
			align-items: center;
			svg {
				width: 100%;
				height: 43px;
				padding: 5px;
				align-items: center;
				display: flex;
				color: #474c63;
			}
			&:hover {
				background-color: #e5eaed;
			}
		}
		.selectedMainMenu {
			color: #ffffff !important;
			background-color: #126cfc !important;
			span {
				color: #ffffff !important;
				font-weight: 600;
			}
			svg {
				color: #ffffff !important;
			}
			.arrowSvg {
				filter: brightness(0) invert(1);
				pointer-events: none;
			}
		}
		.selectedMainMenu:hover {
			background-color: #0053d8 !important;
		}
	}
}

.mediaSubMenuElement {
	background-color: #f2f5f7;
	margin: 4px 0 !important;
	border-radius: 2px;
	span {
		font-family: $body-font;
		font-size: 12px;
	}
	.subMenuDiv {
		&:hover {
			background-color: #e5eaed !important;
		}
	}
	.selectedSubMenu {
		background-color: #e5eaed !important;
		span {
			font-weight: 600;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.mediaMenuWrapper {
		width: 100%;
		margin: 0;
	}
}

.snapShotMediaControls {
	button {
		min-width: 25px !important;
		font-size: 10px !important;
		height: 30px;
	}
	button:first-child {
		margin-right: 10px;
	}
	button.rewind {
		background-color: $live-interact-element-list !important;
		&:hover {
			background-color: $live-interact-element-hover !important;
		}
	}
	img.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	img {
		cursor: pointer;
		width: 12px;
		height: 12px;
		margin-right: 5px;
	}
}

.timelineActionButtons {
	margin: 0 auto;
	display: flex;
	align-content: center;
	color: #fff;
	button {
		color: inherit !important;
	}
}

.in-point,
.out-point,
.live-time {
	vertical-align: top;
	color: white;
	background: $live-interact-element-hover !important;
	text-transform: none;
	letter-spacing: 1px;
	cursor: pointer;
	border-radius: 2px;
	margin-right: 5px;
	opacity: 1;
	img {
		width: 13px;
		height: 13px;
	}
	&:hover {
		background-color: $live-interact-element-list !important;
	}
	&:disabled {
		opacity: 0.4;
	}
}

.out-point {
	img {
		width: 12px;
		height: 12px;
	}
}

.out-point:disabled,
.in-point:disabled,
.live-time:disabled {
	color: $disabled-btn-color !important;
	opacity: 0.5;
}

.semiboldHeaders {
	font-weight: 600;
}

.LiveManagerBottomPanelContainer,
.InteractBottomPanelContainer {
	display: flex;
	text-align: center;
	.zoomOptions {
		float: right;
		@include alignTextImageCenter;
		.zoom {
			background-color: $movable-grid-color;
			width: 50px;
			border: 0px;
			cursor: pointer;
			margin-right: 5px;
		}
		img {
			cursor: pointer;
			width: 15px;
			height: 15px;
			margin-right: 10px;
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.timelineActionButtons {
		margin: 0 auto;
		display: flex;
		align-content: center;
	}
	button {
		letter-spacing: 0.5px;
		cursor: pointer !important;
		color: #ffffff;
		text-transform: none !important;
		background-color: $movable-grid-color;
		height: 24px !important;
		margin-right: 5px;
		img {
			margin-right: 5px;
		}
		&:disabled {
			opacity: 0.4;
		}
		span {
			color: inherit !important;
		}
	}
	button.live-time {
		margin-left: 10%;
		min-width: 100px !important;
	}
	label {
		display: flex;
		align-items: center;
	}
}
