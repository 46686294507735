.media-library {
	flex: 1;
	width: 82%;
	&-screen {
		height: calc(100vh - 45px);
		display: flex;
	}
	&__item {
		position: relative !important;
		&--locked {
			position: absolute;
			background-color: #e5e9ecb2;
			border: 1px solid #e5e9ecb2;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
		}
		&:hover .media-library__item--locked {
			visibility: visible;
		}
	}
}
