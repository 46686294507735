.poll {
	&--visible {
		visibility: visible;
	}

	&--hidden {
		visibility: hidden;
	}

	&__wrapper {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 80px;
		font-size: 12px;
		text-align: left;
		padding: 5%;

		&.poll__wrapper--collapsed {
			padding: 12px 5%;
		}

		&.poll__wrapper--top {
			top: 50px;
			bottom: unset;
			border-radius: 0px !important;
		}

		&:not(.poll__wrapper) {
			background: rgb(255, 255, 255);
			color: rgb(26, 39, 62);
			border-radius: 4px;
			box-shadow: rgb(0 0 0 / 16%) 3px 3px 10px;
			border: 1px solid rgb(206, 212, 216);
			&:not(.poll__wrapper--top) {
				margin: 10px;
			}
		}

		&--endPollOptions {
			background: #444853;
			border: 1px solid #444853;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			&-header {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				span {
					padding-bottom: 12px;
				}
			}
		}
	}

	&__header {
		display: flex;
		color: rgb(12, 48, 108);
		width: 100%;
		justify-content: space-between;
		align-items: center;
		font-size: 11px;

		&-title {
			text-transform: uppercase;
			font-weight: 600;
			cursor: pointer;
			padding: 1px 0px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			svg {
				margin-right: 8px;
			}
		}

		&-suffix {
			width: 20px;
			height: 20px;
		}
	}

	&__question {
		margin-top: 6px;

		> span {
			color: rgba(26, 39, 62, 0.6);
		}

		&-text {
			padding: 8px 0px 6px;
			margin-bottom: 5px !important;
			border-bottom: 1px solid rgb(26, 39, 62);
		}
	}

	&__question-text,
	&__option--textField {
		.MuiFormHelperText-root {
			visibility: hidden;
			height: 0;
			transition: height 0.1s ease-in;
		}
		&:focus-within .MuiFormHelperText-root {
			visibility: visible;
			height: 15px;
		}
	}

	&__option {
		display: flex;
		border-radius: 4px;
		justify-content: space-between;
		align-items: center;
		height: 48px;
		margin: 5px 0px;
		position: relative;
		border: 1px solid #ffffff4d;

		&--textField {
			margin: 5px 0px 0px !important;
			position: relative;
			&-action {
				position: absolute;
				right: 0;
				top: 0;
				height: 52px;
				display: flex;
				align-items: center;
			}
			.MuiFormHelperText-root {
				margin-left: 0px !important;
				margin-right: 0px !important;
			}
		}

		&--add {
			justify-content: flex-end;
			border: none;
			padding: 0;
		}

		&--text {
			height: 100%;
			border-radius: 4px;
			background-color: #f2f5f7;
			&.poll__option--winner {
				background-color: #dbe9ff;
			}
		}

		&:before {
			content: attr(data-text);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			justify-content: space-between;
			align-items: center;
			display: flex;
			padding: 0 12px;
		}

		&--result:after {
			content: attr(data-percent);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			justify-content: flex-end;
			align-items: center;
			display: flex;
			padding: 0 12px;
			border-radius: 4px;
		}
	}

	&__actions {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 24px;

		button:not(:last-child) {
			margin-right: 10px;
		}

		button.defaultActionBtn {
			border-radius: 4px !important;
		}
	}
}

.poll {
	&__wrapper {
		background-color: #2a2e38;
		border: none;
		color: #fff;
		overflow: auto;
		top: 50px;
	}

	&__queue {
		&-title {
			margin-bottom: 12px;
		}
		&-message {
			padding: 12px;
			opacity: 0.7;
			text-align: center;
			margin-bottom: 12px;
		}

		&-item {
			opacity: 1;
			padding: 12px;
			margin: 5px 0;
			box-shadow: 3px 3px 10px #00000029;
			min-height: 56px;
			border-radius: 4px;
			&-title {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-weight: 600;
				height: 32px;
				div {
					display: flex;
					align-items: center;
				}
			}
			&:last-child {
				margin-bottom: 32px;
			}

			&:not(.poll__queue-item-result) {
				background: #444853;
				border: 1px solid #444853;
				.poll__queue-item-title {
					color: #ffffff;
				}
				.poll__option {
					color: #ffffff;
				}
			}

			&-result {
				background: #444853;
				border: 1px solid transparent;
			}

			.poll__option--result {
				border-color: #ffffff4d;
				.poll__option--text {
					background-color: #393d4a;
					&.poll__option--winner {
						background-color: #2a2e38;
					}
				}
			}

			svg {
				width: 15px;
				height: 15px;
				&:first-child:not(:last-child) {
					margin-right: 8px;
				}
			}
		}
	}
}

.poll-notify {
	position: absolute;
	top: 0;
	right: 0;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #126cfc;
}
