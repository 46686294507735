

@import "../../../../../../global";


#informationDialog {
    label {
        color: black;
    }
    .dialogTitleNewMediaContentWrapper {
        padding-top:0px;
        padding-bottom:10px;
    }
    #infoTitle {
        label {
            color: black;
            font-weight: 600;
            font-size: 14px;
        }
    }

    table.infoTable {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        tr {
            margin: 10px;
            border: 1px solid $media-content-border-color;
        }
        td {
            color: black;
            padding: 8px;
            width: 150px;
        }

        td:nth-child(odd)  {
            font-weight: 600;
        }
    }

    img.imgIcons {
        cursor: pointer;
        width: 15px;
        height: 15px;
    }
    a {
        img,
        span {
            cursor: pointer;
            float: left;
            margin-right: 5px;
            font-weight: normal;
        }
    }

    textarea {
        resize: none;
      }
}

.informationDialogBottomActions {
    padding: 12px 0px;
    > a {
        margin-right: 12px !important;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
    }
}
