#speechToTextScreen {
	width: inherit;
	height: 100%;
	border: 0px;
	display: flex;
	flex-direction: row;
	font-family: 'Open Sans', serif !important;

	.sttLeftSide {
		width: 64%;
		height: 100% !important;
		position: relative;

		.sttHeader {
			width: 100%;
			height: 48px;
			border-bottom: 1px solid lightgray;
		}

		.sttSubEdit {
			width: 100%;
			height: 90%;
			display: flex;
			flex-direction: row;
			overflow: hidden;

			.sttSubEditPlaceholder {
				font-size: 16px;
				margin-top: 27%;
				width: 100%;
				text-align: center;
				color: lightgray;
			}

			.sttlanguageSelector {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background: white;
				width: 239px;
				margin-top: 40%;
				margin-left: auto;
				margin-right: auto;

				span {
					font-weight: bold;
					font-size: 11px;
					text-align: left;
					width: 220px;
					margin-bottom: 5px;
				}

				select {
					display: block;
					width: 220px;
					height: 25px;
					text-align: left;
					font: 12px Open Sans;
					border-radius: 5px;

					.sttLanguageOption {
						margin-left: 5px;
					}
				}

				button {
					margin: 20px;
					display: block;
					width: 220px;
					height: 30px;
					background: #126cfc 0% 0% no-repeat padding-box;
					border: 0.5px solid #126cfc;
					font: 12px Open Sans;
					color: white;
					border-radius: 2px;
					text-align: center;
					cursor: pointer;
				}

				button:hover {
					background: #0053d8 0% 0% no-repeat padding-box !important ;
					border: 0.5px solid #0053d8 !important;
				}
			}

			.subEditForm {
				width: 45% !important;

				.subEditFormWrapper {
					display: flex;
					flex-direction: column;
				}

				.subEditFormPlaceholder {
					margin-top: 70%;
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: lightgray;
				}

				.subEditDurationText {
					font-size: 14px;
					margin-bottom: 30px;
				}

				.subEditDurationTextNotAllowed {
					margin-bottom: 0px !important;
				}

				.subEditDurationWrapper {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
				}

				.subEditDurationNotAllowed {
					color: red;
				}

				.subEditTextArea {
					resize: none;
					width: 90%;
					height: 7em;
					margin: 5%;
					padding-left: 3%;
					padding-right: 3%;
					padding-top: 1%;
					border-radius: 5px;
					border-color: lightgray;
				}

				.subEditInputs {
					width: 150px;
					height: 30px;
					border-radius: 5px;
					border-color: lightgray;
					padding: 1.5%;
					margin-bottom: 30px;
				}
				.subEditInputDash {
					height: 30px;
					margin-left: 5px;
					margin-right: 5px;
					font-size: 20px;
				}

				.TimeInput {
					padding: 0px 5px;
					border-radius: 5px;
					border: 2px solid grey;
					&:hover {
						border: 2px solid black;
					}
				}

				.notAllowedInputBorderColor {
					input[type='text'] {
						text-align: center;
						width: 100%;
						height: 30px !important;
						border-radius: 5px;
						border-color: red !important;
					}

					input:focus {
						outline: none;
					}
				}

				.notAllowedInputText {
					height: 20px;
					margin-left: 5%;

					font-size: 12px;
					font-style: italic;
					color: red;
				}

				.subEditMarginForm {
					margin-left: 5%;
					margin-right: 5%;
				}

				.subEditTimestampInputWrapper {
					margin-left: 5%;
					margin-right: 5%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
				}

				.subEditUpdateButton {
					background: #126cfc 0% 0% no-repeat padding-box;
					border-radius: 2px;
					text-align: center;
					width: 90%;
					border: 0.5px solid #126cfc;
					font: 12px Open Sans;
					color: white;
					letter-spacing: 0;
					padding: 5px;
					box-shadow: 0px 3px 6px #00000029;
					cursor: pointer;
				}

				.subEditUpdateButtonDeactive {
					background: #126cfc 0% 0% no-repeat padding-box;
					border-radius: 2px;
					text-align: center;
					width: 90%;
					border: 0.5px solid #126cfc;
					font: 12px Open Sans;
					color: white;
					letter-spacing: 0;
					padding: 5px;
					opacity: 0.4;
				}

				.subEditUpdateButton:hover {
					background: #0053d8 0% 0% no-repeat padding-box !important;

					border: 0.5px solid #0053d8 !important;
				}

				.subEditLabel {
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 10px;
					margin-right: 5px !important;
				}
			}

			.translatedPreview {
				width: 75% !important;
			}

			.translationHeader {
				margin: 5px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				h3 {
					font-size: 16px;
					margin-left: 10px;
				}
			}
		}

		.sstTranscriber {
			width: 100%;
			height: calc(100% - 100px);

			.sttLanguageSelectorWrapper {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.sttlanguageSelector {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background: white;
					width: 239px;

					p {
						font-weight: bold;
						font-size: 11px;
						text-align: left;
						width: 220px;
						margin-bottom: 5px;
					}

					select {
						display: block;
						width: 220px;
						height: 25px;
						text-align: left;
						font: 12px Open Sans;
						border-radius: 5px;
						margin-bottom: 12px;
						padding: 0px 5px;

						.sttLanguageOption {
							margin-left: 5px;
						}
					}

					button {
						margin: 20px;
						display: block;
						width: 220px;
						height: 30px;
						background: #3e64cb 0% 0% no-repeat padding-box;
						border: 0.5px solid #3e64cb;
						font: 12px Open Sans;
						color: white;
						border-radius: 2px;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}

		@media screen and (max-height: 1000px) {
			.sstTranscriber {
				//height: 75.5vh;
			}
			.sttSubEdit {
				height: 75.5vh;
			}
		}

		.sttControlBar {
			width: 100%;
			height: 48px;
		}
	}

	.sttRightSide {
		width: 36%;
		border-left: 1px solid lightgray;

		.headerAttachedSubtitles {
			background-color: #f5f5f5;
			height: 30px;
			padding-top: 10px;
			padding-left: 18px;
		}

		.saveSrtBtn {
			background: #3e64cb 0% 0% no-repeat padding-box;
			border-radius: 2px;
			opacity: 1;
			text-align: center;
			width: 120px;
			margin-left: 32%;
			margin-right: 25%;
			border: 0.5px solid #3e64cb;
			font: 12px Open Sans;
			color: white;
			letter-spacing: 0;
			padding: 5px;
			box-shadow: 0px 3px 6px #00000029;
			cursor: pointer;
		}

		@media screen and (max-width: 1600px) {
			.saveSrtBtn {
				margin-left: 28%;
				margin-right: 25%;
			}
		}

		.saveSrtBtnMain {
			background: #3e64cb 0% 0% no-repeat padding-box;
			border-radius: 2px;
			opacity: 1;
			text-align: center;
			width: 120px;
			margin-top: 18px;
			margin-left: 32%;
			margin-right: 25%;
			border: 0.5px solid #3e64cb;
			font: 12px Open Sans;
			color: white;
			letter-spacing: 0;
			padding: 5px;
			box-shadow: 0px 3px 6px #00000029;
			cursor: pointer;
		}

		@media screen and (max-width: 1600px) {
			.saveSrtBtnMain {
				margin-left: 28%;
				margin-right: 25%;
			}
		}

		.updateSrtBtn {
			background: #126cfc 0% 0% no-repeat padding-box;
			border-radius: 2px;
			opacity: 1;
			text-align: center;
			width: 90px;

			border: 0.5px solid #126cfc;
			font: 12px Open Sans;
			color: white;
			letter-spacing: 0;

			cursor: pointer;
		}

		.updateSrtBtn:hover {
			background: #0053d8 0% 0% no-repeat padding-box !important;
			border: 0.5px solid #0053d8 !important;
		}

		.sttVideoPlayer {
			position: relative;
			margin-top: 18px;
			width: 100%;
			display: flex;
			justify-content: center;

			.gobrain-id-subtitles-container {
				display: none !important;
			}

			.gobrain-id-controls-container {
				display: none !important;
			}

			/* .LivePlayerWrapper{
                position:absolute;
            }  */
			.sttCurrentDisplayedSub {
				position: absolute;
				z-index: 100000000;
				white-space: pre-wrap;
				color: #fff;
				//transform: translateY(-70px);
				//transform: translateX(100%);
				//text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
				bottom: 10px;
				width: 100%;
				text-align: center;
				text-shadow: rgb(0, 0, 0) 2px 0px 0px, rgb(0, 0, 0) 1.75517px 0.958851px 0px,
					rgb(0, 0, 0) 1.0806px 1.68294px 0px, rgb(0, 0, 0) 0.141474px 1.99499px 0px,
					rgb(0, 0, 0) -0.832294px 1.81859px 0px, rgb(0, 0, 0) -1.60229px 1.19694px 0px,
					rgb(0, 0, 0) -1.97998px 0.28224px 0px, rgb(0, 0, 0) -1.87291px -0.701566px 0px,
					rgb(0, 0, 0) -1.30729px -1.5136px 0px, rgb(0, 0, 0) -0.421592px -1.95506px 0px,
					rgb(0, 0, 0) 0.567324px -1.91785px 0px, rgb(0, 0, 0) 1.41734px -1.41108px 0px,
					rgb(0, 0, 0) 1.92034px -0.558831px 0px;
			}
		}

		.sttlanguageSelectorSidebar {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: white;
			width: 100%;
			margin-top: 5%;

			margin-right: 77%;

			span {
				font-weight: bold;
				font-size: 11px;
				text-align: left;
				width: 220px;
				margin-bottom: 5px;
			}

			select {
				display: block;
				width: 220px;
				height: 25px;
				text-align: left;
				font: 12px Open Sans;
				border-radius: 5px;

				.sttLanguageOption {
					margin-left: 5px;
				}
			}

			button {
				margin: 20px;
				display: block;
				width: 220px;
				height: 30px;
				background: #126cfc 0% 0% no-repeat padding-box;
				border: 0.5px solid #126cfc;
				font: 12px Open Sans;
				color: white;
				border-radius: 2px;
				text-align: center;
				cursor: pointer;
			}

			button:hover {
				background: #0053d8 0% 0% no-repeat padding-box !important;
				border: 0.5px solid #0053d8 !important;
			}
		}

		.sttSubtitleList {
			font-size: 11px;
			letter-spacing: 0;
			color: #525252;
			background-color: #f5f5f5;
			position: relative;
			clear: both;

			ul {
				padding: 0;
			}

			.sttSublistUL {
				height: 140px;
				overflow: auto;
				overflow-x: hidden;
				width: 100%;
			}

			.sttSubListText {
				cursor: pointer;
			}

			.sttSubListText:hover {
				cursor: pointer;
				text-decoration: underline;
			}

			.subtitleListItemSelected {
				background-color: #dedede !important;
			}

			.subtitleListItem {
				height: 45px;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				background-color: #f5f5f5;

				padding-left: 18px;
				border-bottom: 0.5px solid #e5e5e5;
				border-top: 0.5px solid #e5e5e5;

				.sttSubListLeftSide {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
				.deleteIcon {
					height: 17px;
				}
				.deleteIcon:hover {
					cursor: pointer;
				}

				.downloadIcon {
					margin-right: 10px;
					height: 17px;
				}
				.downloadIcon:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.transcriptionAreaWrapper {
	height: 100%;
	width: 60%;
	display: flex;
	flex-direction: column;
}

.sttEditTextHeader {
	margin-top: 25px;

	margin-bottom: 25px;
}

.saveSrtBtnMain {
	background: #3e64cb 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
	text-align: center;

	margin-top: 18px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 18px;
	border: 0.5px solid #3e64cb;
	font: 14px Open Sans;
	color: white;
	letter-spacing: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	box-shadow: 0px 3px 6px #00000029;
	cursor: pointer;
}

#transcriptEditor {
	padding-right: 20px;
	width: 100%;
	display: inline-block;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;

	p {
		margin-bottom: 25px;
	}
	span {
		//border: 1px solid white;
		text-align: center;
		font-family: 'Courier New', Courier, monospace;
	}
}

/* @media screen and (max-height:1000px) {
    #transcriptEditor:hover{
      overflow-y: auto;
      padding-right: 8px;
  }
}*/

#transcriptEditor:hover::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
}

#transcriptEditor::-webkit-scrollbar {
	width: 7px;
}
#transcriptEditor::-webkit-scrollbar-thumb {
	background-color: #fff;
	border-radius: 10rem;
	border: 1px solid #fff;
}

#transcriptEditor::-webkit-scrollbar-track-piece:start {
	background: transparent;
}

#transcriptEditor::-webkit-scrollbar-track-piece:end {
	background: transparent;
}

#loadingState {
	width: 40%;
	text-align: center;

	.loadingText {
		margin-bottom: 50px;
	}

	.loadingTextMinor {
		padding-top: 50px;
		font-size: 14px;
	}

	.restartButton {
		background: #3e64cb 0% 0% no-repeat padding-box;
		border-radius: 2px;
		opacity: 1;
		text-align: center;
		width: 120px;
		margin-top: 18px;
		margin-left: 32%;
		margin-right: 25%;
		border: 0.5px solid #3e64cb;
		font: 12px Open Sans;
		color: white;
		letter-spacing: 0;
		padding: 5px;
		box-shadow: 0px 3px 6px #00000029;
		cursor: pointer;
	}
}

.subtitlePreviewAvtive {
	//width: 60% !important;
}

.subtitlePreviewDeAvtive {
	//width: 100% !important;
}

.transcribedSection {
	padding-left: 20px;
	padding-right: 20px;
}

.transcribedWord:hover {
	background-color: rgb(235, 235, 235);
	//border: 1px solid lightblue;
}

#subtitlePreviewArea {
	/*margin-top:62px;
    padding-left: 30px;
    margin-bottom:80px;
    padding-right: 20px;*/

	padding-left: 0px;
	margin-bottom: 0px;
	padding-right: 0px;
	width: 40%;
	display: inline-block;
	position: relative;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	border-left: 1px solid lightgrey;

	.sttSubPreview {
		margin-top: 65px;
	}

	.srtSub {
		position: relative;
		padding-left: 20px;
		font-size: 15px;
		display: flex;
		padding: 10px;
		min-height: 40px;
	}

	.srtSubUnsuable {
		opacity: 0.4;
	}

	.srtSubTiming {
		display: block;
		position: relative;
		left: 0px;
		width: 120px;
		font-weight: bold;
	}

	.srtSubText {
		display: inline-block;
		position: relative;
		width: auto;
	}
	.textSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.textSectionTranslated {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.textSectionTranslated:hover {
		background-color: rgb(235, 235, 235);
		border: 1px solid lightblue;
	}
}

#subtitlePreviewArea:hover {
	overflow-y: auto;
	/*    .textSection{
       transform: translateX(7px);
    }
 */
}

#subtitlePreviewArea::-webkit-scrollbar {
	width: 7px;
}
#subtitlePreviewArea::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 10rem;
	border: 1px solid #fff;
}

#subtitlePreviewArea::-webkit-scrollbar-track-piece:start {
	background: transparent;
}

#subtitlePreviewArea::-webkit-scrollbar-track-piece:end {
	background: transparent;
}

#translatedPreviewArea {
	/*margin-top:62px;
    padding-left: 30px;
    margin-bottom:80px;
    padding-right: 20px;*/
	padding-left: 0px;
	margin-bottom: 0px;
	padding-right: 0px;
	width: 40%;
	display: inline-block;
	position: relative;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;

	span {
		white-space: pre-wrap;
	}

	.translatedSubContentWrapper {
		display: flex;
		flex-direction: row;
	}

	.translatedSubButtonWrapper {
		display: none;
	}

	.highlightedTranslationButtons {
		display: block !important;
	}

	.deleteSubEntryButton {
		background-image: url('../../../assets/images/trashcan.svg');
		background-size: 14px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 18px;
		height: 18px;
		padding: 3px;
		border: 1px solid lightgray;
		border-radius: 2px;
		margin-right: 5px;
	}

	.addSubEntryButton {
		background-image: url('../../../assets/images/plus.png');
		background-size: 14px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 18px;
		height: 18px;
		padding: 3px;
		border: 1px solid lightgray;
		border-radius: 2px;
	}

	.srtSub {
		position: relative;
		padding-left: 20px;
		font-size: 15px;
		display: flex;
		justify-content: space-between;
		padding: 10px;
	}

	.srtSubUnsuable {
		opacity: 0.4;
	}
	.srtSubTiming {
		display: block;
		position: relative;
		left: 0px;
		width: 200px;
		font-size: 14px;
		padding-top: 2px;
		margin-right: 20px;
	}

	.srtSubTimingOverlapping {
		color: #126cfc;
	}

	.srtSubTimingOverlappingNotAllowed {
		color: red !important;
	}

	.srtSubTimingItalic {
		font-style: italic;
	}
	.srtSubText {
		display: inline-block;
		position: relative;
		width: auto;
	}
	.textSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.textSectionTranslated {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		cursor: pointer;
	}

	.textSectionTranslatedUnsuable {
		cursor: auto !important;
	}
}

/* #translatedPreviewArea:hover{

    overflow-y: auto;

} */

#translatedPreviewArea::-webkit-scrollbar {
	width: 7px;
}

#translatedPreviewArea::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 10rem;
	border: 1px solid #fff;
}

#translatedPreviewArea::-webkit-scrollbar-track-piece:start {
	background: transparent;
}

#translatedPreviewArea::-webkit-scrollbar-track-piece:end {
	background: transparent;
}

.highlight {
	background-color: #65e5ff;
}

.highlightedTranslation {
	background-color: #65e5ff;
}

.highlightedTranslationGray {
	background-color: #dddddd;
}

.subEditModeWidth {
	width: 100% !important ;
	//height: 96.5% !important;
	border-right: 1px solid lightgrey;
	position: relative;
}

.editHighlight {
	background-color: #dddddd !important;
	border: 1px solid #1fbede;
}

.editHighlightTranslation {
	background-color: #dddddd !important;
	border: 1px solid #1fbede;
	display: flex !important;
	flex-wrap: wrap;
	//grid-template-columns: 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px 8px;
	span {
		//text-align: center
		/* display: inline-block;
        flex-grow: 1; */
	}
}

@keyframes PulseAttention {
	50% {
		border-color: black;
	}
}

.cursorBorderTranslated {
	border-right: 2px solid transparent;
	animation: PulseAttention 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards infinite;
}

.cursorBorderTranslatedLeft {
	border-left: 2px solid transparent;
	animation: PulseAttention 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards infinite;
}

.cursorBorder {
	border-right: 2px solid transparent;
	animation: PulseAttention 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards infinite;
}

.cursorBorderLeft {
	border-left: 2px solid transparent;
	animation: PulseAttention 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards infinite;
}
