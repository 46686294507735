@mixin addGreyScrollBar {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #eaeaf2;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #eaeaf2;
      border-radius: 10px;
    }
}

.analyticsSideBar {
    background-color: #fff;
    border-right: 1px solid #eaeaf2;
    padding-left: 8px;
    padding-right: 7px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    height: calc(100vh - 80px);
    @include addGreyScrollBar;

    h1 {
        font-size: 18px;
        padding: 10px 20px;
    }

    .listItem {
        // height: 45px;
        margin-bottom: 2px;
        border-radius: 2px;
        cursor: pointer;
        background-color: #F2F5F7;

        .listItemLable {
            padding: 10px;
        }

        &.hasChild {
            background-color: #fff;
            &:hover, &:focus {
                background-color: #fff !important;
            }
            .MuiTreeItem-group{
                margin-left: 0;
            }
           > .MuiTreeItem-content {
                flex-flow: row-reverse;
                background-color: #F2F5F7;
                margin-bottom: 2px;
                .MuiTreeItem-label {
                    padding: 10px;
                }
                .MuiTreeItem-iconContainer {
                    width: 32px;
                    display: block;
                    flex-shrink: 0;
                    margin-right: 0;
                    padding: 6px 10px;
                    /* justify-content: center; */
                    border-left: 2px solid #fff;
                }   
           }
        }

        &:hover, &:focus {
            background-color: #E5E9EC !important;
            
            > .MuiTreeItem-content {
                .MuiTreeItem-label {   
                    background-color: inherit;
                }
            }
        }

        &.selectedItem{
            color: #000;
            background-color: #E5E9EC !important;

            .listItemLable {
                font-weight: bold;
            }
        }

        &:not(.hasChild) {
            .MuiTreeItem-iconContainer {
                display: none;
            }    
        }
       
        &.hasChild, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus  {
            > .MuiTreeItem-content {
                .MuiTreeItem-label {   
                    background-color: inherit;
                }
            }
        }

        &.activechild {
            > .MuiTreeItem-content {
                background-color: #126CFC;
                color: #fff;
                .MuiTreeItem-label {
                    font-weight: bold;
                }
                .MuiTreeItem-iconContainer {
                    svg {
                        fill: #fff;
                    }
                }
            }
            .activechild {
                > .MuiTreeItem-content {
                    background-color: #E5E9EC;
                    color: #000;
                    .MuiTreeItem-iconContainer {
                        svg {
                            fill: #000;
                        }
                    }
                }
            }
            // .MuiTreeItem-label{   
            //     font-weight: bold;
            // }
        }
    } 
}

.navBtn {
    display: none;
    z-index: 400;
    position: absolute;
    right: 10px;
}

@media only screen and (max-width: 768px) {
    .analyticsSideBar {
        height: auto;
        margin: 0;
        padding: 10px;
        z-index: 300;
        display: none;

        border: 0;
        position: fixed;
        width: 100%;
        height: calc(100vh - 65px);

        &.active {
            display: block;
        }
        
    }
    .navBtn {
        display: flex;
    }
}
    