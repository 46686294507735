@import "../../global.scss";

#TagsSectionLibrary {
  margin-top: 8px;
  position: relative;
  .input-icon {
    height: 10px;
    position: absolute;
    margin: 3px 10px 12px 10px;
    width: 10px;
  }
  .tagsListSection {
    max-height: 100px;
    overflow: auto;
    width: 100%;
    padding: 5px 0;
    margin: 0;
  }
  .tagLibrary {
    color: #000000;
    font-weight: bold;
    background: #EAEDF4;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 11px;
    padding-left: 10px;
    border-radius: 4px;
    list-style: none;
    height: 24px;
    display: inline-flex;
    align-items: center;

    img {
      margin-left: 5px;
      cursor: pointer;
    }

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    svg {
      width: 10px;
      height: 10px;
      cursor: pointer;
      top: 1px;
      position: relative;
      margin-left: 5px;

      .prefix__cls-1 { fill: #707070 }
      #prefix__Layer_2 { fill: #707070 }
    }
  }
  /*.tag-input {
      margin-bottom: 0px;
      textarea {
          padding-right: 150px;
      }
  }*/

  #tagInput {
    width: 100%;
    height: 30px;
    padding: 12px 12px 12px 32px;
    margin: 0;
    border: 0.5px solid #CFD5E5;
    border-radius: 4px;
    background-color: #F7F8FA;
  }

  .tagInputInfo {
    margin-left: -90px;
    border-radius: 2px;
    border: 1px solid grey;
    letter-spacing: 0.5px;
    padding: 5px;
    display: inline-block;
    font-size: 10px;
  }

  .suggestionListLibrary {
    padding: 0px;
    cursor: pointer;
    max-height: 100px;
    min-height: 0px;
    width: 216px;
    overflow: auto;
    border: 1px solid #BFBFBF;
    border-top: 0px;
    margin: 0px;
    position: absolute;
    background-color: #f4f5f7;
    top: 30px;
    &::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $body-bg-color-after-login;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $default-label-text-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover { background: $body-table-font-color }
    li {
      padding: 5px 10px;
      font-size: 12px;
      background-color: #F7F7F7;
      margin-bottom: 5px;
      list-style-type: none;
    }
    li:hover{
      background-color: #9ED9E5;
    }
    li.highlight{
      background-color: #9ED9E5;
    }

    + #tagInput {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
  }
}
